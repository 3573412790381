export const setCurrentLangReducer = (state, action) => {
  state.lang = action.payload;
};

export const setLanguagesReducer = (state, action) => {
  state.languages = action.payload.filter(({ value }) =>
    state.translations.hasOwnProperty(value)
  );
  state.isDataLoaded = true;
};
