import { useState } from "react";

import { useSelector } from "react-redux";

import Search from "../../../../../components/ui/New/Search/Search";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { Countries } from "../Countries/Countries";
import NavigationItem from "../Navigation/NavigationItem";
import { StyledLocationsList } from "../ProxiesNavigation/ProxiesNavigation.styled";

import { proxyServicesList } from "../../../../../utils/helpers/serviceMenu.helpers";
import { StyledButton, StyledIcon } from "./ServicesNavigation.styled";

export const ServicesNavigation = () => {
  const [queryLang] = useLangUrlDefault();

  const [search, setSearch] = useState("");
  const [currentStep, setCurrentStep] = useState("services");
  const [isOpened, setIsOpened] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("topLocations");
  const { countries, proxyTypesMenu } = useSelector(getAllContent);

  const handleClick = (step) => {
    setCurrentStep(step);
    setIsOpened(true);
  };

  return (
    <div>
      {currentStep === "services" && (
        <NavigationItem
          title="services"
          mobile
          list={proxyServicesList(proxyTypesMenu?.data?.[queryLang])}
          handleServiceClick={() => handleClick("locations")}
          onClick={() => setIsOpened(!isOpened)}
          isOpened={isOpened}
        />
      )}
      {currentStep === "locations" && (
        <>
          {isOpened && (
            <StyledButton onClick={() => handleClick("services")}>
              <StyledIcon name="arrowAltRight" />
              <span>Select service</span>
            </StyledButton>
          )}

          <NavigationItem
            title="locations"
            isOpened={isOpened}
            onClick={() => setIsOpened(!isOpened)}
            mobile
          >
            <Search
              value={search}
              onChange={setSearch}
              variant="outlined-dark"
            />
            {search ? (
              <Countries
                countries={countries}
                search={search}
                selectedLocation={selectedLocation}
              />
            ) : (
              <StyledLocationsList
                selectedLocation={selectedLocation}
                onClick={setSelectedLocation}
                setCurrentStep={handleClick}
              />
            )}
          </NavigationItem>
        </>
      )}
      {currentStep === "countries" && (
        <>
          {isOpened && (
            <StyledButton onClick={() => setCurrentStep("locations")}>
              <StyledIcon name="arrowAltRight" />
              <span>Locations</span>
            </StyledButton>
          )}
          <NavigationItem
            title={selectedLocation}
            isOpened={isOpened}
            onClick={() => setIsOpened(!isOpened)}
            mobile
          >
            <Countries
              countries={countries}
              search={search}
              selectedLocation={selectedLocation}
            />
          </NavigationItem>
        </>
      )}
    </div>
  );
};
