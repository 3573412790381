import PT from "prop-types";

import { StyledSkeleton } from "../Skeleton/components/SkeletonTableComponents.styled";

import { StyletTitle } from "./Title.styled";

export const Title = ({
  tag = "h2",
  className,
  size,
  color,
  children,
  loading = false
}) => {
  const Tag = StyletTitle?.[tag] ? StyletTitle[tag] : StyletTitle.div;

  return (
    <Tag size={size} color={color} className={className}>
      {!loading ? children || "" : <StyledSkeleton width="70%" />}
    </Tag>
  );
};

Title.propTypes = {
  title: PT.string,
  tag: PT.string,
  className: PT.string,
  size: PT.oneOf(["sm"]),
  color: PT.oneOf(["white"]),
  loading: PT.bool
};
