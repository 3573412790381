import { useMemo } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import {
  StyledAnimationWrapper,
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledImage,
  StyledList,
  StyledListItem,
  StyledPicture,
  StyledStep,
  StyledTitle
} from "./HowToFix.styled";

export const HowToFix = ({
  data = [],
  title,
  imageMobile,
  animation,
  className,
  animationClassName
}) => {
  const { t } = useTranslation();

  const memoSteps = useMemo(
    () =>
      data.map((item, index) => (
        <StyledListItem key={item}>
          <StyledStep>{`${t("ui.step")} ${index + 1}`}</StyledStep>
          <StyledDescription>{item}</StyledDescription>
        </StyledListItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <StyledContainer className={className}>
      <StyledContent className="content-wrapper">
        <StyledTitle tag="h2">{title}</StyledTitle>
        <StyledList>{memoSteps}</StyledList>
      </StyledContent>
      <StyledAnimationWrapper
        className={`${animationClassName} animation-wrapper`}
      >
        {animation ? animation : null}
      </StyledAnimationWrapper>
      <StyledPicture>
        <source srcSet={imageMobile} />
        <StyledImage src={imageMobile} alt={title} />
      </StyledPicture>
    </StyledContainer>
  );
};

HowToFix.propTypes = {
  data: PT.arrayOf(PT.string).isRequired,
  imageDesktop: PT.node.isRequired,
  imageMobile: PT.node.isRequired,
  title: PT.string
};
