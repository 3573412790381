import { useSelector } from "react-redux";

import Status from "../../../../../components/ui/New/Status/Status";
import Tooltip from "../../../../../components/ui/New/Tooltip/Tooltip";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllCabinet } from "../../../../../store/reducers/CabinetReducer/Cabinet.selectors";
import Icon from "../../../../ui/New/Icon/Icon";

import { StyledStatusCell } from "./StatusCell.styled";

export const StatusCell = ({ status, tooltipText }) => {
  const [queryLang] = useLangUrlDefault();

  const { siteStatuses } = useSelector(getAllCabinet);

  const currentStatus = siteStatuses.data?.length
    ? siteStatuses.data.find((item) => item.type === status)
    : null;
  const name = currentStatus?.translations?.[queryLang] || currentStatus?.name;

  return (
    <StyledStatusCell>
      <Status
        size={"sm"}
        bordered={false}
        label={name}
        color={currentStatus?.color || "default"}
      />
      {status === "inactive" && (
        <Tooltip place={"bottom"} body={tooltipText}>
          <Icon name="infoChat" />
        </Tooltip>
      )}
    </StyledStatusCell>
  );
};
