import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledTableTabs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: fit-content;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 16px;
  gap: 8px;
  @media screen and (min-width: 993px) {
    padding-bottom: 12px;
  }
`;

export const StyledTableTabText = styled.span`
  &::before {
    display: block;
    content: attr(id);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding-left: 2px;
  }
`;

export const StyledTableLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  padding: 8px;
  height: 40px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  background-color: ${(p) => p.theme.colors["background-color"]} !important;
  background-image: none;
  white-space: nowrap;
  font-weight: 400;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    transition: all 0.3s ease;

    background-color: #eeeff1 !important;
    color: ${(p) => p.theme.colors["neutral-800"]};
  }

  &:active,
  &.activeTab {
    transition: all 0.3s ease;

    background-color: ${(p) => p.theme.colors["main-500"]} !important;
    outline: none !important;
    color: ${(p) => p.theme.colors["neutral-800"]};
    font-weight: 600;
  }
`;
