export const scrollTo = (elementId) => {
  const anchorTarget = document.getElementById(elementId);

  if (anchorTarget) {
    anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const scrollToTagName = (tag) => {
  const anchorTarget = document.document.getElementsByTagName(tag);
  if (anchorTarget) {
    anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const smoothScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

export const scrollToWithOffset = (elementId, offset = 0) => {
  const anchorTarget = document.getElementById(elementId);
  if (anchorTarget) {
    const y =
      anchorTarget.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};
