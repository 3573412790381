import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { ApiService } from "../../../../../../services";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import {
  getAllProxySite,
  selectProxySitesType
} from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { ProxyTable } from "../ProxyTable/ProxyTable";

import "./ProxyTypeTables.scss";

const NUMBER_PROXY_SERVICES_DISPLAY = 5;

export const ProxyTypeTables = () => {
  // **Dispatch
  const { setProxySitesType } = useDispatchedActions();

  // **Redux state
  const { proxySitesType } = useSelector(getAllProxySite);
  const { proxyTypes } = useSelector(getAllContent);
  const proxySitesTypes = useSelector(selectProxySitesType);

  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const typesLang = (proxyTypes.data?.[queryLang] || []).reduce((obj, it) => {
    obj[it.type] = it;

    return obj;
  }, {});

  const [proxyTypesLang, setProxyTypesLang] = useState(typesLang);

  useEffect(() => {
    setProxyTypesLang(typesLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  useEffect(() => {
    const getTypeProxySitesData = async () => {
      try {
        const response = await ApiService.getTypeProxySites(
          NUMBER_PROXY_SERVICES_DISPLAY
        );

        if (response && response.status !== 200) {
          throw response;
        }

        setProxySitesType(response.data);
      } catch (err) {
        toast.error(t("notifications.apiError"));
      }
    };

    if (!proxySitesType.isDataLoaded) {
      getTypeProxySitesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    Object.keys(proxyTypesLang)?.length > 0 &&
    proxySitesTypes.map((it = {}, i) => {
      const [currentProxyType] = Object.keys(it);

      return (
        <div
          className="proxy-content__item proxy-table-item"
          key={`${currentProxyType}-${i}`}
        >
          <div className="proxy-content__top">
            <div className="proxy-content__top-title">
              <h1>
                {proxyTypesLang[currentProxyType]?.name}{" "}
                <Link
                  to={`${hrefLang}/proxy/?fpt=${currentProxyType}`}
                  className="proxy-content__link"
                >
                  {it[currentProxyType]?.totalElements}{" "}
                  {t("proxyPage.content.table.title.services")}
                </Link>
              </h1>
            </div>
          </div>
          <div className="proxy-content__table">
            <ProxyTable
              tableDate={it[currentProxyType]?.content}
              params={{ page: 0, size: NUMBER_PROXY_SERVICES_DISPLAY }}
              isLoading={!proxySitesType.isDataLoaded}
            />
          </div>
        </div>
      );
    })
  );
};
