export const blogInnerInitialState = {
  content: {
    data: {
      articleBody: "",
      attachments: [],
      category: false,
      commentCount: 0,
      coverImageId: null,
      coverSmallImageId: null,
      creationDate: "",
      description: "",
      id: "",
      nextArticleAlias: null,
      nextArticleId: "",
      nextArticleTitle: "",
      popular: false,
      previousArticleAlias: null,
      previousArticleId: null,
      previousArticleTitle: null,
      title: "",
      userId: "",
      userName: "",
      alias: "",
      translationId: ""
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  comments: {
    data: {
      content: [],
      totalElements: 0,
      totalPages: 0
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  sidebar: {
    data: {
      latest: [],
      popular: [],
      languageCode: null
    },
    isLoading: false,
    isError: null,
    isDataLoaded: false
  }
};
