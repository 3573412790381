export const currentSeoBlockTitle = (title, proxyTypeName, countryNameGen) => {
  if (!title) return "";

  if (countryNameGen) {
    return title
      .replace("{{proxyType}}", "")
      .replace("{{country}}", countryNameGen)
      .replace("  ", " ");
  }

  return title
    .replace("{{proxyType}}", proxyTypeName)
    .replace("{{country}}", "")
    .replace("  ", " ");
};
