import styled from "@emotion/styled";
import Rive from "@rive-app/react-canvas";

export const StyledRive = styled(Rive)`
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : "auto")};
  left: ${(props) => (props.left ? `${props.left}px` : "auto")};
  right: ${(props) => (props.right ? `${props.right}px` : "auto")};
  bottom: ${(props) => (props.bottom ? `${props.bottom}px` : "auto")};
  width: ${(props) =>
    props.width
      ? props.width
      : `calc(100% + ${props.left * -1}px + ${props.right * -1}px)`};
  height: ${(props) =>
    props.height
      ? props.height
      : `calc(100% + ${props.top * -1}px + ${props.bottom * -1}px)`};

  @media (max-width: ${(props) =>
    props.breakpoint ? `${props.breakpoint - 1}px` : "$md-max"} {
    & > canvas {
      width: 0;
      height: 0;
    }
  }
`;

export const StyledRiveWrapper = styled.div`
  z-index: 0;
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden;
  @media (min-width: ${(props) => props.breakpoint}px) {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
`;
