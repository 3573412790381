import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Tabs } from "../../../../../../components/common";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

export const ProxyTabs = ({ currentTab, setTab, hashType }) => {
  const { t } = useTranslation();

  // **Redux state
  const { reviewsAmount } = useSelector(getAllProxySite);

  const routeHandler = (newHash) => {
    setTab(newHash);
  };

  return (
    <div className="proxy-site__tabs">
      <Tabs
        currentTab={currentTab}
        tabs={t("proxySitePage.content.tabs", { returnObjects: true })}
        onTabClick={routeHandler}
        reviewsAmount={reviewsAmount.data}
        hashType={hashType}
      />
    </div>
  );
};
