import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SidebarButton } from "../../../../../components/ui/SidebarButton/SidebarButton";
import { getAllBlogInner } from "../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { SidebarItem } from "./SidebarItem/SidebarItem";

export const Sidebar = () => {
  // **Props
  const { t } = useTranslation();

  // **Redux state
  const { sidebar } = useSelector(getAllBlogInner);

  // **Local state
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  const clickHandler = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  return (
    <>
      <aside
        className={
          isSidebarOpened
            ? "blog-inner__sidebar blog-inner__sidebar--active"
            : "blog-inner__sidebar"
        }
      >
        {sidebar.data?.latest?.length !== 0 && (
          <div className="blog-inner__sidebar-item">
            <div className="blog-inner__sidebar-title">
              {t("blogInnerPage.sidebar.latest.title")}
            </div>
            <ul className="blog-inner__sidebar-list">
              {sidebar.data?.latest?.map((post) => (
                <SidebarItem
                  key={post?.id}
                  post={post}
                  closeSidebar={setIsSidebarOpened}
                />
              ))}
            </ul>
          </div>
        )}
        {sidebar.data?.popular?.length !== 0 && (
          <div className="blog-inner__sidebar-item">
            <div className="blog-inner__sidebar-title">
              {t("blogInnerPage.sidebar.popular.title")}
            </div>
            <ul className="blog-inner__sidebar-list">
              {sidebar.data?.popular?.map((post) => (
                <SidebarItem
                  key={post?.id}
                  post={post}
                  closeSidebar={setIsSidebarOpened}
                />
              ))}
            </ul>
          </div>
        )}
      </aside>
      <div className="lgMax">
        <SidebarButton
          position="right"
          isActive={isSidebarOpened}
          onClick={clickHandler}
        />
      </div>
    </>
  );
};
