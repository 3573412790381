import { useTranslation } from "react-i18next";

import animation from "../../../../assets/animations/browser_information.riv";
import {
  browserInformationHeroImageMobile,
  browserInformationHeroImageMobile2x,
  fingerprintFaqImage,
  fingerprintFaqImage2x
} from "../../../../assets/img";
import SeoSection from "../../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";

import { BrowserInformation } from "./BrowserInformation/BrowserInformation";
import {
  RestyledMainSection,
  StyledFAQSection,
  StyledSection
} from "./BrowserInformationPage.styled";

const BrowserInformationPage = () => {
  const { t } = useTranslation();
  return (
    <StyledSection>
      <RestyledMainSection
        title={t("browserInformation.main.title")}
        subtitle={t("browserInformation.main.subtitle")}
        button={false}
        images={{
          mobile1x: browserInformationHeroImageMobile,
          mobile2x: browserInformationHeroImageMobile2x
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-69}
            right={-91}
            bottom={-140}
            left={-91}
          />
        }
      />
      <BrowserInformation />
      <StyledFAQSection
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </StyledSection>
  );
};

export default BrowserInformationPage;

BrowserInformationPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: {}
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        languageCode: params.locale,
        pageTag: params.tag
      })
    );
  }
};
