import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Container } from "../../../../components/ui";
import { Typography } from "../../../../components/ui/Typography/Typography";
// import { useMetaData } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";

import { BlogTab } from "./BlogTab/BlogTab";

import "./Blog.scss";

export const Blog = () => {
  // **Props
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentTab = searchParams.get("tab");

  // **Redux state
  const {
    routeContent,
    pageContent: { current }
  } = useSelector(getAllContent);
  // const metadata = useMetaData();

  const setTab = (newTab = "") => {
    setSearchParams(
      {
        tab: newTab || "articles"
      },
      {
        replace: !newTab
      }
    );
  };

  useEffect(() => {
    const blogPage = t("blogPage.tabs", { returnObjects: true });

    if (!blogPage.map((tab) => tab.name).includes(currentTab)) {
      setTab();
    }

    if (!currentTab) {
      setTab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <section className="blog">
      <Container isFluid={routeContent.isContainerFluid}>
        <Typography weight="bold" size="very-large" padding="bottom-large">
          <h1>{current?.data?.h1}</h1>
        </Typography>

        {currentTab === "articles" && (
          <BlogTab category emptyText={t("blogPage.emptyArticles")} />
        )}
        {currentTab === "news" && (
          <BlogTab emptyText={t("blogPage.emptyNews")} />
        )}
      </Container>
    </section>
  );
};
