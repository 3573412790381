import "./FeedbackCount.scss";

export const FeedbackCount = ({
  showIcon = false,
  positive = 0,
  negative = 0,
  centered,
  Tag = "div",
  ...rest
}) => {
  const defineClassname = () => {
    let className = "feedback-count";

    if (showIcon) {
      className += " feedback-count--icon";
    }

    if (centered) {
      className += " feedback-count--center";
    }

    return className;
  };

  return (
    <Tag className={defineClassname()} {...rest}>
      <div className="feedback-count__positive">+{positive} /</div>
      <div className="feedback-count__negative">-{negative}</div>
    </Tag>
  );
};
