import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  padding: 40px 16px 0;
  min-width: 320px;
  margin: 0 auto;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: 64px 0 0;
    margin-bottom: 120px;
    width: 100%;
    max-width: 100%;
  }

  @media (min-width: 1500px) {
    max-width: 1600px;
  }
`;

export const StyledContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 32px;
    
    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
       flex-direction: row;
       align-items: stretch;
       justify-content: space-between;
       gap: 12px;
    `;

export const StyledTitleContainer = styled.div`
  margin-bottom: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: 0 32px;
    margin-bottom: 48px;
  }
`;

export const StyledSiteNotFound = styled.div`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  font-size: ${(p) => p.theme.fontSizes.xl};
  color: ${(p) => p.theme.colors["neutral-500"]};
  line-height: 140%;
  text-align: center;
  width: 100%;
  padding: 32px;
`;
