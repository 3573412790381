import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import FormBlock from "../../../components/FormBlock/FormBlock";

import { StyledBlockContactsBody } from "./BlockContacts.styled";
import { Contact } from "./Contact/Contact";

export const BlockContacts = () => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const { socials } = useSelector(getAllContent);
  return socials?.data?.length > 0 ? (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.contacts.title")}
      subtitle={t("dashboard.services.addEdit.blocks.contacts.subtitle")}
    >
      <StyledBlockContactsBody>
        {socials.data.map((item) => (
          <Contact key={item.id} name={item.code} label={item.name} />
        ))}
      </StyledBlockContactsBody>
    </FormBlock>
  ) : null;
};
