import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "../SkeletonTable/SkeletonTable.scss";

export const SkeletonResultsTable = ({ cells }) => (
  <SkeletonTheme baseColor="#d9d9d9" highlightColor="#adadad">
    <div className="skeleton-table">
      {cells &&
        Array(cells)
          .fill(0)
          .map((item, i) => (
            <div key={`item-skeleton-${i}`} className="skeleton-row">
              <div
                className="skeleton-table__col"
                style={{
                  width: "33%"
                }}
              >
                  <Skeleton width={180} height={36} />
              </div>
              <div
                className="skeleton-table__col"
                style={{
                  width: "33%"
                }}
              >
                <Skeleton width={200} height={36} />
              </div>
              <div
                className="skeleton-table__col"
                style={{
                  width: "33%"
                }}
              >
                <Skeleton width={200} height={36} />
              </div>
            </div>
          ))}
    </div>
  </SkeletonTheme>
);
