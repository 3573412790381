import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledIcon, StyledReadMore, StyledWrapper } from "./ReadMore.styled";

export const ReadMore = ({ variant }) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper variant={variant}>
      <StyledReadMore variant={variant}>
        {t("blogPage.item.readMore")}
      </StyledReadMore>
      <StyledIcon name="arrowAltRight" variant={variant} />
    </StyledWrapper>
  );
};

ReadMore.propTypes = {
  variant: PT.oneOf(["dark", "light"])
};
