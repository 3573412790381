import { useId } from "react";

import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { components } from "react-select";

import Icon from "../../../ui/New/Icon/Icon";
import { ReactSelectStyled } from "../../../ui/New/Select/Select.styled";
import { FormFieldWrapper } from "../FormFieldWrapper/FormFieldWrapper";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon name="arrowDown" size="lg" />
  </components.DropdownIndicator>
);

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Icon name="closeModal" />
  </components.MultiValueRemove>
);

export const FormSelect = ({
  id,
  name,
  options,
  defaultValue,
  rules,
  onChange,
  disabled = false,
  className,
  placeholder,
  multiple = false,
  label,
  noData,
  showError = false,
  returnObject = false,
  color
}) => {
  // ** Hooks
  const { control } = useFormContext();
  const { t } = useTranslation();
  const instanceId = useId();

  const currentValue = (data) => {
    if (!data) return multiple ? [] : null;
    const dataIsArray = Array.isArray(data);
    if (dataIsArray && !data?.length) return multiple ? [] : null;
    if (!multiple) {
      const currentData = dataIsArray ? data?.[0] : data;
      const currentValue =
        typeof currentData?.value !== "undefined"
          ? currentData?.value
          : currentData;
      return options.find((option) => option.value === currentValue);
    }
    const currentData = dataIsArray ? data : [data];
    const currentValue =
      typeof currentData?.value !== "undefined"
        ? currentData
        : currentData.map((item) => item.value);
    return currentValue.map((item) =>
      options.find((option) => option.value === item)
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={currentValue(defaultValue)}
      render={({ field, fieldState: { error } }) => (
        <FormFieldWrapper
          className={className}
          label={label}
          error={error?.message}
          showError={showError}
          htmlFor={id}
        >
          <ReactSelectStyled
            classNamePrefix="form-select"
            ref={field.ref}
            id={id}
            instanceId={instanceId}
            name={name}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
              MultiValueRemove
            }}
            value={currentValue(field.value)}
            color={color}
            isError={!!error}
            blurInputOnSelect
            placeholder={placeholder}
            isDisabled={disabled}
            noOptionsMessage={() => (noData ? noData : t("forms.noOptions"))}
            options={options}
            onChange={(option) => {
              if (!option) return;

              if (returnObject) {
                field.onChange(option);
                if (onChange) {
                  onChange(option);
                }
              } else if (!multiple) {
                field.onChange(option.value);
                onChange(option.value);
              } else {
                field.onChange(option.map((item) => item.value));
                onChange(option.map((item) => item.value));
              }
            }}
            isClearable={false}
            isMulti={multiple}
          />
        </FormFieldWrapper>
      )}
    />
  );
};

FormSelect.propTypes = {
  id: PT.string,
  name: PT.string.isRequired,
  options: PT.array.isRequired,
  defaultValue: PT.oneOfType([PT.string, PT.array]),
  rules: PT.object,
  onChange: PT.func,
  disabled: PT.bool,
  className: PT.string,
  placeholder: PT.string,
  multiple: PT.bool,
  label: PT.node,
  showError: PT.bool,
  noData: PT.node,
  returnObject: PT.bool
};
