import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SubscribePromoModal } from "../../../../../../components/common/New/Modals/SubscribePromoModal/SubscribePromoModal";
import { Promocode } from "../../../../../../components/common/New/Promocodes/Promocode";
import Pagination from "../../../../../../components/ui/New/Pagination/Pagination";
import TextButton from "../../../../../../components/ui/New/TextButton/TextButton";
import { useLangUrlDefault } from "../../../../../../hooks";
import { getAllPromocodes } from "../../../../../../store/reducers/PromocodesReducer/Promocodes.selectors";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../../../../../store/reducers/UserReducer/User.selectors";
import { TabTitle } from "../TabTitle/TabTitle";

import {
  StyledPaginationBox,
  StyledPromocodesBlock,
  StyledPromocodesBlockHead,
  StyledPromocodesList,
  StyledShowMore
} from "./Promocodes.styled";

export const Promocodes = ({ active }) => {
  // **Props
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const { isUserAuthenticated } = useSelector(getAllUser);

  // **Redux state
  const { site } = useSelector(getAllProxySite);
  const { promocodes } = useSelector(getAllProxySite);
  const { alternatePromocodes } = useSelector(getAllPromocodes);
  const getCurrentPromocodes = () => {
    if (promocodes.data?.length > 0) {
      return promocodes.data?.filter(
        (item) =>
          Object.keys(item.showIn)?.length === 0 ||
          (Object.keys(item.showIn)?.length > 0 && item.showIn[queryLang])
      );
    }
    if (
      !promocodes.isLoading &&
      promocodes.data?.length === 0 &&
      alternatePromocodes.data?.length > 0
    ) {
      return alternatePromocodes.data?.filter(
        (item) =>
          Object.keys(item.showIn)?.length === 0 ||
          (Object.keys(item.showIn)?.length > 0 && item.showIn[queryLang])
      );
    }
    return [];
  };
  const [showedPromocodes, setShowedPromocodes] = useState([]);
  const [showNoData, setShowNoData] = useState(null);
  const [currentPromocodes, setCurrentPromocodes] = useState(
    getCurrentPromocodes()
  );
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    size: 5
  });

  const triggerClose = () => setOpen((prev) => !prev);

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const handleSizeChange = () => {
    setParams({ page: 1, size: params.size + 5 });
  };
  // const promocodesList = currentPromocodes();
  useEffect(() => {
    if (!promocodes.isLoading && promocodes.isDataLoaded) {
      if (currentPromocodes?.length > 0) {
        const form = params.size * (params.page - 1);
        const to = form + params.size;
        const promo = currentPromocodes.slice(form, to);
        setShowedPromocodes(promo);
        setShowNoData(false);
      } else {
        setShowedPromocodes([]);
        setShowNoData(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.page, params?.size, currentPromocodes]);

  useEffect(() => {
    setParams({ page: 1, size: 5 });
    setCurrentPromocodes(getCurrentPromocodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, promocodes.data, alternatePromocodes.data]);

  return (
    <StyledPromocodesBlock active={active}>
      <StyledPromocodesBlockHead>
        <TabTitle
          imgSrc={"/img/site-page/Promocodes.png"}
          title={t("proxySitePage.about.promocodes.title")}
        />
        {isUserAuthenticated ? (
          <TextButton
            color={"secondary"}
            iconLeft={"mail"}
            onClick={triggerClose}
          >
            {t("proxySitePage.about.promocodes.subscribe")}
          </TextButton>
        ) : null}
        <SubscribePromoModal
          open={open}
          onClose={triggerClose}
          siteId={site?.data?.id}
        />
      </StyledPromocodesBlockHead>
      {showedPromocodes?.length > 0 ? (
        <>
          <StyledPromocodesList>
            {showedPromocodes?.map((promocode) => (
              <li key={promocode.id}>
                <Promocode
                  variant={"light"}
                  promocode={promocode?.promocode}
                  description={promocode?.description?.[queryLang]}
                  title={promocode?.siteName}
                  image={promocode?.imageUrl}
                  time={promocode?.endTime}
                  eternal={promocode?.eternal}
                  siteId={site?.data?.id}
                />
              </li>
            ))}
          </StyledPromocodesList>
          {currentPromocodes?.length > 0 &&
            currentPromocodes?.length > params.size && (
              <StyledPaginationBox>
                {currentPromocodes?.length > params.size * params.page && (
                  <StyledShowMore
                    onClick={handleSizeChange}
                    iconRight={"refresh"}
                  >
                    {t("reviews.showMore")}
                  </StyledShowMore>
                )}
                <Pagination
                  rowCount={currentPromocodes?.length}
                  rowsPerPage={params.size}
                  currentPage={params.page}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  onChangePage={handlePageChange}
                />
              </StyledPaginationBox>
            )}
        </>
      ) : null}
      {showNoData ? (
        <p>{t("proxySitePage.content.promocodes.noData")}</p>
      ) : null}
      {!promocodes.isDataLoaded && (
        <StyledPromocodesList>
          {[...Array(2)].map((_, index) => (
            <li key={index}>
              <Promocode loading variant={"light"} />
            </li>
          ))}
        </StyledPromocodesList>
      )}
    </StyledPromocodesBlock>
  );
};
