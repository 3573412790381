import * as yup from "yup";

const regexIP =
/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const IpBlacklistCheckSchema = (trns) =>
  yup.object().shape({
    ipAddress: yup
      .string()
      .required(trns.form.rules.required)
      .matches(regexIP, trns.form.rules.ip)
  });
