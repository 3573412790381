import { ReviewItem } from "./ReviewItem/ReviewItem";

import "./Reviews.scss";

export const Reviews = ({
  captchaRef,
  setTokenCaptcha,
  reviews = [],
  changeHandler,
  complaint = false,
  nestedResponseValue = 1
}) =>
  reviews?.map((review) => (
    <ReviewItem
      captchaRef={captchaRef}
      setTokenCaptcha={setTokenCaptcha}
      key={review.id}
      review={review}
      changeHandler={changeHandler}
      complaint={complaint}
      isRootReview={nestedResponseValue === 1 && review?.responses?.length > 0}
      nestedResponseValue={nestedResponseValue}
    />
  ));
