import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { BlogSearch } from "../BlogSearch/BlogSearch";
import { Tab, Tabs, TabsContainer, TabsWrapper } from "./BlogTabs.styled";

export const BlogTabs = ({ pageChangeHandler }) => {
  const { t } = useTranslation();
  // **Props
  const [searchParams, setSearchParams] = useSearchParams();

  const tabs = t("blogPage.tabs", { returnObjects: true });

  const handleClickTab = (name) => {
    searchParams.set("tab", name);
    setSearchParams(searchParams);
    pageChangeHandler(1);
  };

  return (
    <TabsWrapper>
      <BlogSearch />
      <TabsContainer>
        <Tabs>
          {tabs?.map((tab) => (
            <Tab
              key={tab.name}
              active={searchParams.get("tab") === tab.name}
              onClick={(evt) => {
                evt.preventDefault();
                handleClickTab(tab.name);
              }}
              title={tab.title}
              role="button"
            >
              {tab.title}
            </Tab>
          ))}
        </Tabs>
      </TabsContainer>
    </TabsWrapper>
  );
};
