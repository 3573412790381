import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledYesNoBox, StyledYesNoDot } from "./YesNo.styled";

export const YesNo = ({ value, dot = true, className = "" }) => {
  // **Redux state
  const { t } = useTranslation();

  return (
    <StyledYesNoBox className={className}>
      {dot ? <StyledYesNoDot value={value} /> : null}
      {value ? t("ui.yesNo.yes") : t("ui.yesNo.no")}
    </StyledYesNoBox>
  );
};

YesNo.propTypes = {
  value: PT.bool.isRequired,
  className: PT.string,
  dot: PT.bool
};

export default YesNo;
