import { useRef, useEffect } from "react";

export const useDebouncedFunction = (func, delay, cleanUp = false) => {
  const timeoutRef = useRef();

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  // eslint-disable-next-line
  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => func(...args), delay);
  };
};
