
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Container } from "../../../../components/ui";
import { Typography } from "../../../../components/ui/Typography/Typography";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { Cards } from "./Cards/Cards";

import "./Hero.scss";

export const Hero = () => {
  const { t } = useTranslation();

  // **Redux state
  const { routeContent } = useSelector(getAllContent);

  return (
    <section className="hero">
      <Container isFluid={routeContent.isContainerFluid}>
        <Typography weight="bold" size="very-large" padding="bottom-large">
          {t("mainPage.title")}
        </Typography>
        <Typography weight="bold" size="large" padding="bottom-large" color>
          {t("mainPage.subtitle.startLine")}{" "}
          <span>{t("mainPage.subtitle.colorLine")}</span>{" "}
          {t("mainPage.subtitle.endLine")}
        </Typography>
        <div className="hero__description">
          <p>{t("mainPage.description")}</p>
        </div>
        <Cards />
      </Container>
    </section>
  );
};

