import { useFormContext } from "react-hook-form";

import "./DichotRadio.scss";

export const DichotRadio = ({
  name,
  label,
  options,
  defaultValue,
  rules,
  showError,
  ...rest
}) => {
  const [dichotRadioYes, dichotRadioNo] = options;

  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <div className="form-dichot-radio__item">
      {label && <span className="form-dichot-radio__title">{label}</span>}
      <ul className="form-dichot-radio__options">
        <li className="form-dichot-radio__option">
          <input
            className="form-dichot-radio__input"
            id={`${name}-yes`}
            type="radio"
            name={name}
            value={dichotRadioYes.value}
            defaultChecked={defaultValue.value}
            {...register(name, rules)}
            {...rest}
          />
          <label className="form-dichot-radio__label" htmlFor={`${name}-yes`}>
            {dichotRadioYes.label}
          </label>
        </li>
        <li className="form-dichot-radio__option">
          <input
            className="form-dichot-radio__input"
            id={`${name}-no`}
            type="radio"
            name={name}
            value={dichotRadioNo.value}
            defaultChecked={!defaultValue.value}
            {...register(name, rules)}
            {...rest}
          />
          <label className="form-dichot-radio__label" htmlFor={`${name}-no`}>
            {dichotRadioNo.label}
          </label>
        </li>
      </ul>
      {showError && errors[name]?.message && (
        <div className="form-input__error">{errors[name]?.message}</div>
      )}
    </div>
  );
};
