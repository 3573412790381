import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getBlogContent = createAsyncThunk(
  "content/getBlogContent",
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await ApiService.getBlogContent(params, signal);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return { ...response.data, params };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setBlogContent = createAction("content/setBlogContent");
