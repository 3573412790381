import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledIndexCell, StyledIndexCellTop } from "./IndexCell.styled";

export const IndexCell = ({ className, top = false, children }) => {
  const { t } = useTranslation();
  return (
    <StyledIndexCell className={className}>
      {top ? (
        <StyledIndexCellTop>
          {t("proxyPage.content.table.index.top")}
        </StyledIndexCellTop>
      ) : null}
      {children}
    </StyledIndexCell>
  );
};

IndexCell.propTypes = {
  className: PT.string,
  children: PT.node,
  top: PT.bool
};
