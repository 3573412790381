import styled from "@emotion/styled";

export const StyledRating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: ${(p) => (p.background ? "8px 12px" : "0")};
  font-family: ${(p) => p.theme.fontFamily.lato};
  border-radius: 8px;
  text-align: left;
  font-weight: 600;
  color: ${(p) => {
    if (p.rating >= 4) {
      return p.theme.colors["main-700"];
    }
    if (p.rating >= 3) {
      return p.theme.colors["yellow"];
    }
    return p.theme.colors["red-500"];
  }};
  background-color: ${(p) => {
    if (p.background) {
      if (p.rating >= 4) {
        return p.theme.colors["rating-background-green"];
      }
      if (p.rating >= 3) {
        return p.theme.colors["rating-background-yellow"];
      }
      return p.theme.colors["rating-background-red"];
    }
    return "transparent";
  }};
  & > svg {
    margin-right: 4px;
    margin-bottom: 2px;
  }
  ${(p) => {
    if (p.size === "sm") {
      return `
    font-size: ${p.theme.fontSizes.xxs};
    & > svg {
        width: 16px;
        height: 15px;
    }
      `;
    }
    if (p.size === "md_s") {
      return `
    font-size: ${p.theme.fontSizes.m};
   & > svg {
        width: 16px;
        height: 15px;
    }`;
    }
    if (p.size === "lg") {
      return `
    font-size: ${p.theme.fontSizes.l};
    & > svg {
        width: 20px;
        height: 19px;
    }`;
    }

    return `
    font-size: ${p.theme.fontSizes.s}; 
    & > svg {
        width: 16px;
        height: 15px;
    }
    `;
  }}
`;
