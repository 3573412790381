import { useId } from "react";

import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import Icon from "../../../ui/New/Icon/Icon";
import { FormError } from "../FormError/FormError";

import {
  CheckboxBox,
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper
} from "./FormCheckbox.styled";

const TYPES = {
  checkbox: "checkbox",
  radio: "radio"
};

export const FormCheckbox = ({
  id,
  name,
  type,
  rules,
  label,
  className,
  defaultValue = false,
  radioValue,
  showError,
  disabled = false,
  iconType,
  radioClearable = false,
  noActionLabel = false,
  ...rest
}) => {
  // ** Props
  const localId = useId();
  // Form
  const { control } = useFormContext();

  const currentIcon = (fieldValue) => {
    const icon = TYPES[iconType] || TYPES[type] || TYPES.checkbox;
    if (type === TYPES.radio) {
      return fieldValue === radioValue ? `${icon}On` : `${icon}Off`;
    } else {
      return fieldValue ? `${icon}On` : `${icon}Off`;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <CheckboxWrapper className={`form-field ${className}`}>
          <CheckboxContainer
            data-checked={
              type === TYPES.radio ? field.value === radioValue : field.value
            }
          >
            <CheckboxBox
              checked={
                type === TYPES.radio ? field.value === radioValue : field.value
              }
              disabled={disabled}
              data-checked={
                type === TYPES.radio ? field.value === radioValue : field.value
              }
            >
              <CheckboxInput
                id={noActionLabel ? id : id || localId}
                type={type}
                disabled={disabled}
                {...field}
                onClick={(e) => {
                  if (radioClearable && type === TYPES.radio) {
                    if (field.value === radioValue) {
                      field.onChange("");
                    } else {
                      field.onChange(radioValue);
                    }
                  } else {
                    field.onChange(
                      type === TYPES.radio ? radioValue : !field.value
                    );
                  }
                  e.target.blur();
                }}
                value={type === TYPES.radio ? radioValue : field.value}
                {...rest}
              />
              <Icon name={currentIcon(field.value)} />
            </CheckboxBox>
            {label && (
              <CheckboxLabel
                htmlFor={noActionLabel ? null : id || localId}
                data-checked={
                  type === TYPES.radio
                    ? field.value === radioValue
                    : field.value
                }
              >
                {label}
              </CheckboxLabel>
            )}
          </CheckboxContainer>
          {showError && error?.message && <FormError message={error.message} />}
        </CheckboxWrapper>
      )}
    />
  );
};

FormCheckbox.PT = {
  id: PT.string,
  name: PT.string.isRequired,
  rules: PT.object,
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  className: PT.string,
  defaultValue: PT.bool,
  noActionLabel: PT.bool,
  disabled: PT.bool,
  radioValue: PT.oneOfType([PT.string, PT.number]),
  type: PT.oneOf([TYPES.checkbox, TYPES.radio]),
  iconType: PT.oneOf([TYPES.checkbox, TYPES.radio]),
  radioClearable: PT.bool
};
