import { useState } from "react";

import { useSelector } from "react-redux";

import Search from "../../../../../components/ui/New/Search/Search";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { proxyServicesList } from "../../../../../utils/helpers/serviceMenu.helpers";
import { Countries } from "../Countries/Countries";

import {
  StyledLocationsList,
  StyledProxiesNavigation,
  StyledProxiesNavigationItem
} from "./ProxiesNavigation.styled";

export const ProxiesNavigation = () => {
  const [queryLang] = useLangUrlDefault();
  const [search, setSearch] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("topLocations");
  const { countries, proxyTypesMenu } = useSelector(getAllContent);

  return (
    <StyledProxiesNavigation>
      <StyledProxiesNavigationItem
        title="proxies"
        list={proxyServicesList(proxyTypesMenu?.data?.[queryLang])}
      />
      <StyledProxiesNavigationItem title="locations">
        <Search value={search} onChange={setSearch} variant="outlined-dark" />
        <StyledLocationsList
          selectedLocation={selectedLocation}
          onClick={setSelectedLocation}
        />
      </StyledProxiesNavigationItem>
      <StyledProxiesNavigationItem title={selectedLocation}>
        <Countries
          countries={countries}
          search={search}
          selectedLocation={selectedLocation}
        />
      </StyledProxiesNavigationItem>
    </StyledProxiesNavigation>
  );
};
