export const mockTableData = [
  {
    attribute: "User agent",
    similarityRatio: "0.31",
    value:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36"
  },
  {
    attribute: "Accept",
    similarityRatio: "10.5",
    value:
      "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7"
  },
  {
    attribute: "Content encoding",
    similarityRatio: "91.00",
    value: "gzip, deflate, br"
  },
  {
    attribute: "Content language",
    similarityRatio: "0.31",
    value: "en,ru;q=0.9,ru-RU;q=0.8,en-US;q=0.7"
  },
  {
    attribute: "If none match",
    similarityRatio: "0.00",
    value: "8dfe7-yvJZw3KyBcwjkzOolNT2DC3UFnU"
  },
  {
    attribute: "Upgrade Insecure Requests",
    similarityRatio: "0.01",
    value: "1"
  },
  {
    attribute: "Referer",
    similarityRatio: "15.25",
    value: "https://amiunique.org/fingerprint/nojs"
  },
  {
    attribute: "User agent",
    similarityRatio: "0.00",
    value:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36"
  },
  {
    attribute: "User agent",
    similarityRatio: "88.88",
    value:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36"
  },
  {
    attribute: "User agent",
    similarityRatio: "0.31",
    value:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36"
  },
  {
    attribute: "User agent",
    similarityRatio: "0.31",
    value:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36"
  }
];

export const mockPortCheckedData = [
  {
    port: "109",
    protocol: "POP",
    description: ""
  },
  {
    port: "110",
    protocol: "POP3",
    description: ""
  },
  {
    port: "115",
    protocol: "Simple FTP",
    description: ""
  },
  {
    port: "118",
    protocol: "SQL Services",
    description: ""
  },
  {
    port: "119",
    protocol: "NNTP",
    description: ""
  },
  {
    port: "123",
    protocol: "NTP",
    description: ""
  },
  {
    port: "128",
    protocol: "NetBIOS",
    description: ""
  },
  {
    port: "109",
    protocol: "POP",
    description: ""
  },
  {
    port: "109",
    protocol: "POP",
    description: ""
  },
  {
    port: "109",
    protocol: "POP",
    description: ""
  },
  {
    port: "109",
    protocol: "POP",
    description: ""
  }
];
