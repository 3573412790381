import { createSlice } from "@reduxjs/toolkit";

import { freeProxyInitialState } from "./FreeProxy.initialState";
import {
  setAllFreeProxyContentReducer,
  setCitiesReducer,
  setFreeProxyContentReducer
} from "./FreeProxy.reducer";
import {
  getAllFreeProxyContent,
  getCities,
  getFreeProxyContent,
  setAllFreeProxyContent,
  setCities,
  setFreeProxyContent
} from "./FreeProxy.thunks";

const FreeProxySlice = createSlice({
  name: "freeproxy",
  initialState: freeProxyInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setFreeProxyContent, setFreeProxyContentReducer)
      .addCase(setAllFreeProxyContent, setAllFreeProxyContentReducer)
      .addCase(setCities, setCitiesReducer)

      // getFreeProxyContent
      .addCase(getFreeProxyContent.pending, (state) => {
        state.freeProxy.isError = null;
        state.freeProxy.isLoading = true;
      })
      .addCase(getFreeProxyContent.fulfilled, (state, action) => {
        state.freeProxy.isError = null;
        state.freeProxy.data = action.payload;
        state.freeProxy.isLoading = false;
        state.freeProxy.isDataLoaded = true;
      })
      .addCase(getFreeProxyContent.rejected, (state, action) => {
        state.freeProxy.isError = action.payload;
        state.freeProxy.isLoading = false;
      })

      // getAllFreeProxyContent
      .addCase(getAllFreeProxyContent.pending, (state) => {
        state.allFreeProxy.isError = null;
        state.allFreeProxy.isLoading = true;
      })
      .addCase(getAllFreeProxyContent.fulfilled, (state, action) => {
        state.allFreeProxy.isError = null;
        state.allFreeProxy.data = action.payload;
        state.allFreeProxy.isLoading = false;
        state.allFreeProxy.isDataLoaded = true;
      })
      .addCase(getAllFreeProxyContent.rejected, (state, action) => {
        state.allFreeProxy.isError = action.payload;
        state.allFreeProxy.isLoading = false;
      })

      // getCities
      .addCase(getCities.pending, (state) => {
        state.cities.isError = null;
        state.cities.isLoading = true;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.cities.isError = null;
        state.cities.data = action.payload;
        state.cities.isLoading = false;
        state.cities.isDataLoaded = true;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.cities.isError = action.payload;
        state.cities.isLoading = false;
      })
});

export const FreeProxyReducerActions = {
  setFreeProxyContent,
  setAllFreeProxyContent,
  setCities,
  getFreeProxyContent,
  getAllFreeProxyContent,
  getCities
};
export default FreeProxySlice.reducer;
