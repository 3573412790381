import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Modal } from "../../";
import { useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import {
  getCountriesForSelect,
  getCurrencies,
  getGoalsForSelect,
  getProxyTypesForSelect,
  transformMinRent
} from "../../../../utils/helpers";
import { ProxyModalSchema } from "../../../../utils/validation";
import { Input } from "../../../forms/Input/Input";
import { Select } from "../../../forms/Select/Select";
import { Button } from "../../../ui/Button/Button";
import { Loader } from "../../../ui/Loader/Loader";
import { Typography } from "../../../ui/Typography/Typography";

import "./ProxyModal.scss";

export const ProxyModal = ({ ...rest }) => {
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const { countries, goals, rentPeriods, currencies, proxyTypes } =
    useSelector(getAllContent);

  // **Local state
  const [isLoading, setIsLoading] = useState(false);

  // Form
  const methods = useForm({
    resolver: yupResolver(ProxyModalSchema(t("forms", { returnObjects: true })))
  });
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const response = await ApiService.sendProxyQuery({
        ...data,
        price: +data?.price,
        amount: +data?.amount,
        emailType: "PROXY_REQUEST"
      });

      if (response && response.status !== 200) {
        throw response;
      }

      toast.success(t("notifications.proxyQuery"));
      methods.reset();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  // Allow input only digits
  const inputHandler = (e) => {
    e.target.value = e.target.value.replace(/\D/g, "");
  };

  return (
    <Modal {...rest}>
      <Typography
        size="middle"
        weight="semibold"
        padding="bottom-very-large"
        position="center"
      >
        {t("proxyPage.content.modal.title")}
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="proxy-modal__form-row">
            <Select
              name="country"
              showError
              options={getCountriesForSelect(countries?.data, queryLang)}
              placeholder={t("forms.countries.placeholder.one")}
            />
            <Select
              name="goal"
              showError
              options={getGoalsForSelect(goals?.data)}
              placeholder={t("forms.purposeUse.placeholder.one")}
            />
            <Select
              name="proxyType"
              showError
              options={getProxyTypesForSelect(proxyTypes.data?.[queryLang])}
              placeholder={t("forms.proxyType.placeholder")}
            />
            <Select
              name="minRent"
              showError
              options={transformMinRent(
                rentPeriods.data,
                queryLang,
                t("times", { returnObjects: true })
              )}
              placeholder={t("forms.minRent.placeholder")}
            />
            <Select
              name="currency"
              showError
              options={getCurrencies(currencies?.data)}
              placeholder={t("forms.currency.placeholder")}
            />
            <Input
              name="amount"
              type="text"
              showError
              placeholder={t("proxyPage.content.modal.quantity.placeholder")}
              onInput={inputHandler}
            />
            <Input
              name="price"
              type="text"
              showError
              placeholder={t("forms.minPrice.placeholder")}
              onInput={inputHandler}
            />
            <Input
              name="email"
              type="email"
              showError
              placeholder={t("forms.email.label")}
            />
          </div>
          <Button disabled={isLoading} position="center" type="solid">
            {!isLoading ? (
              t("proxyPage.content.modal.btn")
            ) : (
              <Loader
                type="absolute"
                scale={0.4}
                color="var(--clr-default-900)"
              />
            )}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};
