import { useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllCabinet } from "../../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { getAllTranslation } from "../../../../store/reducers/TranslationReducer/Translation.selectors";
import {
  getCabinetSitesForSelect,
  getDefaultDescLangHelper
} from "../../../../utils/helpers";
import { PromocodeSchema } from "../../../../utils/validation";
import { DateRange } from "../../../forms/DateRange/DateRange";
import { Input } from "../../../forms/Input/Input";
import { Select } from "../../../forms/Select/Select";
import { Switch } from "../../../forms/Switch/Switch";
import { Textarea } from "../../../forms/Textarea/Textarea";
import { Button } from "../../../ui/Button/Button";
import { FormTabs } from "../../../ui/FormTabs/FormTabs";
import { Loader } from "../../../ui/Loader/Loader";

export const Form = ({ defaultValues, isEdit }) => {
  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();

  // **Redux state
  const { languages } = useSelector(getAllTranslation);
  const { allProxies, promocodes: cabinetPromocodes } =
    useSelector(getAllCabinet);

  const { t } = useTranslation();

  // **Local state
  const [isLoading, setIsLoading] = useState(false);
  const [activeDescriptionTab, setActiveDescriptionTab] = useState(
    getDefaultDescLangHelper(defaultValues?.description, languages)
  );

  // **Dispatch
  const { getCabinetPromocodes } = useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(PromocodeSchema("forms", languages, t)),
    defaultValues: useMemo(() => defaultValues, [defaultValues])
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const promocodeResponse = !isEdit
        ? await ApiService.createPromocode(data)
        : await ApiService.updatePromocode(data);

      // Handling error for "promocodeResponse" query
      if (promocodeResponse && promocodeResponse.status !== 200) {
        throw promocodeResponse;
      }

      if (!promocodeResponse.data) {
        toast.error(t("notifications.promocode.duplicate"));
        return;
      }

      // Show success message that site has been created / updated
      toast.success(
        isEdit
          ? t("notifications.promocode.edited")
          : t(" notifications.promocode.created")
      );

      // Getting all promocodes
      getCabinetPromocodes(cabinetPromocodes.prevParams);

      // Check if file is added, if no go the main cabinet page
      navigate(`${hrefLang}/cabinet/promocodes/`);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  const defineButtonContent = () => {
    if (isLoading) {
      return (
        <Loader type="absolute" scale={0.4} color="var(--clr-default-900)" />
      );
    }

    if (isEdit) {
      return t("promocodes.btn.edit");
    }

    return t("promocodes.btn.add");
  };

  const changeDescriptionTab = (code) => () => setActiveDescriptionTab(code);

  return (
    <FormProvider {...methods}>
      <form
        className="promocodes__form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Select
          name="siteId"
          showError
          label={t("promocodes.site.label")}
          placeholder=""
          defaultValue={{
            value: defaultValues.siteId,
            label: defaultValues.siteName
          }}
          layout="horizontal"
          margin="mb-large"
          options={getCabinetSitesForSelect(allProxies.data)}
        />
        <Input
          name="promocode"
          type="text"
          showError
          label={t("promocodes.promocode.label")}
          layout="horizontal"
          margin="mb-large"
        />
        <FormTabs
          position="right"
          tabList={languages}
          activeTab={activeDescriptionTab}
          changeTab={changeDescriptionTab}
        />
        {languages.map(({ value }) => (
          <Textarea
            key={`description.${value}`}
            name={`description.${value}`}
            label={t("promocodes.description.label")}
            layout="horizontal"
            margin="mb-large"
            showError
            isDescription
            parentStyles={{
              display: value === activeDescriptionTab ? "" : "none"
            }}
          />
        ))}
        <Switch
          name="eternal"
          label={t("promocodes.eternal.label")}
          showError
          layout="horizontal"
          margin="mb-large"
        />
        {!methods.watch("eternal") && (
          <DateRange
            startRangeName="startDate"
            endRangeName="endDate"
            margin="mb-large"
            label={t("promocodes.date.label")}
            layout="horizontal"
          />
        )}
        <Switch
          name="isActive"
          label={t("promocodes.isActive.label")}
          showError
          layout="horizontal"
          margin="mb-large"
        />
        <Button disabled={isLoading} type="solid" size="large">
          {defineButtonContent()}
        </Button>
      </form>
    </FormProvider>
  );
};
