import { Suspense } from "react";

import { Outlet } from "react-router-dom";

import { Footer } from "../../components/common/New/Footer/Footer";
import { Header } from "../../components/common/New/Header/Header";
import { BurgerMenu } from "../../components/common/New/Menu/BurgerMenu";
import { ServiceMenu } from "../../components/common/New/Menu/ServiceMenu";
import { Loader } from "../../components/ui/Loader/Loader";

export const NewAppLayout = () => (
  <>
    <div className={"wrapper bg-color-main new-layout"}>
      <Header />
      <main>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
      <BurgerMenu />
      <ServiceMenu />
    </div>
  </>
);
