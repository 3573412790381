import { useTranslation } from "react-i18next";

import { Proxy } from "../../../../../../components/common";

export const ProxyEdit = () => {
  // **Redux state
  const { t } = useTranslation();

  return <Proxy title={t("cabinetPages.proxy.edit.title")} isEdit />;
};
