import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { SectionHead } from "../../../../../../components/common/New/SectionHead/SectionHead";
import { useLangUrlDefault, useSeoBlock } from "../../../../../../hooks";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { currentSeoBlockTitle } from "../../../../../../utils/helpers";

export const ProxyContentTop = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const [searchParams] = useSearchParams();
  const { countries } = useSelector(getAllContent);
  const { data } = useSeoBlock(["proxyPageProxyTable"]);

  const activeTab = searchParams.get("fpt") || "IPv4";
  const activeCountry =
    searchParams.get("fc") && activeTab === "IPv4"
      ? searchParams.get("fc")
      : null;
  const country = activeCountry
    ? countries?.data?.find((country) => country.urlParam === activeCountry)
    : null;
  const countryNameGen = country
    ? country?.caseLocalization?.[queryLang]?.gen
    : null;

  return (
    <SectionHead
      title={currentSeoBlockTitle(
        data?.proxyPageProxyTable?.title?.content,
        `${t(`proxyTypesGen.${activeTab}`)}`,
        countryNameGen
      )}
      description={data?.proxyPageProxyTable?.description?.content}
    />
  );
};
