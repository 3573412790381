import { createSlice } from "@reduxjs/toolkit";

import { blogInitialState } from "./Blog.initialState";
import { setBlogContentReducer } from "./Blog.reducer";
import { getBlogContent, setBlogContent } from "./Blog.thunks";

const BlogSlice = createSlice({
  name: "blog",
  initialState: blogInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setBlogContent, setBlogContentReducer)

      // getBlogContent
      .addCase(getBlogContent.pending, (state) => {
        state.content.isError = null;
        state.content.isLoading = true;
      })
      .addCase(getBlogContent.fulfilled, (state, action) => {
        state.content.isError = null;
        state.content.data = action.payload;
        state.content.isLoading = false;
        state.content.isDataLoaded = true;
      })
      .addCase(getBlogContent.rejected, (state, action) => {
        state.content.isError = action.payload;
        state.content.isLoading = false;
      })
});

export const BlogReducerActions = {
  setBlogContent,
  getBlogContent
};
export default BlogSlice.reducer;
