import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ApiService } from "../../../../../../services";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllUser } from "../../../../../../store/reducers/UserReducer/User.selectors";

export const Voting = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler
}) => {
  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);

  const { t } = useTranslation();
  const { isUserAuthenticated } = useSelector(getAllUser);

  // **Local state
  const [isDisableButtons, setIsDisableButtons] = useState({
    like: false,
    dislike: false
  });
  const { enable, headerName } = captchaConfig.data;

  const changeCarma = (step) => async () => {
    let header = {};

    if (enable && !isUserAuthenticated) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      const response = await ApiService.changeCommentCarma(
        review.id,
        step,
        header
      );

      if (response && response.status !== 200) {
        throw response;
      }

      changeHandler();

      setIsDisableButtons({
        ...isDisableButtons,
        like: step === 1,
        dislike: step === -1
      });
      toast.success(t("notifications.vote"));
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      if (enable && !isUserAuthenticated) {
        captchaRef.current?.resetCaptcha();
        setTokenCaptcha(null);
      }
    }
  };

  return (
    <div className="reviews__voting">
      <p>{t("reviews.voting.title")}:</p>
      <div className="reviews__voting-content">
        <button
          className="reviews__voting-btn reviews__voting-btn--plus"
          onClick={changeCarma(1)}
          aria-label="Add carma"
          disabled={isDisableButtons.like}
        ></button>
        <div className="reviews__voting-value">{review.karma}</div>
        <button
          className="reviews__voting-btn reviews__voting-btn--minus"
          onClick={changeCarma(-1)}
          aria-label="Remove carma"
          disabled={isDisableButtons.dislike}
        ></button>
      </div>
    </div>
  );
};
