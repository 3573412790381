import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { FormInput } from "../../../../../components/forms/New/FormInput/FormInput";
import Button from "../../../../../components/ui/New/Button/Button";
import { useLangUrlDefault } from "../../../../../hooks";
import { ApiService } from "../../../../../services";
import { getAllUser } from "../../../../../store/reducers/UserReducer/User.selectors";
import { ProfileChangeEmailSchema } from "../../../../../utils/validation";
import { FormBlock } from "../../components/FormBlock/FormBlock";
import { ModalConfirmCode } from "../ModalConfirmCode/ModalConfirmCode";
import { StyledForm } from "../PasswordBlock/PasswordBlock.styled";

export const EmailBlock = () => {
  const [queryLang] = useLangUrlDefault();

  const { t } = useTranslation();

  // **Redux state
  const { user } = useSelector(getAllUser);

  // const methods = useForm({ defaultValues: { email: user.email } });
  const methods = useForm({
    defaultValues: { newEmail: user?.email },
    resolver: yupResolver(
      ProfileChangeEmailSchema(t("forms", { returnObjects: true }))
    )
  });
  const { newEmail } = methods.watch();

  const [modalOpen, setModalOpen] = useState(false);
  const [tokenTime, setTokenTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = async (data) => {
    setLoading(true);
    try {
      const res = await ApiService.changeEmail({
        ...data,
        locale: queryLang
      });
      if (res.status === 200) {
        toast.success(t("dashboard.profile.confirmModal.successSend"));
        // 15 minutes in milliseconds - new Date().getTime() - new Date(tokenCreatTime).getTime()
        const newTokenTime =
          900000 -
          (new Date().getTime() - new Date(res.data?.createdDate).getTime());
        if (newTokenTime > 0) {
          setTokenTime(newTokenTime);
        }

        setModalOpen(true);
      }
      if (res?.response?.status === 400) {
        if (res.response?.data?.error === "PASSWORD_DOESNT_MATCHES") {
          methods.setError("password", {
            type: "manual",
            message: t("dashboard.profile.confirmModal.invalidPassword")
          });
        }
        if (res.response?.data?.error === "INVALID_EMAIL") {
          methods.setError("newEmail", {
            type: "manual",
            message: t("dashboard.profile.confirmModal.invalidEmail")
          });
        }
      }
      if (res?.response?.status === 409) {
        methods.setError("newEmail", {
          type: "manual",
          message: t("dashboard.profile.confirmModal.emailExist")
        });
      }
    } catch (error) {
      toast.error(t("notifications.serverError"));
    } finally {
      setLoading(false);
    }
  };
  const handlerCloseModal = () => {
    setModalOpen(false);
    setTokenTime(null);
  };

  const handleCancel = () => {
    methods.reset({ newEmail: user.email });
    methods.clearErrors();
  };

  useEffect(() => {
    if (methods && Object.keys(methods.formState.errors)?.length > 0) {
      methods.trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);
  return (
    <>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(handleSave)} noValidate>
          <FormBlock
            title={t("dashboard.profile.blockEmail.title")}
            subtitle={t("dashboard.profile.blockEmail.subtitle")}
          >
            <FormInput
              name="newEmail"
              label={t("forms.emailV2.label")}
              placeholder={t("forms.emailV2.placeholder")}
              defaultValue={user.email}
              type="email"
              showError
            />
            {newEmail.toLowerCase() !== user.email.toLowerCase() && (
              <FormInput
                name="password"
                label={t("forms.password.labelChangeEmail")}
                placeholder={t("forms.password.placeholderCurrent")}
                type="password"
                showError
              />
            )}
          </FormBlock>
          {newEmail.toLowerCase() !== user.email.toLowerCase() && (
            <FormBlock bodyType={"buttons"}>
              <Button type="submit" loading={loading}>
                {t("dashboard.profile.blockEmail.change")}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={handleCancel}
                disabled={loading}
              >
                {t("forms.buttons.cancel")}
              </Button>
            </FormBlock>
          )}
        </StyledForm>
      </FormProvider>
      <ModalConfirmCode
        open={modalOpen}
        close={handlerCloseModal}
        tokenTime={tokenTime}
      />
    </>
  );
};
