import { forwardRef, useEffect } from "react";

import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";

import "./Modal.scss";

export const Modal = forwardRef((props, ref) => {
  // **Props
  const {
    visible,
    animationDuration = 300,
    width = 80,
    cancelHandler,
    children
  } = props;

  const styles = {
    "--duration": `${animationDuration}ms`,
    "--width": `${width}rem`
  };

  const closeModal = () => {
    cancelHandler(false);
  };

  useEffect(() => {

      const originalStyle = window.getComputedStyle(document.body).overflow;
      const paddingOffset = `${
        window.innerWidth - document.body.offsetWidth
      }px`;

      if (visible) {
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = paddingOffset;
      }

      return () => {
        document.body.style.overflow = originalStyle;
        document.body.style.paddingRight = "";
      };

  }, [visible]);

  if (typeof window === "object") return (
    createPortal(
      <Transition
        in={visible}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            ref={ref}
            style={styles}
            className={`modal ${state}`}
            onClick={closeModal}
          >
            <div className="modal__wrapper">
              <div
                className="modal__content"
                onClick={(e) => e.stopPropagation()}
              >
                {children}
                <button
                  className="modal__close"
                  aria-label="Закрыть модальное окно"
                  onClick={closeModal}
                ></button>
              </div>
            </div>
          </div>
        )}
      </Transition>,
      document?.body
    )
  );

  return null;
});
