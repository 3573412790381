import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Pagination } from "../../../../../components/common";
import { Loader } from "../../../../../components/ui/Loader/Loader";
import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";
import { getAllBlog } from "../../../../../store/reducers/BlogReducer/Blog.selectors";
import { BlogItem } from "../BlogItem/BlogItem";

export const BlogTab = ({ emptyText, category = false }) => {
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { content } = useSelector(getAllBlog);

  // **Local state
  const [pageParams, setPageParams] = useState({ page: 0, size: 16 });

  // Dispatch
  const { getBlogContent } = useDispatchedActions();

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({ ...pageParams, page: page - 1 });
  };

  // Handling content
  useEffect(() => {
    if (
      content.data.params?.page === pageParams.page &&
      content.data.params?.category === category &&
      content.data.params?.languageCode === queryLang &&
      content.data.params?.size === pageParams.size
    ) {
      return;
    }

    const promise = getBlogContent({
      page: pageParams.page,
      size: pageParams.size,
      category,
      languageCode: queryLang
    });

    return () => promise?.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams, category, queryLang]);
  // console.log(content.isLoading);
  return (
    <div
      className={
        content.isLoading
          ? "blog__wrapper blog__wrapper--loading"
          : "blog__wrapper"
      }
    >
      {content.data.totalElements > 0 && (
        <>
          <ul className="blog__list">
            {content.data.content.map((item) => (
              <BlogItem key={item.id} item={item} />
            ))}
          </ul>
          <Pagination
            totalPages={content.data.totalPages}
            onPageChange={pageChangeHandler}
            currentPage={pageParams.page + 1}
            hideOnSinglePage
          />
        </>
      )}
      {!content.isLoading && content.data.totalElements === 0 && (
        <p className="blog__empty">{emptyText}</p>
      )}
      {content.isLoading && <Loader type="blur" />}
    </div>
  );
};
