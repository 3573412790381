export const setCookie = (name, value, options = {}) => {
  if (typeof document !== "undefined") {
    const updatedOptions = {
      path: "/",
      ...options
    };
    if (updatedOptions.expires instanceof Date) {
      updatedOptions.expires = updatedOptions.expires.toUTCString();
    }
    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
      value
    )}`;
    for (const optionKey in updatedOptions) {
      updatedCookie += `; ${optionKey}`;
      const optionValue = updatedOptions[optionKey];
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    }
    document.cookie = updatedCookie;
  }
};

export const getCookie = (name) => {
  if (typeof document !== "undefined") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts?.length === 2) return parts.pop().split(";").shift();
  } else {
    return null;
  }
};
