import styled from "@emotion/styled";

export const StyledProxyTabFilterBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  @media screen and (min-width: 993px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: nowrap;
  }
`;
