import styled from "@emotion/styled";

import FAQ from "../../../../components/common/New/FAQ/FAQ";
import { Main } from "../../../../components/common/New/Main/Main";

export const RestyledMainSection = styled(Main)`
  padding-bottom: 70px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 160px;
    min-height: 590px;
  }
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    min-height: 770px;
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      width: 478px;
      height: 170px;
      top: 93px;
      right: 40px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      width: 597px;
      height: 212px;
    }

    @media (min-width: 1350px) {
      right: -45px;
    }

    &::before {
      width: 153px;
      height: 153px;
      bottom: 50%;
    }
  }
`;

export const RestyledFAQ = styled(FAQ)`
  img {
    width: 329px;
  }
`;
