import { ArticleBody } from "./ArticleBody/ArticleBody";
import { Comments } from "./Comments/Comments";

export const Content = ({ captchaRef, setTokenCaptcha }) => (
  <div className="blog-inner__content">
    <ArticleBody />
    <Comments captchaRef={captchaRef} setTokenCaptcha={setTokenCaptcha} />
  </div>
);

