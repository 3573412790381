import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormBlock from "../../../components/FormBlock/FormBlock";

import { Advantages } from "./components/Advantages/Advatages";
import { ProxyTypeInfo } from "./components/ProxyTypeInfo/ProxyTypeInfo";
import { ProxyTypes } from "./components/ProxyTypes/ProxyTypes";

export const BlockType = ({ proxyForLang }) => {
  const { t } = useTranslation();

  const isProxyNotEmpty = proxyForLang && proxyForLang?.length > 0;
  const { watch } = useFormContext();

  const data = watch("proxyTypes");

  const infoFor = proxyForLang?.filter(({ type }) => data?.[type] === true);

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.type.title")}
      subtitle={t("dashboard.services.addEdit.blocks.type.subtitle")}
    >
      {isProxyNotEmpty && <ProxyTypes data={proxyForLang} />}

      <Advantages />

      {infoFor &&
        infoFor?.length > 0 &&
        infoFor.map((item) => <ProxyTypeInfo key={item?.type} data={item} />)}
    </FormBlock>
  );
};
