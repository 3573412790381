import styled from "@emotion/styled";

const variables = {
  color: {
    success: "#7FBC32",
    warning: "#EEBA58",
    error: "#FF5C5C"
  },
  background: {
    success: "#F0FBDE",
    warning: "#FDF7DB",
    error: "#FFE9E6"
  }
};

export const StyledPercent = styled.div`
  display: flex;
  padding: 8px 12px;
  border-radius: 8px;
  align-items: center;
  color: ${(props) => variables.color[props.color]};
  background-color: ${(props) => variables.background[props.color]};
`;

export const Number = styled.div`
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25;
`;
