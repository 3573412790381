import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../../hooks";

import { Icon, StyledLink } from "./Logo.styled";

export const Logo = ({ className, color = "white", onClick }) => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  return (
    <StyledLink
      color={color}
      to={`${hrefLang}/`}
      className={`${className ? className : ""}`}
      title={t("header.logo.linkTitle")}
      {...(onClick && { onClick })}
    >
      <Icon name="logo" />
    </StyledLink>
  );
};

Logo.propTypes = {
  className: PT.string,
  color: PT.oneOf(["black", "white"])
};

export default Logo;
