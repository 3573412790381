import { Navigate } from "react-router-dom";

import AddEditPromocode from "../../pages/New/Dashboard/AddEditPromocode/AddEditPromocode";
import AddEditService from "../../pages/New/Dashboard/AddEditService/AddEditService";
import Profile from "../../pages/New/Dashboard/Profile/Profile";
import Promocodes from "../../pages/New/Dashboard/Promocodes/Promocodes";
import Reviews from "../../pages/New/Dashboard/Reviews/Reviews";
import Services from "../../pages/New/Dashboard/Services/Services";

export const DashboardRoutes = (ssr) => [
  {
    path: "/:lang?/dashboard/profile",
    tag: "dashboard-profile",
    type: "dashboard",
    component: ssr ? Profile : () => import("../../pages/New/Dashboard/Profile/Profile"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/services",
    tag: "dashboard-services",
    type: "dashboard",
    component: ssr ? Services : () => import("../../pages/New/Dashboard/Services/Services"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/services/create",
    tag: "dashboard-services-create",
    type: "dashboard",
    component: ssr ? AddEditService : () =>
      import("../../pages/New/Dashboard/AddEditService/AddEditService"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/services/edit/:id",
    tag: "dashboard-services-edit",
    type: "dashboard",
    component: () =>
      import("../../pages/New/Dashboard/AddEditService/AddEditService"),
    element: AddEditService,
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/reviews",
    tag: "dashboard-reviews",
    type: "dashboard",
    component: ssr ? Reviews : () => import("../../pages/New/Dashboard/Reviews/Reviews"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/promocodes",
    tag: "dashboard-promocodes",
    type: "dashboard",
    component: ssr ? Promocodes : () => import("../../pages/New/Dashboard/Promocodes/Promocodes"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/promocodes/create",
    tag: "dashboard-promocodes-create",
    type: "dashboard",
    component: ssr ? AddEditPromocode : () =>
      import("../../pages/New/Dashboard/AddEditPromocode/AddEditPromocode"),
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/promocodes/edit/:id",
    tag: "dashboard-promocodes-edit",
    type: "dashboard",
    component: ssr ? AddEditPromocode : () =>
      import("../../pages/New/Dashboard/AddEditPromocode/AddEditPromocode"),
    element: AddEditPromocode,
    isAuthRequired: true
  },
  {
    path: "/:lang?/dashboard/*",
    element: <Navigate to="/dashboard/profile" replace={true} />
  }
];
