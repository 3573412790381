import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../../../hooks";

import { Translator } from "./Translator/Translator";

export const InfoPart = (props) => {
  // **Props
  const [queryLang] = useLangUrlDefault();
  const { review } = props;
  const { locale = null, translation = {} } = review;

  // **Redux state
  const { t } = useTranslation();

  const isTranslations = Object.keys(translation)?.length > 0;
  const isShowTranslator = locale && isTranslations && queryLang !== locale;

  // **Local state
  const [isShowOriginal, setIsShowOriginal] = useState(false);

  const getReviewData = (data, isAttribute, nameField) => {
    if (isAttribute) {
      return data?.["attributes"]?.[nameField];
    } else {
      return data?.[nameField];
    }
  };

  const getTranslateReviewData = (isAttribute, nameField) => {
    if (
      locale !== queryLang &&
      isTranslations &&
      translation?.[queryLang] &&
      !isShowOriginal
    ) {
      return getReviewData(translation[queryLang], isAttribute, nameField);
    } else {
      return getReviewData(review, isAttribute, nameField);
    }
  };

  const isReviewData = (isAttribute, nameField) =>
    Boolean(getTranslateReviewData(isAttribute, nameField));

  const showOriginalHandle = () => {
    setIsShowOriginal(!isShowOriginal);
  };

  return (
    <>
      {isShowTranslator && (
        <Translator
          isShowOriginal={isShowOriginal}
          showOriginalHandle={showOriginalHandle}
        />
      )}
      <ul className="reviews__info">
        {isReviewData(true, "advantages") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.advantages.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(true, "advantages")}
            </div>
          </li>
        )}
        {isReviewData(true, "disadvantages") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.disadvantages.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(true, "disadvantages")}
            </div>
          </li>
        )}
        {isReviewData(false, "reviewBody") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.review.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(false, "reviewBody")}
            </div>
          </li>
        )}
        {isReviewData(false, "commentBody") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.review.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(false, "commentBody")}
            </div>
          </li>
        )}
        {isReviewData(true, "usage") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.usage.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(true, "usage")}
            </div>
          </li>
        )}
        {isReviewData(true, "wishes") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.wishes.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(true, "wishes")}
            </div>
          </li>
        )}
        {isReviewData(false, "reason") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.reason.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(false, "reason")}
            </div>
          </li>
        )}
        {isReviewData(false, "complaintBody") && (
          <li className="reviews__info-item">
            <div className="reviews__info-left">
              {t("reviews.content.review.title")}:
            </div>
            <div className="reviews__info-right">
              – {getTranslateReviewData(false, "complaintBody")}
            </div>
          </li>
        )}
      </ul>
    </>
  );
};
