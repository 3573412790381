export const proxySiteInitialState = {
  site: {
    data: {
      id: "",
      name: "",
      affiliateProgram: false,
      clientIPAuth: false,
      clientLoginAuth: false,
      clientNoAuth: false,
      countries: [],
      freeTest: false,
      price: 0,
      individual: false,
      proxyType: [],
      minRentPeriod: 0,
      purposeUse: [],
      refunds: false,
      replacementPossibility: false
    },
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  statistic: {
    isStatisticSites: [],
    isLoading: false,
    isError: null
  },
  reviewsAmount: {
    data: null,
    isLoading: false,
    isError: null
  },
  rating: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  reviews: {
    data: {
      totalPages: 0,
      totalElements: 0,
      content: []
    },
    isError: null,
    isLoading: false,
    reviewPage: null,
    reviewId: null,
    fetchedParams: {
      sort: "creationDate",
      siteId: "",
      page: 0,
      size: 4
    },
    isDataLoaded: false
  },
  complaints: {
    data: {
      totalPages: 0,
      totalElements: 0,
      content: []
    },
    isError: null,
    isLoading: false
  },
  promocodes: {
    data: [],
    isLoading: false,
    isError: null
  },
  proxySites: {
    totalElements: 0,
    totalPages: 0,
    content: [],
    fetchParams: {},
    skip: 0,
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  proxySitesType: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  }
};
