import { useEffect, useRef, useState } from "react";

import Cropper from "cropperjs";
import { useTranslation } from "react-i18next";

import { useDebouncedFunction } from "../../../../utils/helpers";
import { Button } from "../../../ui/Button/Button";
import { Loader } from "../../../ui/Loader/Loader";
import { Modal } from "../../Modal/Modal";

import "./CropperImg.scss";

const ASPECT_RATIOS = [23 / 7, 23 / 13];
const DEBOUNCE_DELAY = 1000;

export const CropperImg = ({
  isModalOpened,
  defaultImage,
  fileObject,
  setCroppedFileObjects,
  onUpload,
  cancelModalHandler
}) => {
  const cropperImageRef = useRef(null);
  const cropperOutputImageRefs = useRef([]);

  const { t } = useTranslation();

  // **Local state
  const [isCropping, setIsCropping] = useState(false);
  const [mainCropper, setMainCropper] = useState({});
  const [index, setIndex] = useState(0);
  const [croppedFiles, setCroppedFiles] = useState([]);

  const cropperPreview = (index, cropper) => {
    if (index >= ASPECT_RATIOS?.length) {
      setIsCropping(false);
      setIndex(0);

      return;
    }

    const outputImage = cropperOutputImageRefs.current[index];

    if (outputImage) {
      outputImage.src = cropper.getCroppedCanvas().toDataURL("image/png");
      cropper.getCroppedCanvas().toBlob((blob) => {
        setCroppedFiles((prevState) => [
          ...prevState,
          new File([blob], fileObject.name, { type: blob.type })
        ]);
      });
      const i = index + 1;

      const newCropper = new Cropper(outputImage, {
        aspectRatio: ASPECT_RATIOS[i],
        viewMode: 3,
        autoCropArea: 1,
        ready() {
          cropperPreview(i, newCropper);
          newCropper.destroy();
        }
      });
    } else {
      return null;
    }
  };

  const cropperHandle = (index, mainCropper) => {
    setIsCropping(true);
    setCroppedFiles([]);
    cropperPreview(index, mainCropper);
  };

  const debouncedCropperHandle = useDebouncedFunction(
    cropperHandle,
    DEBOUNCE_DELAY
  );

  useEffect(() => {
    if (isModalOpened) {
      setIsCropping(true);
      setCroppedFiles([]);
      let previewReady = false;

      const image = cropperImageRef.current;

      setMainCropper(
        new Cropper(image, {
          aspectRatio: ASPECT_RATIOS[index],
          viewMode: 0,
          autoCropArea: 1,
          ready() {
            cropperPreview(index, this.cropper);
            previewReady = true;
          },
          crop() {
            if (!previewReady) {
              return;
            }

            debouncedCropperHandle(index, this.cropper);
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpened]);

  const saveCropHandle = () => {
    setCroppedFileObjects(croppedFiles);
    onUpload(croppedFiles);
    cancelModalHandler();
  };

  const getRatioPreviewNameClass = (ratio) => {
    let ratioClassName = "";

    switch (ratio) {
      case 23 / 7:
        ratioClassName = "cropper__preview-small";
        break;
      case 10 / 3:
        ratioClassName = "cropper__preview-large";
        break;
      case 23 / 13:
        ratioClassName = "cropper__preview-middle";
        break;
      default:
        ratioClassName = "cropper__preview-default";
    }

    return ratioClassName;
  };

  return (
    <Modal visible={isModalOpened} cancelHandler={cancelModalHandler}>
      <div className="cropper__wrapper">
        <div className="cropper__image">
          <img
            ref={cropperImageRef}
            src={
              fileObject && typeof fileObject !== "string"
                ? URL?.createObjectURL(fileObject)
                : defaultImage
            }
            alt="Cropper image"
            style={{
              display: "block",
              maxWidth: "100%"
            }}
          />
        </div>
        <hr />
        <div className="cropper__previews">
          {ASPECT_RATIOS.map((it, i) => (
            <div
              key={`cropper-preview-item-${i}`}
              className={`cropper__preview-item ${
                !isCropping && "cropper__preview-item--show"
              }`}
            >
              <img
                ref={(el) => {
                  cropperOutputImageRefs.current[i] = el;
                  return null;
                }}
                className={getRatioPreviewNameClass(it)}
                alt={`Cropper output image ${i}`}
              />
            </div>
          ))}
          {isCropping && <Loader type="absolute" scale={0.8} />}
        </div>
        <div className="cropper__buttons">
          <Button type="black" onClick={() => mainCropper.reset()}>
            {t("proxy.logotype.modal.btn.reset")}
          </Button>
          <Button type="solid" onClick={saveCropHandle}>
            {t("proxy.logotype.modal.btn.save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
