import { useEffect, useState } from "react";

import { webrtcCheck } from "@forproxyband/react-tools";
import { useTranslation } from "react-i18next";

import { Loader } from "../../../../../components/ui/Loader/Loader";
import Tooltip from "../../../../../components/ui/New/Tooltip/Tooltip";
import { useUserIp } from "../../../../../hooks/useUserIp";
import { useWebRtcStatus } from "../../../../../hooks/useWebRtcStatus";

import { ResultsTable } from "./ResultRable/ResultTable";
import {
  ImageWrapper,
  Info,
  InfoMessage,
  Inner,
  ItemLabel,
  ItemValue,
  ListMobile,
  ListMobileItem,
  LoaderWrapper,
  RestyledButton,
  RestyledContainer,
  RestyledTitle,
  Results,
  Status,
  TitleWrapper
} from "./WebRTCLeakTest.styled";

export const WebRTCLeakTest = () => {
  const [ips, setIps] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const userIp = useUserIp();

  const webRtcStatus = useWebRtcStatus(ips, userIp?.ipAddress, setIps);

  useEffect(() => {
    if (userIp?.ipAddress) {
      webrtcCheck.check(setIps);
    }
    setIsLoading(false);
  }, [userIp?.ipAddress]);

  return (
    <RestyledContainer>
      <Inner>
        <Info>
          <ImageWrapper>
            <img
              src="img/webRTC-leak-test/webRTC-leak-test-image.svg"
              alt={t("dnsLeak.main.title")}
            />
          </ImageWrapper>
          <RestyledButton
            type="button"
            // eslint-disable-next-line no-empty-function
            onClick={() => {}}
            fullWidth
          >
            {t("webRTCLeakTest.webRTCTest.buttonLabel")}
          </RestyledButton>
        </Info>
        <Results>
          {userIp && !isLoading ? (
            <>
              <TitleWrapper>
                <RestyledTitle tag="h2">
                  {t("webRTCLeakTest.webRTCTest.title")}
                </RestyledTitle>
                <InfoMessage webRtcStatus={webRtcStatus}>
                  {webRtcStatus
                    ? t("webRTCLeakTest.webRTCTest.leakMessage")
                    : t("webRTCLeakTest.webRTCTest.noLeakMessage")}
                </InfoMessage>
              </TitleWrapper>
              <ResultsTable data={[userIp]} status={webRtcStatus} />
              <ListMobile>
                <ListMobileItem>
                  <ItemLabel>{t("webRTCLeakTest.table.ip")}</ItemLabel>
                  <ItemValue>{userIp.ipAddress}</ItemValue>
                </ListMobileItem>
                <ListMobileItem>
                  <ItemLabel>{t("webRTCLeakTest.table.type")}</ItemLabel>
                  <ItemValue>{userIp.type || "Public IPv4"}</ItemValue>
                </ListMobileItem>
                <ListMobileItem>
                  <ItemLabel>{t("webRTCLeakTest.table.status")}</ItemLabel>
                  <ItemValue>
                    <Status webRtcStatus={webRtcStatus}>
                      {webRtcStatus
                        ? t("webRTCLeakTest.table.leak")
                        : t("webRTCLeakTest.table.noLeak")}
                    </Status>

                    <Tooltip
                      body={
                        webRtcStatus
                          ? t("webRTCLeakTest.table.leakTooltip")
                          : t("webRTCLeakTest.table.noLeakTooltip")
                      }
                      isOpen={true}
                      place="top"
                    >
                      <img src="img/icons/information.svg" alt="" />
                    </Tooltip>
                  </ItemValue>
                </ListMobileItem>
              </ListMobile>
              <RestyledButton
                isMobile
                type="button"
                // eslint-disable-next-line no-empty-function
                onClick={() => {}}
                disabled={isLoading}
                fullWidth
              >
                {t("webRTCLeakTest.webRTCTest.buttonLabel")}
              </RestyledButton>
            </>
          ) : (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
        </Results>
      </Inner>
    </RestyledContainer>
  );
};
