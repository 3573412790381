import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";

import "./Select.scss";

export const Select = ({
  name,
  rules,
  options,
  margin,
  label,
  layout,
  style,
  showError,
  ...rest
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const defineClassname = () => {
    let className = "form-select__item";

    switch (layout) {
      case "vertical": {
        className += " form-select__item--layout-vertical";
        break;
      }
      case "horizontal": {
        className += " form-select__item--layout-horizontal";
        break;
      }
      default: {
        break;
      }
    }

    switch (margin) {
      case "mb-large": {
        className += " form-select__item--margin-bottom-large";
        break;
      }
      default: {
        break;
      }
    }

    if (errors[name]) {
      className += " form-select__item--error";
    }
    return className;
  };

  return (
    <div className={defineClassname()} style={style}>
      <label>
        {label && <span className="form-select__label">{label}</span>}
        <div className="form-select__select">
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange } }) => (
              <ReactSelect
                classNamePrefix="select"
                onChange={(option) => onChange(option.value)}
                options={options}
                {...rest}
              />
            )}
          />
        </div>
      </label>
      {showError && errors[name]?.message && (
        <div className="form-select__error">{errors[name]?.message}</div>
      )}
    </div>
  );
};
