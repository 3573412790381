import { createSlice } from "@reduxjs/toolkit";

import { contentInitialState } from "./FAQ.initialState";
import { setFaqContentReducer } from "./FAQ.reducer";
import { getFaqContent, setFaqContent } from "./FAQ.thunks";

const FAQSlice = createSlice({
  name: "faq",
  initialState: contentInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setFaqContent, setFaqContentReducer)
      .addCase(getFaqContent.pending, (state) => {
        state.isError = null;
        state.isLoading = true;
      })
      .addCase(getFaqContent.fulfilled, (state, action) => {
        state.isError = null;
        state.data = {
          ...state.data,
          [action.payload.languageCode]: {
            ...state.data[action.payload.languageCode],
            [action.payload.pageTag]: action.payload.data
          }
        };
        state.isDataLoaded = true;
        state.isLoading = false;
      })
      .addCase(getFaqContent.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = false;
      })
});

export const FAQReducerActions = {
  setFaqContent,
  getFaqContent
};
export default FAQSlice.reducer;
