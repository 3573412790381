import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SectionHead } from "../../../../../../components/common/New/SectionHead/SectionHead";
import { useSeoBlock } from "../../../../../../hooks";

export const ProxyContentTop = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { data } = useSeoBlock(["mainPageProxyTable"]);
  const activeTab = searchParams.get("fpt") || "IPv4";
  return (
    <SectionHead
      title={data?.mainPageProxyTable?.title?.content?.replace(
        "{{proxyType}}",
        t(`proxyTypesGen.${activeTab}`)
      )}
      description={data?.mainPageProxyTable?.description?.content}
    />
  );
};
