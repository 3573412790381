import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// import { useMetaData } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";

import Form from "./Form";

export const Feedback = () => {
  // **Redux state
  const { t } = useTranslation();

  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  // // **Local state
  // const metaData = useMetaData();

  return (
    <section className="feedback">
      <div className="feedback__top">
        <div className="feedback__top-title">
          <h1>{current?.data?.h1}</h1>
        </div>
      </div>
      <div className="feedback__content">
        {t("feedbackPage.text", { returnObjects: true }).map((text) => (
          <p key={text}>{text}</p>
        ))}
      </div>
      <Form />
    </section>
  );
};
