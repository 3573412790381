import { time } from "./time.helper";

export const getRentPeriod = (period, lang, t) => {
  if (!period) {
    return;
  }

  return {
    id: period.id,
    label: time(period.periodName, period.periodValue, lang, t),
    value: period.id,
    time: period.periodMilliseconds
  };
};
