export const parseSeoData = (field) => {
  if (!field) return null;
  if (field.type === "JSON") {
    try {
      return JSON.parse(field.content);
    } catch (e) {
      return null;
    }
  } else {
    return field.content;
  }
};
