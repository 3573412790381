import PT from "prop-types";

import Icon from "../Icon/Icon";

import { StyledStar, StyledStars } from "./Stars.styled";

export const Stars = ({ className, value = 0, size = 16 }) => (
  <StyledStars className={className}>
    {Array(5)
      .fill(0)
      .map((_, index) => {
        const number = Number(value);
        const current = number - index;
        let fill = "none";
        if (current >= 0.5) fill = "half";
        if (current >= 1) fill = "full";
        return (
          <StyledStar
            key={`${index}-${value}`}
            value={value}
            size={size}
            className={fill === "none" ? "none_color" : ""}
          >
            <Icon name={fill === "half" ? "starHalf" : "starFill"} />
          </StyledStar>
        );
      })}
  </StyledStars>
);

Stars.propTypes = {
  className: PT.string,
  value: PT.number,
  size: PT.number,
  padding: PT.string
};

export default Stars;
