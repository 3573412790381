import { Typography } from "../../../../components/ui/Typography/Typography";
import { AuthTabs } from "../AuthTabs/AuthTabs";

export const Authorization = ({
  isTabsShown = false,
  title = "",
  children
}) => (
  <>
    {isTabsShown && <AuthTabs />}
    <div className="auth__inner">
      <Typography
        Tag="h1"
        weight="semibold"
        position="center"
        size="very-large"
      >
        {title}
      </Typography>
      {children}
    </div>
  </>
);
