import { useId } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { components } from "react-select";

import Icon from "../Icon/Icon";

import { ReactSelectStyled } from "./Select.styled";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon name="arrowDown" size="lg" />
  </components.DropdownIndicator>
);

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Icon name="closeModal" />
  </components.MultiValueRemove>
);

const Input = (props) => {
  if (props.selectProps.propIsSearchable === false) {
    return <components.Input {...props} readOnly />;
  }
  return <components.Input {...props} />;
};

export const Select = ({
  options = [],
  id,
  value,
  name,
  defaultValue,
  onChange,
  disabled = false,
  className,
  placeholder,
  multiple = false,
  color = "light",
  noData,
  isError,
  isSearchable = false,
  returnObject = false,
  size = "md"
}) => {
  const instanceId = useId();
  const { t } = useTranslation();

  const currentValue = (data) => {
    if (!data) return multiple ? [] : null;
    const dataIsArray = Array.isArray(data);
    if (dataIsArray && !data?.length) return multiple ? [] : null;
    if (!multiple) {
      const currentData = dataIsArray ? data?.[0] : data;
      const currentValue =
        typeof currentData?.value !== "undefined"
          ? currentData?.value
          : currentData;
      return options.find((option) => option.value === currentValue);
    }
    const currentData = dataIsArray ? data : [data];
    const currentValue =
      typeof currentData?.value !== "undefined"
        ? currentData
        : currentData.map((item) => item.value);
    return currentValue.map((item) =>
      options.find((option) => option.value === item)
    );
  };

  return (
    <ReactSelectStyled
      className={className}
      classNamePrefix="form-select"
      id={id}
      instanceId={instanceId}
      name={name}
      size={size}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        MultiValueRemove,
        Input
      }}
      color={color}
      isError={isError}
      placeholder={placeholder}
      isDisabled={disabled}
      menuPlacement="auto"
      noOptionsMessage={() => (noData ? noData : t("forms.noOptions"))}
      propIsSearchable={isSearchable}
      defaultValue={currentValue(defaultValue)}
      value={currentValue(value)}
      blurInputOnSelect
      options={options}
      onChange={(option) => {
        if (!option) return;

        if (returnObject) {
          onChange(option);
        } else if (!multiple) {
          onChange(option.value);
        } else {
          onChange(option.map((item) => item.value));
        }
      }}
      isClearable={false}
      isMulti={multiple}
    />
  );
};

Select.propTypes = {
  options: PT.arrayOf(
    PT.shape({
      value: PT.oneOfType([PT.string, PT.number]).isRequired,
      label: PT.node.isRequired
    })
  ).isRequired,
  onChange: PT.func.isRequired,
  className: PT.string,
  disabled: PT.bool,
  id: PT.string,
  name: PT.string,
  value: PT.oneOfType([PT.string, PT.array, PT.number, PT.object]),
  defaultValue: PT.oneOfType([PT.string, PT.array, PT.number, PT.object]),
  placeholder: PT.string,
  multiple: PT.bool,
  color: PT.oneOf(["light", "dark", "menu"]),
  size: PT.oneOf(["sm", "md", "lg"]),
  noData: PT.node,
  isError: PT.bool,
  isSearchable: PT.bool,
  returnObject: PT.bool
};

export default Select;
