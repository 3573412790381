import { useEffect, useRef, useState } from "react";

import Icon from "../../../../../../../../components/ui/New/Icon/Icon";

import {
  StyledDots,
  StyledInfoMultipleEnd,
  StyledRenderValueBox,
  StyledValueButton
} from "./RenderValue.styled";

const RendetItem = ({ list, index, total, onClerClick }) => {
  const ref = useRef();
  const [end, setEnd] = useState(0);
  const [endParent, setEndParent] = useState(0);

  const handlerRemove = (e) => {
    const item = list[index];
    onClerClick?.(item);
    e.stopPropagation();
  };

  useEffect(() => {
    if (ref?.current) {
      setEnd(ref.current.getBoundingClientRect().right);
      setEndParent(ref.current.parentElement.getBoundingClientRect().right);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current?.offsetWidth]);

  if (index >= total) {
    return null;
  }
  if (end !== 0 && endParent !== 0 && end > endParent - 80) {
    return (
      <>
        <StyledDots>{"..."}</StyledDots>
        <StyledInfoMultipleEnd>{total - index}</StyledInfoMultipleEnd>
      </>
    );
  }

  return (
    <>
      <StyledValueButton ref={ref} onClick={handlerRemove} type="button">
        {list[index]?.label}
        <Icon name="closeModal" />
      </StyledValueButton>
      <RendetItem
        list={list}
        index={index + 1}
        total={total}
        onClerClick={onClerClick}
      />
    </>
  );
};

export const RenderValue = ({ selected, onClerClick }) => (
  <StyledRenderValueBox>
    {selected?.length > 0 && (
      <RendetItem
        list={selected}
        index={0}
        total={selected?.length}
        onClerClick={onClerClick}
      />
    )}
  </StyledRenderValueBox>
);
