import { createSlice } from "@reduxjs/toolkit";

import { blogInnerInitialState } from "./BlogInner.initialState";
import {
  setBlogCommentsReducer,
  setBlogContentByAliasReducer,
  setBlogSidebarContentReducer,
  setErrorBlogInnerContentReducer
} from "./BlogInner.reducer";
import {
  getBlogComments,
  getBlogContentByAlias,
  getBlogSidebarContent,
  setBlogComments,
  setBlogContentByAlias,
  setBlogSidebarContent,
  setErrorBlogInnerContent
} from "./BlogInner.thunks";

const BlogInnerSlice = createSlice({
  name: "bloginner",
  initialState: blogInnerInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setErrorBlogInnerContent, setErrorBlogInnerContentReducer)
      .addCase(setBlogSidebarContent, setBlogSidebarContentReducer)
      .addCase(setBlogContentByAlias, setBlogContentByAliasReducer)
      .addCase(setBlogComments, setBlogCommentsReducer)

      // getBlogContentByAlias
      .addCase(getBlogContentByAlias.pending, (state) => {
        state.content.isError = null;
        state.content.isLoading = true;
      })
      .addCase(getBlogContentByAlias.fulfilled, (state, action) => {
        state.content.isError = null;
        state.content.data = action.payload;
        state.content.isLoading = false;
      })
      .addCase(getBlogContentByAlias.rejected, (state, action) => {
        state.content.isError = action.payload;
        state.content.isLoading = false;
      })

      // getBlogComments
      .addCase(getBlogComments.pending, (state) => {
        state.comments.isError = null;
        state.comments.isLoading = true;
      })
      .addCase(getBlogComments.fulfilled, (state, action) => {
        state.comments.isError = null;
        state.comments.data = action.payload;
        state.comments.isLoading = false;
      })
      .addCase(getBlogComments.rejected, (state, action) => {
        state.comments.isError = action.payload;
        state.comments.isLoading = false;
      })

      // getBlogSidebarContent
      .addCase(getBlogSidebarContent.pending, (state) => {
        state.sidebar.isError = null;
        state.sidebar.isLoading = true;
      })
      .addCase(getBlogSidebarContent.fulfilled, (state, action) => {
        state.sidebar.isError = null;
        state.sidebar.data = action.payload;
        state.sidebar.isDataLoaded = true;
        state.sidebar.isLoading = false;
      })
      .addCase(getBlogSidebarContent.rejected, (state, action) => {
        state.sidebar.isError = action.payload;
        state.sidebar.isLoading = false;
      })
});

export const BlogInnerReducerActions = {
  setErrorBlogInnerContent,
  setBlogSidebarContent,
  setBlogContentByAlias,
  setBlogComments,
  getBlogContentByAlias,
  getBlogComments,
  getBlogSidebarContent
};
export default BlogInnerSlice.reducer;
