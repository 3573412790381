import styled from "@emotion/styled";

export const StyledExpnadButton = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  margin: 0 4px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M15 7.5L10 12.5L5 7.5" stroke="%232A2A2D" stroke-width="1.5"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;

  transform: rotate(0deg);
  transition: transform 0.3s ease;

  &.open {
    transform: rotate(180deg);
    tarnsition: transform 0.3s ease;
  }
`;
