import styled from "@emotion/styled";

export const StyledSidebarOverlay = styled.div`
  display: ${(p) => (p.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.colors["overlay-color"]};
  z-index: 99;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none !important;
  }
`;

export const StyledSidebar = styled.aside`
  flex-shrink: 0;
  display: flex;
  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    position: fixed;
    width: 300px;
    top: 0;
    right: ${(p) => (p.isOpen ? "0" : "-300px")};
    z-index: 180;
    overflow: auto;
    height: 100%;
    transition: right ${(p) => p.theme.transitions.default};
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 288px;
    min-height: 480px;
  }
`;

export const StyledSidebarCardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.white};
  padding: 24px 12px !important;
  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    border-radius: 12px 0px 0px 12px !important;
  }
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    box-shadow: ${(p) => p.theme.shadows.card};
    width: 100%;
  }
`;
