import DatePicker from "react-datepicker";
import { Controller, useFormContext } from "react-hook-form";

import "./DateRange.scss";

export const DateRange = ({
  startRangeName,
  endRangeName,
  margin,
  label,
  layout,
  showError
}) => {
  const {
    control,
    getValues,
    formState: { errors },
    watch
  } = useFormContext();

  const defineClassname = () => {
    let className = "form-range__item";

    switch (layout) {
      case "vertical": {
        className += " form-range__item--layout-vertical";
        break;
      }
      case "horizontal": {
        className += " form-range__item--layout-horizontal";
        break;
      }
      default: {
        break;
      }
    }

    switch (margin) {
      case "mb-large": {
        className += " form-range__item--margin-bottom-large";
        break;
      }
      default: {
        break;
      }
    }

    if (errors[startRangeName] || errors[endRangeName]) {
      className += " form-range__item--error";
    }
    return className;
  };

  return (
    <div className={defineClassname()}>
      <label>
        {label && <span className="form-range__label">{label}</span>}
        <div className="form-range__date">
          <Controller
            control={control}
            name={startRangeName}
            render={({ field }) => (
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selectsStart
                minDate={new Date()}
                startDate={field.value}
                endDate={getValues(endRangeName)}
                onChange={(date) => field.onChange(date)}
                selected={field.value}
              />
            )}
          />
          <Controller
            control={control}
            name={endRangeName}
            render={({ field }) => (
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selectsEnd
                startDate={getValues(startRangeName)}
                endDate={field.value}
                minDate={watch(startRangeName)}
                onChange={(date) => field.onChange(date)}
                selected={field.value}
              />
            )}
          />
        </div>
      </label>
      {showError && errors[startRangeName]?.message && (
        <div className="form-range__error">{errors[endRangeName]?.message}</div>
      )}
      {showError && errors[endRangeName]?.message && (
        <div className="form-range__error">{errors[endRangeName]?.message}</div>
      )}
    </div>
  );
};
