export const getProxyTypesForSelect = (proxyTypes) => {
  if (!proxyTypes) {
    return;
  }

  return proxyTypes.map(({ type, name }) => ({
    value: type,
    label: name
  }));
};
