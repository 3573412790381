import { useRef, useState } from "react";

import i18n from "i18next";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useDispatchedActions,
  useLangUrlDefault,
  useMatchedRoute,
  useOnClickOutside
} from "../../../../hooks";
import { getAllBlogInner } from "../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllTranslation } from "../../../../store/reducers/TranslationReducer/Translation.selectors";
import { langs } from "../../../../utils/constants/langs";
import { setCookie } from "../../../../utils/helpers/cookie.helpers";

import {
  ArrowIcon,
  Button,
  Current,
  Item,
  LanguagesContainer,
  LanguagesInner,
  List,
  Title,
  TranslateIcon
} from "./Languages.styled";

const BLOG_INNER_TAG = "bloginner";

const Languages = ({
  title,
  variant = "dark",
  className,
  listClassName,
  bgHoverColor = "none"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // **Dispatch
  const { setCurrentLang } = useDispatchedActions();
  const dropdownRef = useRef();

  const [queryLang] = useLangUrlDefault();
  const location = useLocation();
  const navigate = useNavigate();

  // **Redux state
  const { languages } = useSelector(getAllTranslation);
  const { t } = useTranslation();
  const route = useMatchedRoute();
  const { content: BlogInner } = useSelector(getAllBlogInner);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    setCookie("lang", newLang);
    const pathnameSplit = location.pathname.split("/");
    const isNotLangUrl = pathnameSplit[1]?.length > 2;
    const rootEn = newLang === "en" ? "" : `/${newLang}`;

    setCurrentLang(newLang);

    if (route?.tag === BLOG_INNER_TAG) {
      const { langLinks } = BlogInner.data;
      const isLangForBlog = langLinks.hasOwnProperty(newLang);

      if (isLangForBlog) {
        navigate(`${newLang}/blog/${langLinks[newLang]}/`);
      } else {
        toast.error(t("notifications.blogInner.notFoundBlogPost"));
        navigate(`${rootEn}/blog/?tab=all`, { replace: true });
      }

      return;
    }

    navigate({
      pathname: `${rootEn}/${location.pathname.slice(isNotLangUrl ? 1 : 4)}`,
      hash: location.hash,
      search: location.search,
      state: location.state
    });
  };

  const normalizeLanguageCode = () => {
    const languageCode = i18n.language;
    const parts = languageCode.split("-");
    return parts[0];
  };

  const handleClick = (language) => {
    changeLanguage(language);
    setIsOpen(false);
  };

  return (
    <LanguagesContainer
      ref={dropdownRef}
      onClick={() => setIsOpen(!isOpen)}
      variant={variant}
      className={className}
      bgHoverColor={bgHoverColor}
    >
      {title && <Title>{title}</Title>}
      <LanguagesInner variant={variant}>
        <TranslateIcon name="translate" size="lg" />
        <Current>{normalizeLanguageCode()}</Current>
        <ArrowIcon name="arrowDown" isOpen={isOpen} />
        <List
          variant={variant}
          isOpen={isOpen}
          className={`${listClassName} languages-list`}
        >
          {languages.map((language) => (
            <Item
              variant={variant}
              active={queryLang === language.value}
              key={language.value}
            >
              <Button onClick={() => handleClick(language.value)} type="button">
                {langs[language.value]}
              </Button>
            </Item>
          ))}
        </List>
      </LanguagesInner>
    </LanguagesContainer>
  );
};

Languages.propTypes = {
  listClassName: PT.string,
  title: PT.string,
  className: PT.string,
  variant: PT.oneOf(["light", "dark"]),
  bgHoverColor: PT.oneOf(["white", "none"])
};

export default Languages;
