/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";

export const StyledBurgerWrapper = styled.div`
  width: 36px;
  height: 36px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  transition: background-color 0.5s ease;
  background-color: ${(props) =>
    props.active && (props.variant === "light" ? "white" : "#ffffff14")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 8px;
    margin-right: 2.4rem;
    width: 40px;
    height: 40px;
  }

  &:hover {
    background-color: ${(props) =>
      props.variant === "light" ? "white" : "#ffffff14"};
    transition: background-color 0.5s ease;
  }

  &:hover {
    button::before,
    button::after,
    span {
      background-color: ${(props) => props.theme.colors["main-500"]};
      transition: background-color 0.5s ease;
    }
  }
`;

export const StyledBurger = styled.button`
  position: relative;
  z-index: 10;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;

  span,
  &::after,
  &::before {
    background-color: ${(props) =>
      props.active
        ? props.theme.colors["main-500"]
        : props.variant === "dark"
        ? "white"
        : props.theme.colors["neutral-800"]};
    transition: background-color 0.5s ease;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 2px;
    width: 21px;
    height: 2px;
    transition: background-color 0.5s ease;
  }

  &::before {
    top: 5px;
  }

  &::after {
    bottom: 5px;
  }
`;

export const StyledSpan = styled.span`
  content: "";
  position: absolute;
  display: block;
  left: 2px;
  width: 15px;
  height: 1.5px;
  transition: background-color 0.5s ease;
  top: 50%;
  transform: translateY(-50%) scale(1);
`;
