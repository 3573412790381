import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Loader } from "../../../../../../components/ui/Loader/Loader";
import { useLangUrlDefault } from "../../../../../../hooks";
import { getAllPromocodes } from "../../../../../../store/reducers/PromocodesReducer/Promocodes.selectors";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

import { PromocodeItem } from "./PromocodeItem/PromocodeItem";

export const Promocodes = () => {
  // **Props
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { promocodes } = useSelector(getAllProxySite);
  const { alternatePromocodes } = useSelector(getAllPromocodes);

  return (
    <div
      className={
        promocodes.isLoading
          ? "proxy-site__promocodes proxy-site__promocodes--loading"
          : "proxy-site__promocodes"
      }
    >
      {promocodes.data?.length > 0 && (
        <ul className="proxy-site__promocodes-list">
          {promocodes.data?.map((promocode) => {
            const { showIn } = promocode;
            const showInKeys = Object.keys(showIn);

            if (showInKeys?.length > 0) {
              if (showIn[queryLang]) {
                return (
                  <PromocodeItem key={promocode.id} promocode={promocode} />
                );
              } else {
                return;
              }
            }

            return <PromocodeItem key={promocode.id} promocode={promocode} />;
          })}
        </ul>
      )}
      {!promocodes.isLoading &&
        promocodes.data?.length === 0 &&
        alternatePromocodes.data?.length > 0 && (
          <ul className="proxy-site__promocodes-list">
            {alternatePromocodes.data?.map((promocode) => (
              <PromocodeItem key={promocode.id} promocode={promocode} />
            ))}
          </ul>
        )}
      {!promocodes.isLoading &&
        promocodes.data?.length === 0 &&
        alternatePromocodes.data?.length === 0 && (
          <p>{t("proxySitePage.content.promocodes.noData")}</p>
        )}
      {promocodes.isLoading && <Loader type="blur" />}
    </div>
  );
};
