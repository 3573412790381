export const Tab = ({ tab, currentTab, children, link, size, ...rest }) => {
  const defineClassname = () => {
    let className = "tabs__item";

    if (tab === currentTab) {
      className += " tabs__item--active";
    }

    switch (size) {
      case "large": {
        className += " tabs__item--large";
        break;
      }
      case "small": {
        className += " tabs__item--small";
        break;
      }
      default: {
        className += " button__size--default";
        break;
      }
    }

    return className;
  };

  return link ? (
    <a href={link} className={defineClassname()} {...rest}>
      {children}
    </a>
  ) : (
    <button className={defineClassname()} {...rest}>
      {children}
    </button>
  );
};
