import { Tab } from "../Tab/Tab";

const TabsWraper = ({
  tabs,
  currentTab,
  generateLink,
  reviewsAmount,
  onTabClick
}) => (
  <>
    {tabs?.map(
      (tab) =>
        tab.name !== "news" && (
          <Tab
            key={tab.name}
            tab={tab.name}
            currentTab={currentTab}
            link={generateLink(tab.name)}
            onClick={(evt) => {
              evt.preventDefault();
              onTabClick(tab.name);
            }}
          >
            {tab.name === "reviews" && reviewsAmount ? (
              <span data-reviews={reviewsAmount}>{tab.title}</span>
            ) : (
              tab.title
            )}
          </Tab>
        )
    )}
  </>
);

export default TabsWraper;
