import {
  animationAnonymityChecker,
  animationAnonymityInfo
} from "../../../../../assets/animations";
import { Rive } from "../../../../../components/ui/New/Rive/Rive";
import { useSeoBlock } from "../../../../../hooks";

import {
  AnimationWrapper,
  ContentWrapper,
  Description,
  MobileImage,
  MobilePicture,
  MyAnonymitySection,
  RestyledContainer,
  RestyledTitle
} from "./MyAnonymityInfo.styled";

export const MyAnonymityInfo = () => {
  const { data } = useSeoBlock([
    "toolsAnonymityWhatIsIt",
    "toolsAnonymityWhatDoesIt"
  ]);
  return (
    <>
      {data?.toolsAnonymityWhatIsIt?.showOnFront && (
        <MyAnonymitySection variant="light">
          <RestyledContainer variant="light">
            <AnimationWrapper variant="light">
              <Rive
                src={animationAnonymityInfo}
                autoPlay
                top={-78}
                left={-80}
                bottom={-78}
                right={-80}
              />
            </AnimationWrapper>
            <MobilePicture>
              <source srcSet="img/my-anonymity/my-anonymity-info-image-mobile.svg" />
              <MobileImage
                src="img/my-anonymity/my-anonymity-info-image-mobile.svg"
                alt={data?.toolsAnonymityWhatIsIt?.title?.content}
              />
            </MobilePicture>
            <ContentWrapper variant="light">
              <RestyledTitle tag="h2">
                {data?.toolsAnonymityWhatIsIt?.title?.content}
              </RestyledTitle>
              <Description>
                {data?.toolsAnonymityWhatIsIt?.description?.content}
              </Description>
            </ContentWrapper>
          </RestyledContainer>
        </MyAnonymitySection>
      )}
      {data?.toolsAnonymityWhatDoesIt?.showOnFront && (
        <MyAnonymitySection>
          <RestyledContainer>
            <ContentWrapper>
              <RestyledTitle tag="h2">
                {data?.toolsAnonymityWhatDoesIt?.title?.content}
              </RestyledTitle>
              <Description>
                {data?.toolsAnonymityWhatDoesIt?.description?.content}
              </Description>
            </ContentWrapper>
            <AnimationWrapper>
              <Rive
                src={animationAnonymityChecker}
                autoPlay
                top={-75}
                right={-132}
                bottom={-75}
                left={-83}
              />
            </AnimationWrapper>
            <MobilePicture>
              <source srcSet="img/my-anonymity/my-anonymity-info-image-mobile1.svg" />
              <MobileImage
                src="img/my-anonymity/my-anonymity-info-image-mobile1.svg"
                alt={data?.toolsAnonymityWhatDoesIt?.title?.content}
              />
            </MobilePicture>
          </RestyledContainer>
        </MyAnonymitySection>
      )}
    </>
  );
};
