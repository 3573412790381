import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Input } from "../../../../../components/forms/Input/Input";
import { Textarea } from "../../../../../components/forms/Textarea/Textarea";
import { Button } from "../../../../../components/ui/Button/Button";
import { Loader } from "../../../../../components/ui/Loader/Loader";
import { ApiService } from "../../../../../services";
import { FeedbackSchema } from "../../../../../utils/validation";

const Form = () => {
  // **Redux state
  const { t } = useTranslation();
  // **Local state
  const [isLoading, setIsLoading] = useState(false);

  // Form
  const methods = useForm({
    resolver: yupResolver(FeedbackSchema(t("forms", { returnObjects: true })))
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const response = await ApiService.createFeedback(data);

      if (response && response.status !== 200) {
        throw response;
      }

      // Reset form
      methods.reset();

      // Show success message
      toast.success(t("notifications.feedback"));
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="feedback__form">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="name"
            layout="horizontal"
            type="text"
            showError
            margin="mb-large"
            label={t("forms.name.label")}
          />
          <Input
            name="email"
            layout="horizontal"
            type="email"
            showError
            margin="mb-large"
            label={t("forms.email.label")}
          />
          <Textarea
            name="message"
            layout="horizontal"
            showError
            margin="mb-large"
            label={t("forms.message.label")}
          />
          <Button type="solid">
            {!isLoading ? (
              t("feedbackPage.form.btn")
            ) : (
              <Loader
                type="absolute"
                scale={0.4}
                color="var(--clr-default-900)"
              />
            )}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default Form;
