import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Pagination, Reviews } from "../../../../../../components/common";
import { Loader } from "../../../../../../components/ui/Loader/Loader";
import { useDispatchedActions } from "../../../../../../hooks";
import { getAllBlogInner } from "../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { Form } from "./Form/Form";

export const Comments = ({ captchaRef, setTokenCaptcha }) => {
  // **Redux state
  const { comments, content } = useSelector(getAllBlogInner);
  const { t } = useTranslation();
  const { id } = content?.data;

  // **Dispatch
  const { getBlogComments } = useDispatchedActions();

  // **Local state
  const [pageParams, setPageParams] = useState({
    currentPage: 1,
    size: 10
  });
  const [saveParams, setSaveParams] = useState({
    id,
    currentPage: 1,
    size: 10
  });

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({
      ...pageParams,
      currentPage: page
    });
  };

  const fetchBlogReviews = useCallback(() => {
    if (
      saveParams.id === id &&
      saveParams.currentPage === pageParams.currentPage &&
      saveParams.size === pageParams.size &&
      comments.isDataLoaded
    ) {
      return;
    }
    setSaveParams({
      id,
      currentPage: pageParams.currentPage,
      size: pageParams.size
    });
    getBlogComments({
      targetId: id,
      page: pageParams.currentPage - 1,
      size: pageParams.size
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageParams]);

  // Handling content
  useEffect(() => {
    fetchBlogReviews();
  }, [fetchBlogReviews]);

  // Handling error
  useEffect(() => {
    if (comments.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments.isError]);

  return (
    <div id="comments" className="blog-inner__comments">
      <div className="blog-inner__comments-title">
        {t("modals.comment.title")}
      </div>
      <Form
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
        afterSubmitHandler={fetchBlogReviews}
      />
      <div className={"blog-inner__comments-reviews"}>
        {comments.data?.content?.length !== 0 && (
          <>
            <Reviews
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              reviews={comments.data?.content}
              changeHandler={fetchBlogReviews}
            />
            <Pagination
              totalPages={comments.data?.totalPages}
              onPageChange={pageChangeHandler}
              currentPage={pageParams.currentPage}
              hideOnSinglePage
            />
          </>
        )}
        {!comments.isLoading && comments.data?.content?.length === 0 && (
          <p>{t("blogInnerPage.comments.noData")}</p>
        )}
        {comments.isLoading && <Loader type="blur" />}
      </div>
    </div>
  );
};
