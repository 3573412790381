import { useTranslation } from "react-i18next";
import Moment from "react-moment";

const getRating = (num = 0) => {
  const rating = [];

  for (let i = 1; i <= 5; i += 1) {
    rating.push(
      <li key={i}>
        <img
          src={
            i <= num ? "/img/ui/star-active.svg" : "/img/ui/star-inactive.svg"
          }
          width={13}
          height={13}
          loading="lazy"
          alt=""
          aria-hidden
        />
      </li>
    );
  }

  return rating;
};

export const UserPart = ({ review }) => {
  const { t } = useTranslation();

  return (
    <div className="reviews__user">
      {review.userName && (
        <div className="reviews__user-name">{review.userName}</div>
      )}
      {review?.rating >= 0 && (
        <ul
          className="reviews__user-rating"
          aria-label={`${t("reviews.rating.title")} ${review?.rating} ${t(
            "reviews.rating.from"
          )} 5`}
        >
          {getRating(review.rating)}
        </ul>
      )}
      {review.creationDate && (
        <div className="reviews__user-date">
          <Moment format="DD.MM.YYYY HH:mm" suppressHydrationWarning>{new Date(review.creationDate)}</Moment>
        </div>
      )}
    </div>
  );
};
