import PT from "prop-types";

import {
  StyledCheckboxIcon,
  StyledCheckboxInput,
  StyledCheckboxLabel,
  StyledCheckboxWrapper,
  StyledLabel
} from "./Checkbox.styled";

export const Checkbox = ({
  name,
  onClick,
  label,
  className,
  checked = false,
  noActionLabel,
  ...rest
}) => (
  <div className={className} onClick={onClick}>
    <StyledCheckboxWrapper>
      <StyledLabel htmlFor={name} className={checked ? "label-checked" : ""}>
        <StyledCheckboxInput
          type="checkbox"
          {...rest}
          id={name}
          defaultChecked={checked}
        />
        <StyledCheckboxIcon
          name={checked ? "checkboxOn" : "checkboxOff"}
          checked={checked}
        />
        {label && <StyledCheckboxLabel>{label}</StyledCheckboxLabel>}
      </StyledLabel>
      {noActionLabel && (
        <StyledCheckboxLabel>{noActionLabel}</StyledCheckboxLabel>
      )}
    </StyledCheckboxWrapper>
  </div>
);
Checkbox.PT = {
  name: PT.string.isRequired,
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  className: PT.string,
  checked: PT.bool,
  onClick: PT.func
};
