import PT from "prop-types";

import { Number, StyledPercent } from "./Percent.styled";

const Percent = ({ number, className, color = "success", style }) => (
  <StyledPercent color={color} className={className} style={style}>
    <Number>{`${number}%`}</Number>
  </StyledPercent>
);

Percent.propTypes = {
  className: PT.string,
  color: PT.string,
  number: PT.oneOfType([PT.string, PT.node]).isRequired,
  style: PT.shape({})
};

export default Percent;
