import BrowserInformationPage from "../../pages/New/ToolsPages/BrowserInformationPage/BrowserInformationPage";
import DnsLeakTestPage from "../../pages/New/ToolsPages/DnsLeakTestPage/DnsLeakTestPage";
import EvercookieTestPage from "../../pages/New/ToolsPages/EvercookieTestPage/EvercookieTestPage";
import FingerprintPage from "../../pages/New/ToolsPages/FingerprintPage/FingerprintPage.jsx";
import FreeProxyPage from "../../pages/New/ToolsPages/FreeProxyPage/FreeProxyPage";
import HttpHeadersCheckerPage from "../../pages/New/ToolsPages/HttpHeadersCheckerPage/HttpHeadersCheckerPage";
import IpBlacklistCheckPage from "../../pages/New/ToolsPages/IpBlacklistCheckPage/IpBlacklistCheckPage";
import MyAnonymityPage from "../../pages/New/ToolsPages/MyAnonymityPage/MyAnonymityPage";
import PortScannerPage from "../../pages/New/ToolsPages/PortScannerPage/PortScannerPage";
import TracingIpPage from "../../pages/New/ToolsPages/TracingIpPage/TracingIpPage";
import WebRTCLeakTestPage from "../../pages/New/ToolsPages/WebRTCLeakTestPage/WebRTCLeakTestPage";

export const ToolsRoutes = (ssr) => [
  {
    path: "/:lang?/tools/fingerprint",
    tag: "fingerprint",
    type: "tool",
    component: ssr ? FingerprintPage : () =>
      import("../../pages/New/ToolsPages/FingerprintPage/FingerprintPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/port-scanner",
    tag: "port-scanner",
    type: "tool",
    component: ssr ? PortScannerPage : () =>
      import("../../pages/New/ToolsPages/PortScannerPage/PortScannerPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/tracing-ip",
    tag: "tracing-ip",
    type: "tool",
    component: ssr ? TracingIpPage : () =>
      import("../../pages/New/ToolsPages/TracingIpPage/TracingIpPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/dns-leak-test",
    tag: "dns-leak-test",
    type: "tool",
    component: ssr ? DnsLeakTestPage : () =>
      import("../../pages/New/ToolsPages/DnsLeakTestPage/DnsLeakTestPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/ip-blacklist-check",
    tag: "ip-blacklist-check",
    type: "tool",
    component: ssr ? IpBlacklistCheckPage : () =>
      import(
        "../../pages/New/ToolsPages/IpBlacklistCheckPage/IpBlacklistCheckPage"
      ),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/free-proxy",
    tag: "free-proxy",
    type: "tool",
    component: ssr ? FreeProxyPage : () =>
      import("../../pages/New/ToolsPages/FreeProxyPage/FreeProxyPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/my-anonymity",
    tag: "my-anonymity",
    type: "tool",
    component: ssr ? MyAnonymityPage : () =>
      import("../../pages/New/ToolsPages/MyAnonymityPage/MyAnonymityPage"),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/webrtc-leak-test",
    tag: "webrtc-leak-test",
    type: "tool",
    component: ssr ? WebRTCLeakTestPage : () =>
      import(
        "../../pages/New/ToolsPages/WebRTCLeakTestPage/WebRTCLeakTestPage"
      ),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/http-headers-checker",
    tag: "http-headers-checker",
    type: "tool",
    component: ssr ? HttpHeadersCheckerPage : () =>
      import(
        "../../pages/New/ToolsPages/HttpHeadersCheckerPage/HttpHeadersCheckerPage"
      ),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/evercookie-test",
    tag: "evercookie-test",
    type: "tool",
    component: ssr ? EvercookieTestPage : () =>
      import(
        "../../pages/New/ToolsPages/EvercookieTestPage/EvercookieTestPage"
      ),
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/tools/browser-information",
    tag: "browser-information",
    type: "tool",
    component: ssr ? BrowserInformationPage : () =>
      import(
        "../../pages/New/ToolsPages/BrowserInformationPage/BrowserInformationPage"
      ),
    isAuthRequired: false,
    isSsr: true
  }
];

