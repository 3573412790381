export const programVersionHelpers = (data) => {
  const { userAgent, app } = data;

  const regex = new RegExp(`${app}/([\\d\\.]+)`);
  const match = userAgent.match(regex);

  if (match) {
    return match[1];
  }

  return "N/A";
};
