import styled from "@emotion/styled";

export const StyledCard = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;

  @media (min-width: $lg-min) {
    white-space: normal;
  }
`;

export const StyledIcon = styled.img`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 24px;
`;

export const StyledText = styled.div`
  color: ${(props) => props.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background: white;
  padding: 16px 16px 16px 0;
  border-radius: 12px;
  box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);
  width: 1170px;
  white-space: normal;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 100%;
    padding: 36px;
  }
`;
