import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { getAllBlogInner } from "../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllTranslation } from "../../../../../../store/reducers/TranslationReducer/Translation.selectors";
import { langs } from "../../../../../../utils/constants/langs";
import { setCookie } from "../../../../../../utils/helpers/cookie.helpers";

import { RestyledSelect } from "./LangSelect.styled";

const BLOG_INNER_TAG = "bloginner";

export const LangSelect = () => {
  // **Dispatch
  const { setCurrentLang } = useDispatchedActions();

  const [queryLang] = useLangUrlDefault();
  const location = useLocation();
  const navigate = useNavigate();

  // **Redux state
  const { languages } = useSelector(getAllTranslation);
  const { t } = useTranslation();
  const { routeContent } = useSelector(getAllContent);
  const { content: BlogInner } = useSelector(getAllBlogInner);

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    setCookie("lang", newLang);
    const pathnameSplit = location.pathname.split("/");
    const isNotLangUrl = pathnameSplit[1]?.length > 2;
    const rootEn = newLang === "en" ? "" : `/${newLang}`;

    setCurrentLang(newLang);

    if (routeContent.innerTag === BLOG_INNER_TAG) {
      const { langLinks } = BlogInner.data;
      const isLangForBlog = langLinks.hasOwnProperty(newLang);

      if (isLangForBlog) {
        navigate(`${newLang}/blog/${langLinks[newLang]}/`);
      } else {
        toast.error(t("notifications.blogInner.notFoundBlogPost"));
        navigate(`${rootEn}/blog/?tab=articles`);
      }

      return;
    }

    navigate({
      pathname: `${rootEn}/${location.pathname.slice(isNotLangUrl ? 1 : 4)}`,
      hash: location.hash,
      search: location.search,
      state: location.state
    });
  };

  const options = languages.map((language) => ({
    value: language.value,
    label: langs[language.value]
  }));

  return (
    <RestyledSelect
      options={options}
      color={"menu"}
      onChange={(value) => changeLanguage(value)}
      defaultValue={queryLang}
      value={queryLang}
      dataTextLabel={t("languages.title")}
    />
  );
};
