import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../../hooks";

export const SidebarItem = ({ post, closeSidebar }) => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  const routeHandler = () => {
    closeSidebar(false);
  };

  return (
    <li className="blog-inner__sidebar-article">
      <div className="blog-inner__sidebar-article-image">
        <Link to={`${hrefLang}/blog/${post.alias}/`} onClick={routeHandler}>
          <img
            src={post.coverImage ? post.coverImage : "/img/ui/placeholder.svg"}
            width={100}
            height={100}
            loading="lazy"
            alt=""
          />
        </Link>
      </div>
      <div className="blog-inner__sidebar-article-content">
        <div className="blog-inner__sidebar-article-title">
          <Link to={`${hrefLang}/blog/${post.alias}/`} onClick={routeHandler}>
            {post.title}
          </Link>
        </div>
        <div className="blog-inner__sidebar-article-more">
          <Link to={`${hrefLang}/blog/${post.alias}/`} onClick={routeHandler}>
            {t("blogInnerPage.sidebar.more")}
          </Link>
        </div>
      </div>
    </li>
  );
};
