export const setErrorBlogInnerContentReducer = (state, action) => {
  state.content.isError = action.payload;
};

export const setBlogSidebarContentReducer = (state, action) => {
  state.sidebar.data = action.payload;
  state.sidebar.isDataLoaded = true;
};

export const setBlogContentByAliasReducer = (state, action) => {
  state.content.data = action.payload;
  state.content.isDataLoaded = true;
};

export const setBlogCommentsReducer = (state, action) => {
  state.comments.data = action.payload;
  state.comments.isDataLoaded = true;
};
