import styled from "@emotion/styled";
import "moment/locale/ru";
import "moment/locale/uk";

export const StyledPeriodCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;

  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 170%;
`;

export const StyledEternalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  padding: 8px 12px;
  border-radius: 8px;
  background-color: #eeeeee;
  line-height: 130%;
`;
