import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getParamsUrlForFilter } from "../../../../../../utils/helpers";

import "./ProxySort.scss";

export const ProxySort = memo(() => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // **Redux state
  const { proxyTypes } = useSelector(getAllContent);
  const { t } = useTranslation();

  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();

  const proxyTypesForLang = proxyTypes.data?.[queryLang];
  const isProxyTypesForLangNotEmpty =
    proxyTypesForLang && proxyTypesForLang?.length !== 0;

  const routeHandler = (filter, evt = null) => {
    const sortArray = [filter];

    const fc = searchParams.get("fc");
    const fg = searchParams.get("fg");
    const s = searchParams.get("s");
    const urlForFilter = getParamsUrlForFilter(fc, fg, sortArray, s);

    if (evt === null) {
      return urlForFilter;
    }

    navigate(urlForFilter);
    setActiveFilters({ fpt: sortArray });
  };

  return (
    <div className="proxy-sort">
      <div className="proxy-sort__title">
        {t("proxyPage.content.filter.title")}
      </div>
      <div className="proxy-sort__list">
        {isProxyTypesForLangNotEmpty &&
          proxyTypesForLang?.map(({ type, name }) => (
            <a
              key={type}
              href={`${hrefLang}/proxy/${routeHandler(type)}`}
              className={
                searchParams.getAll("fpt").includes(type)
                  ? "proxy-sort__item proxy-sort__item--active"
                  : "proxy-sort__item"
              }
              onClick={(evt) => {
                evt.preventDefault();
                routeHandler(type, evt);
              }}
            >
              {name}
            </a>
          ))}
      </div>
    </div>
  );
});
