import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledReadReview } from "./ReadReview.styled";

export const ReadReview = ({ className, linkTo, v1 = false }) => {
  const { t } = useTranslation();

  return (
    <StyledReadReview className={className} to={linkTo} v1={v1.toString()}>
      {t("ui.readReview")}
    </StyledReadReview>
  );
};

ReadReview.propTypes = {
  className: PT.string,
  linkTo: PT.string.isRequired,
  v1: PT.bool
};

export default ReadReview;
