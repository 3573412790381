import { useTranslation } from "react-i18next";

import animation from "../../../assets/animations/main.riv";
import { mainHeroImageMobile } from "../../../assets/img";
import SeoSection from "../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/New/Rive/Rive";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { getCountryId, getGoalId } from "../../../utils/helpers";
import { scrollToWithOffset } from "../../../utils/helpers/scroll.helper";

import { RestyledMainSection } from "./MainPage.styled";
import { Proxy } from "./components/Proxy/Proxy";
import { UserChoice } from "./components/UserChoice/UserChoice";

const MainPage = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    scrollToWithOffset("proxyContent", -50);
  };

  return (
    <>
      <RestyledMainSection
        highlightWord={t("mainPage.highlightWord")}
        title={t("mainPage.title")}
        subtitle={t("mainPage.description")}
        buttonLabel={t("navigation.selectService")}
        animation={<Rive src={animation} autoPlay />}
        images={{
          mobile1x: mainHeroImageMobile,
          mobile2x: mainHeroImageMobile
        }}
        handleButtonClick={handleButtonClick}
        star
      />
      <Proxy />
      <UserChoice />
      <SeoSection noPaddingTop />
    </>
  );
};
export default MainPage;

MainPage.getServerSideState = async (store, params) => {
  const fptParams = params.params.fpt || "IPv4";
  // const { data: pageContent } = await $api.post("/api/page/front/content", {
  //   ...params,
  //   location: params.params.fpt
  //     ? params.location
  //     : `${params.location}?fpt=IPv4`,
  //   params: {
  //     ...params.params,
  //     fpt: Array.isArray(fptParams)
  //       ? fptParams[fptParams?.length - 1]
  //       : fptParams || null
  //   }
  // });

  const {
    content: { countries, goals }
  } = store.getState();

  const fetchParams = {
    proxyType: Array.isArray(fptParams) ? fptParams : [fptParams],
    country: getCountryId(countries.data, params?.params?.fc)?.id || "",
    sort: params?.params?.s || "",
    goal: getGoalId(goals.data, params?.params?.fg)?.id || "",
    top: false,
    useNew: true,
    page: 0,
    size: 10
  };
  const { data: sites } = await $api.post("/api/front/site/proxy", fetchParams);

  const { data: UserChoiceData } = await $api.get("/api/front/userChoices", {
    params: {
      size: 10
    }
  });

  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: ["mainPageProxyTable"]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );

  store.dispatch(AllActions.setActivePageParams(params));
  // store.dispatch(
  //   AllActions.setPageContent({
  //     data: pageContent,
  //     lang: params.locale,
  //     innerTag: params.location
  //   })
  // );
  store.dispatch(
    AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
  );
  store.dispatch(AllActions.setProxySites({ ...sites, fetchParams }));
  store.dispatch(AllActions.setUserChoice(UserChoiceData));
};
