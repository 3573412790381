import PT from "prop-types";

import { StyledSubtitle } from "./Subtitle.styled";

export const Subtitle = ({ size, color, children, className }) => (
  <StyledSubtitle white={color === "white"} size={size} className={className}>
    {children}
  </StyledSubtitle>
);

Subtitle.propTypes = {
  children: PT.node,
  className: PT.string,
  size: PT.oneOf(["lg"]),
  color: PT.oneOf(["white"])
};
