export const proxyServicesList = (proxylist) =>
  proxylist
    ?.filter((type) => type.showInMenu)
    ?.map((item) => ({
      route: "proxy",
      search: `?fpt=${item.type}`,
      title: item?.localizationMenu?.name || "",
      description: item?.localizationMenu?.description || "",
      useCountries: item.type === "IPv4",
      chip: item?.isNew && "new"
    }));
