import { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Download } from "../../icons";

import { CropperImg } from "./CropperImg/CropperImg";

import "./Uploader.scss";

export const Uploader = ({
  defaultImage = null,
  onUpload,
  croppedFileObjects,
  setCroppedFileObjects,
  ...rest
}) => {
  const { t } = useTranslation();

  // **Local state
  const [fileObject, setFileObject] = useState(defaultImage);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const dragHandler = (e, state) => {
    e.preventDefault();
    setIsDragging(state);
  };

  const hoverTextFileHandler = (evt) => {
    const eventType = evt.type;
    const containerUploader = evt.currentTarget.closest(".uploader");
    const fileTextUploader = containerUploader.querySelector(
      ".uploader__file-text"
    );

    if (eventType === "mouseenter") {
      fileTextUploader.classList.add("uploader__file-text--active");
    }

    if (eventType === "mouseleave") {
      fileTextUploader.classList.remove("uploader__file-text--active");
    }
  };

  const uploadHandler = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e?.dataTransfer?.files[0] || e?.target?.files[0];
    e.target.value = "";

    if (!file) {
      toast.error(t("notifications.file.badFile"));
      return;
    }

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      toast.error(t("notifications.file.badFileType"));
      return;
    }

    if (file.size / 1024 / 1024 >= 2) {
      toast.error(t("notifications.file.largeSize"));
      return;
    }

    setFileObject(file);
    setIsModalOpened(true);
  };

  const modalHandler = () => {
    setIsModalOpened(false);
  };

  return (
    <>
      <div
        className={isDragging ? "uploader uploader--dragging" : "uploader"}
        onDragStart={(e) => dragHandler(e, true)}
        onDragOver={(e) => dragHandler(e, true)}
        onDragLeave={(e) => dragHandler(e, false)}
        onDrop={uploadHandler}
        {...rest}
      >
        <div className="uploader__input">
          <label>
            <span
              className="uploader__file"
              onMouseEnter={hoverTextFileHandler}
              onMouseLeave={hoverTextFileHandler}
            ></span>
            <input
              type="file"
              accept="image/jpeg, image/png"
              onChange={uploadHandler}
            />
          </label>
        </div>
        <div className="uploader__wrapper">
          {croppedFileObjects?.length ? (
            <div className="uploader__preview">
              <div className="uploader__preview-wrapper">
                <img
                  src={
                    typeof croppedFileObjects[0] !== "string"
                      ? URL?.createObjectURL(croppedFileObjects[0])
                      : defaultImage
                  }
                  width={115}
                  height={35}
                  loading="lazy"
                  alt=""
                  aria-hidden="true"
                />
              </div>
            </div>
          ) : (
            <div className="uploader__icon">
              <Download />
            </div>
          )}
          <div
            className={
              croppedFileObjects?.length
                ? "uploader__content uploader__content--column"
                : "uploader__content"
            }
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {croppedFileObjects?.length ? (
              <>
                {croppedFileObjects[0]?.name && (
                  <div className="uploader__text">
                    {croppedFileObjects[0].name}
                  </div>
                )}
                <span className="uploader__file-text">
                  {t("uploader.chooseOtherFile")}
                </span>
              </>
            ) : !isDragging ? (
              <>
                <div className="uploader__text">
                  <span className="uploader__file-text">
                    {t("uploader.chooseFile")}
                  </span>{" "}
                  {t("uploader.staticState")}
                </div>
              </>
            ) : (
              <div className="uploader__text">
                {t("uploader.draggingState")}
              </div>
            )}
          </div>
        </div>
      </div>
      <CropperImg
        isModalOpened={isModalOpened}
        defaultImage={defaultImage}
        fileObject={fileObject}
        setCroppedFileObjects={setCroppedFileObjects}
        onUpload={onUpload}
        cancelModalHandler={modalHandler}
      />
    </>
  );
};
