export const setUserReducer = (state, action) => {
  state.user = action.payload;
};

export const setDefaultUserReducer = (state) => {
  state.user = {
    email: "",
    uid: "",
    picture: "",
    name: "",
    roles: []
  };
};

export const setAuthenticationReducer = (state, action) => {
  state.isUserAuthenticated = action.payload;
  state.isLogout = action.payload ? false : state.isLogout;
  state.isAuthfetched = true;
};

export const setIsRedirectToCabinetReducer = (state, action) => {
  state.isRedirectToCabinet = action.payload;
};

export const setLogoutReducer = (state) => {
  state.isLogout = true;
  state.user = {
    email: "",
    uid: "",
    picture: "",
    name: "",
    roles: []
  };
  state.isUserAuthenticated = false;
};
