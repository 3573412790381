import { useParams } from "react-router-dom";

import { useLangUrlDefault } from "../../../hooks";

import { TabDropdown } from "./TabDropdown/TabDropdown";
import TabsWraper from "./TabsWraper/TabsWraper";

import "./Tabs.scss";

export const Tabs = ({
  title,
  tabs,
  currentTab,
  onTabClick,
  reviewsAmount,
  hashType
}) => {
  const { alias } = useParams();
  const [, hrefLang] = useLangUrlDefault();

  const generateLink = (tabName = "") => {
    let link = `${hrefLang}/proxy/${alias ? `${alias}/` : ""}`;

    if (hashType) {
      if (tabName !== "promocodes") {
        link = `${link}#${tabName}`;
      } else {
        link = `${link}${tabName}/`;
      }
    } else {
      link = `${link}?tab=${tabName}`;
    }

    return link;
  };

  return (
    <div className="tabs">
      <div className="mdMin">
        {title && <div className="tabs__title">{title}</div>}
        <div className="tabs__wrapper">
          <TabsWraper
            tabs={tabs}
            currentTab={currentTab}
            generateLink={generateLink}
            reviewsAmount={reviewsAmount}
            onTabClick={onTabClick}
          />
        </div>
      </div>
      <div className="mdMax">
        <TabDropdown
          tabs={tabs}
          currentTab={currentTab}
          onTabClick={onTabClick}
        />
      </div>
    </div>
  );
};
