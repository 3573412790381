export const cabinetInitialState = {
  proxies: {
    data: {
      content: [],
      totalElements: 0,
      totalPages: 0
    },
    isLoading: false,
    isError: null,
    prevParams: null
  },
  allProxies: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  siteStatuses: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  promocodes: {
    data: {
      content: [],
      totalElements: 0,
      totalPages: 0
    },
    isLoading: false,
    isError: null,
    prevParams: null
  }
};
