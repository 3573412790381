import styled from "@emotion/styled";

export const StyledYesNoBox = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: 400;
  line-height: 130%;
  text-align: left;
`;

export const StyledYesNoDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(p) =>
    p.value ? p.theme.colors["main-600"] : p.theme.colors["red-500"]};
`;
