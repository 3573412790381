import { Navigate, Route } from "react-router-dom";

import { AppLayout } from "../layout/app.layout";
import { AuthLayout as OldAuthLayout } from "../layout/auth.layout";
import { CabinetLayout } from "../layout/cabinet.layout";
import { ExportLayout } from "../layout/export.layout";
import { IndexLayout as OldIndexLayout } from "../layout/index.layout";
import { AuthLayout } from "../layout/new/AuthLayout";
import { DashboardLayout } from "../layout/new/DashbordLayout/DashboardLayout";
import { IndexLayout } from "../layout/new/index.layout";
import { NewAppLayout } from "../layout/new/new.app.layout";
import { SidebarLayout } from "../layout/sidebar.layout";
import { renderRoutes } from "../utils/helpers/renderRoutes.helper";

import { AppRoutes } from "./new/app.routes";
import { AuthRoutes } from "./new/auth.routes";
import { DashboardRoutes } from "./new/dashboard.routes";
import { ExportRoutes } from "./new/export.routes";
import { ToolsRoutes } from "./new/tools.routes";
import { AppRoutesOLD } from "./old/app.routes";
import { AuthRoutesOLD } from "./old/auth.routes";
import { CabinetRoutesOLD } from "./old/cabinet.routes";
import { SidebarRoutesOLD } from "./old/sidebar.routes";

const convertRoutes = (routes) =>
  routes.map((route) => {
    // eslint-disable-next-line no-unused-vars
    const { component, element, ...rest } = route;
    return {
      ...rest,
      async lazy() {
        const module = await component();
        return { Component: module.default };
      }
    };
  });

export const NEW_ROUTES = () => [
  {
    element: <OldIndexLayout />,
    children: [
      {
        element: <AppLayout />,
        children: convertRoutes(AppRoutesOLD())
      },
      {
        element: <SidebarLayout />,
        children: renderRoutes(SidebarRoutesOLD())
      },
      {
        element: <OldAuthLayout />,
        children: renderRoutes(AuthRoutesOLD())
      },
      {
        element: <CabinetLayout />,
        children: renderRoutes(CabinetRoutesOLD())
      }
    ]
  },
  {
    element: <IndexLayout />,
    children: [
      { element: <NewAppLayout />, children: renderRoutes(AppRoutes()) },
      { element: <NewAppLayout />, children: renderRoutes(ToolsRoutes()) },
      {
        loader: () => ({ isAuthRequired: true }),
        element: <DashboardLayout />,
        children: renderRoutes(DashboardRoutes())
      },
      { element: <AuthLayout />, children: renderRoutes(AuthRoutes()) }
    ]
  },
  {
    element: <ExportLayout />,
    children: renderRoutes(ExportRoutes())
  },
  {
    path: "*",
    element: <Navigate to="/" replace={true} />
  }
];

// eslint-disable-next-line no-unused-vars
export const getRoutes = ({ lang = "en", store = null }) => (
  <>
    {/* <Route element={<OldIndexLayout />}>
      <Route element={<AppLayout />}>
        {renderRoutes(AppRoutesOLD(), store)}
      </Route>
      <Route element={<SidebarLayout />}>
        {renderRoutes(SidebarRoutesOLD(), store)}
      </Route>
      <Route element={<OldAuthLayout />}>
        {renderRoutes(AuthRoutesOLD(), store)}
      </Route>
      <Route element={<CabinetLayout />}>
        {renderRoutes(CabinetRoutesOLD(), store)}
      </Route>
    </Route> */}
    <Route element={<IndexLayout />}>
      <Route element={<NewAppLayout />}>
        {renderRoutes(AppRoutes(), store)}
        {renderRoutes(ToolsRoutes(), store)}
      </Route>
      <Route
        element={<DashboardLayout />}
        loader={() => ({ isAuthRequired: true })}
      >
        {renderRoutes(DashboardRoutes(), store)}
      </Route>
      <Route element={<AuthLayout />}>
        {renderRoutes(AuthRoutes(), store)}
      </Route>
    </Route>
    <Route element={<ExportLayout />}>
      {renderRoutes(ExportRoutes(), store)}
    </Route>
    <Route path="*" element={<Navigate to="/" replace={true} />} />
  </>
);
