import { useSelector } from "react-redux";

import { getAllBlogInner } from "../../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

export const Body = () => {
  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <div
      className="blog-inner__body"
      dangerouslySetInnerHTML={{ __html: content.data.articleBody }}
    ></div>
  );
};
