import PT from "prop-types";
import { useNavigate } from "react-router-dom";

import { Menu, MenuButton, MenuItem } from "./Dropdown.styled";

export const Dropdown = ({
  className,
  menuClassName,
  align = "center",
  direction = "bottom",
  label,
  children,
  portal,
  position = "auto",
  variant = "default",
  menuWidth,
  mlauto = false
}) => (
  <Menu
    transition
    align={align}
    direction={direction}
    className={menuClassName}
    propwidth={menuWidth}
    position={position}
    portal={portal}
    menuButton={
      <MenuButton
        className={className}
        variant={variant}
        mlauto={mlauto.toString()}
      >
        {label}
      </MenuButton>
    }
  >
    {children}
  </Menu>
);

Dropdown.propTypes = {
  className: PT.string,
  menuClassName: PT.string,
  align: PT.oneOf(["start", "end", "center"]),
  direction: PT.oneOf(["top", "bottom", "left", "right"]),
  position: PT.oneOf(["auto", "anchor", "initial"]),
  variant: PT.oneOf(["default", "table"]),
  portal: PT.bool,
  label: PT.node,
  children: PT.node,
  mlauto: PT.bool,
  menuWidth: PT.number
};

export const DropdownItem = ({
  children,
  onClick,
  linkTo,
  target,
  disabled = false,
  className,
  active = false,
  variant = "default"
}) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.syntheticEvent.preventDefault();
    onClick?.();
    if (linkTo) {
      if (target === "_blank") {
        window.open(linkTo, "_blank");
        return;
      }
      navigate(linkTo, { replace: true });
    }
  };

  return (
    <MenuItem
      onClick={handleClick}
      className={className}
      disabled={disabled}
      active={active.toString()}
      href={linkTo}
      target={target}
      variant={variant}
    >
      {children}
    </MenuItem>
  );
};

DropdownItem.propTypes = {
  children: PT.node,
  className: PT.string,
  onClick: PT.func,
  linkTo: PT.string,
  target: PT.string,
  disabled: PT.bool,
  active: PT.bool,
  variant: PT.oneOf(["default", "danger"])
};

Dropdown.Item = DropdownItem;

export default Dropdown;
