import PT from "prop-types";

import { StyledAnnotationCell } from "./AnnotationCell.styled";

export const AnnotationCell = ({ positive = 0, negative = 0 }) => (
  <StyledAnnotationCell
    positive={positive}
    negative={negative}
    showIcon={false}
  />
);

AnnotationCell.propTypes = {
  positive: PT.number,
  negative: PT.number
};
