import { useEffect, useState } from "react";

import { browserInfoInitialValues } from "../utils/constants";
import { programVersionHelpers } from "../utils/helpers";

export const useBrowserInfo = (data) => {
  const [browserInfo, setBrowserInfo] = useState(browserInfoInitialValues);

  useEffect(() => {
    if (data && typeof window !== "undefined") {
      const version = programVersionHelpers(data);

      setBrowserInfo({
        browserLanguage: navigator.language,
        appVersion: navigator.appVersion,
        browserVersion: version
      });
    }
  }, [data]);

  return { browserInfo };
};
