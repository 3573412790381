import { Link } from "react-router-dom";
import { Container } from "../../../components/ui/Container/Container";
import { Button } from "../../../components/ui/Button/Button";

const TestPage = () => (<Container>
        <Link to="/u3e83e8yr47233e0ur47y883/test/errorBoundary">
            <Button>====== !!! TEST BTN !!! ======</Button>
        </Link>
    </Container>);

export default TestPage;
