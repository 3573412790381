import { createSlice } from "@reduxjs/toolkit";

import { contentInitialState } from "./Content.initialState";
import {
  setActiveFiltersReducer,
  setActivePageParamsReducer,
  setCaptchaConfigReducer,
  setClearPageContentReducer,
  setCountriesReducer,
  setCurrenciesReducer,
  setCurrentPageContentReducer,
  setGoalsByGroupsReducer,
  setGoalsReducer,
  setMainBlockListReducer,
  setPageContentReducer,
  setProxyTypesMenuReducer,
  setProxyTypesReducer,
  setRentPeriodsReducer,
  setReviewsReducer,
  setRouteContentReducerReducer,
  setSellersReducer,
  setSeoDataDefaultReducer,
  setSocialsReducer,
  setStatisticReducer,
  setTopSitesReducer,
  setUserChoiceReducer,
  setUserIpReducer
} from "./Content.reducer";
import {
  getAllCountries,
  getAllCurrencies,
  getAllGoalGroups,
  getAllGoals,
  getAllMenuProxies,
  getAllProxies,
  getAllRentPeriods,
  getAllSocials,
  getAllTopSellers,
  getCaptchaConfig,
  getLastSiteReviews,
  getMainBlockList,
  getPageContent,
  getSeoDataDefault,
  getStatistic,
  getTopSites,
  getUserChoice,
  getUserIp,
  setActiveFilters,
  setActivePageParams,
  setCaptchaConfig,
  setClearPageContent,
  setCountries,
  setCurrencies,
  setCurrentPageContent,
  setGoals,
  setGoalsByGroups,
  setMainBlockList,
  setPageContent,
  setProxyTypes,
  setProxyTypesMenu,
  setRentPeriods,
  setReviews,
  setRouteContent,
  setSellers,
  setSeoDataDefault,
  setSocials,
  setStatistic,
  setTopSites,
  setUserChoice,
  setUserIp
} from "./Content.thunks";

const ContentSlice = createSlice({
  name: "content",
  initialState: contentInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setRouteContent, setRouteContentReducerReducer)
      .addCase(setStatistic, setStatisticReducer)
      .addCase(setActiveFilters, setActiveFiltersReducer)
      .addCase(setPageContent, setPageContentReducer)
      .addCase(setCurrentPageContent, setCurrentPageContentReducer)
      .addCase(setClearPageContent, setClearPageContentReducer)
      .addCase(setCountries, setCountriesReducer)
      .addCase(setGoalsByGroups, setGoalsByGroupsReducer)
      .addCase(setSellers, setSellersReducer)
      .addCase(setReviews, setReviewsReducer)
      .addCase(setGoals, setGoalsReducer)
      .addCase(setTopSites, setTopSitesReducer)
      .addCase(setCurrencies, setCurrenciesReducer)
      .addCase(setRentPeriods, setRentPeriodsReducer)
      .addCase(setProxyTypes, setProxyTypesReducer)
      .addCase(setActivePageParams, setActivePageParamsReducer)
      .addCase(setCaptchaConfig, setCaptchaConfigReducer)
      .addCase(setSocials, setSocialsReducer)
      .addCase(setMainBlockList, setMainBlockListReducer)
      .addCase(setSeoDataDefault, setSeoDataDefaultReducer)
      .addCase(setUserIp, setUserIpReducer)
      .addCase(setUserChoice, setUserChoiceReducer)
      .addCase(setProxyTypesMenu, setProxyTypesMenuReducer)

      // getPageContent
      .addCase(getPageContent.pending, (state) => {
        state.pageContent.isError = null;
        state.pageContent.isLoading = true;
      })
      .addCase(getPageContent.fulfilled, (state, action) => {
        state.pageContent.isError = null;
        state.pageContent.data = {
          ...state.pageContent.data,
          [action.payload.innerTag]: action.payload.data
        };
        state.pageContent.isDataLoaded = true;
        state.pageContent.isLoading = false;
      })
      .addCase(getPageContent.rejected, (state, action) => {
        state.pageContent.isError = action.payload;
        state.pageContent.isLoading = false;
      })

      // getAllCountries
      .addCase(getAllCountries.pending, (state) => {
        state.countries.isError = null;
        state.countries.isLoading = true;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.countries.isError = null;
        state.countries.data = action.payload;
        state.countries.isDataLoaded = true;
        state.countries.isLoading = false;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.countries.isError = action.payload;
        state.countries.isLoading = false;
      })

      // getAllGoalGroups
      .addCase(getAllGoalGroups.pending, (state) => {
        state.goalsByGroups.isError = null;
        state.goalsByGroups.isLoading = true;
      })
      .addCase(getAllGoalGroups.fulfilled, (state, action) => {
        state.goalsByGroups.isError = null;
        state.goalsByGroups.data = action.payload;
        state.goalsByGroups.isDataLoaded = true;
        state.goalsByGroups.isLoading = false;
      })
      .addCase(getAllGoalGroups.rejected, (state, action) => {
        state.goalsByGroups.isError = action.payload;
        state.goalsByGroups.isLoading = false;
      })

      // getAllGoals
      .addCase(getAllGoals.pending, (state) => {
        state.goals.isError = null;
        state.goals.isLoading = true;
      })
      .addCase(getAllGoals.fulfilled, (state, action) => {
        state.goals.isError = null;
        state.goals.data = action.payload;
        state.goals.isDataLoaded = true;
        state.goals.isLoading = false;
      })
      .addCase(getAllGoals.rejected, (state, action) => {
        state.goals.isError = action.payload;
        state.goals.isLoading = false;
      })

      // getAllTopSellers
      .addCase(getAllTopSellers.pending, (state) => {
        state.sellers.isError = null;
        state.sellers.isLoading = true;
      })
      .addCase(getAllTopSellers.fulfilled, (state, action) => {
        state.sellers.isError = null;
        state.sellers.data = action.payload;
        state.sellers.isDataLoaded = true;
        state.sellers.isLoading = false;
      })
      .addCase(getAllTopSellers.rejected, (state, action) => {
        state.sellers.isError = action.payload;
        state.sellers.isLoading = false;
      })

      // getLastSiteReviews
      .addCase(getLastSiteReviews.pending, (state) => {
        state.reviews.isError = null;
        state.reviews.isLoading = true;
      })
      .addCase(getLastSiteReviews.fulfilled, (state, action) => {
        state.reviews.isError = null;
        state.reviews.data = action.payload;
        state.reviews.isDataLoaded = true;
        state.reviews.isLoading = false;
      })
      .addCase(getLastSiteReviews.rejected, (state, action) => {
        state.reviews.isError = action.payload;
        state.reviews.isLoading = false;
      })

      // getTopSites
      .addCase(getTopSites.pending, (state) => {
        state.topSites.isError = null;
        state.topSites.isLoading = true;
      })
      .addCase(getTopSites.fulfilled, (state, action) => {
        state.topSites.isError = null;
        state.topSites.data = action.payload;
        state.topSites.isDataLoaded = true;
        state.topSites.isLoading = false;
      })
      .addCase(getTopSites.rejected, (state, action) => {
        state.topSites.isError = action.payload;
        state.topSites.isLoading = false;
      })

      // getAllCurrencies
      .addCase(getAllCurrencies.pending, (state) => {
        state.currencies.isError = null;
        state.currencies.isLoading = true;
      })
      .addCase(getAllCurrencies.fulfilled, (state, action) => {
        state.currencies.isError = null;
        state.currencies.data = action.payload;
        state.currencies.isDataLoaded = true;
        state.currencies.isLoading = false;
      })
      .addCase(getAllCurrencies.rejected, (state, action) => {
        state.currencies.isError = action.payload;
        state.currencies.isLoading = false;
      })

      // getAllRentPeriods
      .addCase(getAllRentPeriods.pending, (state) => {
        state.rentPeriods.isError = null;
        state.rentPeriods.isLoading = true;
      })
      .addCase(getAllRentPeriods.fulfilled, (state, action) => {
        state.rentPeriods.isError = null;
        state.rentPeriods.data = action.payload;
        state.rentPeriods.isDataLoaded = true;
        state.rentPeriods.isLoading = false;
      })
      .addCase(getAllRentPeriods.rejected, (state, action) => {
        state.rentPeriods.isError = action.payload;
        state.rentPeriods.isLoading = false;
      })

      // getAllProxies
      .addCase(getAllProxies.pending, (state) => {
        state.proxyTypes.isError = null;
        state.proxyTypes.isLoading = true;
      })
      .addCase(getAllProxies.fulfilled, (state, action) => {
        state.proxyTypes.isError = null;
        state.proxyTypes.data = action.payload;
        state.proxyTypes.isDataLoaded = true;
        state.proxyTypes.isLoading = false;
      })
      .addCase(getAllProxies.rejected, (state, action) => {
        state.proxyTypes.isError = action.payload;
        state.proxyTypes.isLoading = false;
      })

      // getAllProxiesMenu
      .addCase(getAllMenuProxies.pending, (state) => {
        state.proxyTypesMenu.isError = null;
        state.proxyTypesMenu.isLoading = true;
      })
      .addCase(getAllMenuProxies.fulfilled, (state, action) => {
        state.proxyTypesMenu.isError = null;
        state.proxyTypesMenu.data = action.payload;
        state.proxyTypesMenu.isDataLoaded = true;
        state.proxyTypesMenu.isLoading = false;
      })
      .addCase(getAllMenuProxies.rejected, (state, action) => {
        state.proxyTypesMenu.isError = action.payload;
        state.proxyTypesMenu.isLoading = false;
      })

      // getStatistic
      .addCase(getStatistic.pending, (state) => {
        state.statistic.isError = null;
        state.statistic.isLoading = true;
      })
      .addCase(getStatistic.fulfilled, (state, action) => {
        state.statistic.isError = null;
        state.statistic.data = action.payload;
        state.statistic.isDataLoaded = true;
        state.statistic.isLoading = false;
      })
      .addCase(getStatistic.rejected, (state, action) => {
        state.statistic.isError = action.payload;
        state.statistic.isLoading = false;
      })

      // getAllSocials
      .addCase(getAllSocials.pending, (state) => {
        state.socials.isError = null;
        state.socials.isLoading = true;
      })
      .addCase(getAllSocials.fulfilled, (state, action) => {
        state.socials.isError = null;
        state.socials.data = action.payload;
        state.socials.isDataLoaded = true;
        state.socials.isLoading = false;
      })
      .addCase(getAllSocials.rejected, (state, action) => {
        state.socials.isError = action.payload;
        state.socials.isLoading = false;
      })

      // getCaptchaConfig
      .addCase(getCaptchaConfig.pending, (state) => {
        state.captchaConfig.isError = null;
        state.captchaConfig.isLoading = true;
      })
      .addCase(getCaptchaConfig.fulfilled, (state, action) => {
        state.captchaConfig.isError = null;
        state.captchaConfig.data = action.payload;
        state.captchaConfig.isDataLoaded = true;
        state.captchaConfig.isLoading = false;
      })
      .addCase(getCaptchaConfig.rejected, (state, action) => {
        state.captchaConfig.isError = action.payload;
        state.captchaConfig.isLoading = false;
      })

      // getMainBlockList
      .addCase(getMainBlockList.pending, (state) => {
        state.mainBlockList.isError = null;
        state.mainBlockList.isLoading = true;
      })
      .addCase(getMainBlockList.fulfilled, (state, action) => {
        state.mainBlockList.isError = null;
        state.mainBlockList.data = action.payload;
        state.mainBlockList.isDataLoaded = true;
        state.mainBlockList.isLoading = false;
      })
      .addCase(getMainBlockList.rejected, (state, action) => {
        state.mainBlockList.isError = action.payload;
        state.mainBlockList.isLoading = false;
      })

      // getUserIp
      .addCase(getUserIp.pending, (state) => {
        state.userIp.isError = null;
        state.userIp.isLoading = true;
      })
      .addCase(getUserIp.fulfilled, (state, action) => {
        state.userIp.isError = null;
        state.userIp.data = action.payload;
        state.userIp.isDataLoaded = true;
        state.userIp.isLoading = false;
      })
      .addCase(getUserIp.rejected, (state, action) => {
        state.userIp.isError = action.payload;
        state.userIp.isLoading = false;
      })

      // getSeoDataDefault
      .addCase(getSeoDataDefault.pending, (state) => {
        state.seoDataDefault.isError = null;
        state.seoDataDefault.isLoading = true;
      })
      .addCase(getSeoDataDefault.fulfilled, (state, action) => {
        state.seoDataDefault.isError = null;
        state.seoDataDefault.data = action.payload;
        state.seoDataDefault.isDataLoaded = true;
        state.seoDataDefault.isLoading = false;
      })
      .addCase(getSeoDataDefault.rejected, (state, action) => {
        state.seoDataDefault.isError = action.payload;
        state.seoDataDefault.isLoading = false;
      })

      // getUserChoice
      .addCase(getUserChoice.pending, (state) => {
        state.userChoice.isError = null;
        state.userChoice.isLoading = true;
      })
      .addCase(getUserChoice.fulfilled, (state, action) => {
        state.userChoice.isError = null;
        state.userChoice.data = action.payload;
        state.userChoice.isDataLoaded = true;
        state.userChoice.isLoading = false;
      })
      .addCase(getUserChoice.rejected, (state, action) => {
        state.userChoice.isError = action.payload;
        state.userChoice.isLoading = false;
      })
});

export const ContentReducerActions = {
  setRouteContent,
  setStatistic,
  setActiveFilters,
  setPageContent,
  setCurrentPageContent,
  setClearPageContent,
  setCountries,
  setGoalsByGroups,
  setSellers,
  setReviews,
  setGoals,
  setTopSites,
  setCurrencies,
  setRentPeriods,
  setProxyTypes,
  setActivePageParams,
  setCaptchaConfig,
  setSocials,
  setMainBlockList,
  setSeoDataDefault,
  setUserIp,
  setUserChoice,
  getPageContent,
  getAllCountries,
  getAllGoalGroups,
  getAllTopSellers,
  getLastSiteReviews,
  getAllGoals,
  getTopSites,
  getStatistic,
  getAllProxies,
  getAllCurrencies,
  getAllRentPeriods,
  getCaptchaConfig,
  getMainBlockList,
  getAllSocials,
  getSeoDataDefault,
  getUserIp,
  getUserChoice,
  setProxyTypesMenu
};
export default ContentSlice.reducer;
