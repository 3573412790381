import { memo, useEffect, useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "../../../../../components/ui/Loader/Loader";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { Contacts } from "./Contacts/Contacts";
import { Promocodes } from "./Promocodes/Promocodes";
import { ProxyContentTop } from "./ProxyContentTop/ProxyContentTop";
import { ProxyTabs } from "./ProxyTabs/ProxyTabs";
import { Rating } from "./Rating/Rating";
import { Reviews } from "./Reviews/Reviews";
import { Сharacteristics } from "./Сharacteristics/Сharacteristics";

export const Content = memo(({ stepRating, clickStepRatingHandle }) => {
  // **Props
  const { alias } = useParams();
  const { pathname, hash } = useLocation();
  const [, hrefLang] = useLangUrlDefault();
  const [, lastUrlParam] = pathname.split("/").reverse();
  const { t } = useTranslation();
  const currentTab = lastUrlParam === "promocodes" ? lastUrlParam : (hash.replace("#", "") || "characteristics");
  const navigate = useNavigate();

  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);
  const { site } = useSelector(getAllProxySite);

  // **Local state
  const [, setTokenCaptcha] = useState(null);
  const { enable, clientKey } = captchaConfig.data;

  // **Ref
  const captchaRef = useRef(null);

  const setTab = (newTab = "") => {
    if (newTab === "promocodes") {
      navigate(`${hrefLang}/proxy/${alias}/promocodes/`, { replace: !newTab });
    } else {
      navigate(
        newTab
          ? `${hrefLang}/proxy/${alias}/#${newTab}`
          : `${hrefLang}/proxy/${alias}/#characteristics`,
        { replace: !newTab }
      );
    }
  };

  useEffect(() => {
    if (
      !t("proxySitePage.content.tabs", { returnObjects: true })
        .map((tab) => tab.name)
        .includes(currentTab)
    ) {
      setTab();
    }

    if (!currentTab) {
      setTab();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return !site.isLoading ? (
    <>
      <ProxyContentTop />
      <ProxyTabs currentTab={currentTab} setTab={setTab} hashType />
      {currentTab === "contacts" && <Contacts />}
          {currentTab === "characteristics" && (
            <Сharacteristics setTab={setTab} />
          )}
          {currentTab === "rating" && (
            <Rating
              stepRating={stepRating}
              clickStepRatingHandle={clickStepRatingHandle}
            />
          )}
          {currentTab === "reviews" && (
            <Reviews
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
            />
          )}
      {currentTab === "promocodes" && <Promocodes />}
      {enable && clientKey && (
        <HCaptcha
          ref={captchaRef}
          sitekey={clientKey}
          onVerify={setTokenCaptcha}
          onExpire={() => setTokenCaptcha(null)}
          onError={(err) => toast.error(err?.message)}
          size="invisible"
        />
      )}
    </>
  ) : (
    <Loader />
  );
});
