import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Typography } from "../../../../../components/ui/Typography/Typography";
import { useLangUrlDefault } from "../../../../../hooks";

export const BlogItem = (props) => {
  // **Props
  const { item } = props;
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  return (
    <li className="blog__item">
      {item.popular && (
        <div className="blog__item-labels">
          <span className="blog__item-label">{t("blogPage.item.popular")}</span>
        </div>
      )}
      <div className="blog__item-image">
        <Link to={`${hrefLang}/blog/${item.alias}/`}>
          <img
            src={item.coverImageSmallUrl || "/img/ui/blog-placeholder.svg"}
            width={430}
            height={212}
            loading="lazy"
            alt={item.title}
          />
        </Link>
      </div>
      <div className="blog__item-body">
        <Typography
          size="middle"
          weight="semibold"
          classNames="blog__item-title"
        >
          <Link to={`${hrefLang}/blog/${item.alias}/`}>{item.title}</Link>
        </Typography>
        <div className="blog__item-description">{item.description}</div>
        <div className="blog__item-additional">
          <div className="blog__item-category">
            {t("blogPage.item.categoryTitle")}:{" "}
            <span>
              {item.category
                ? t("blogPage.item.categories.article")
                : t("blogPage.item.categories.news")}
            </span>
          </div>
          <div className="blog__item-feedback">
            {t("blogPage.item.commentsTitle")}:{" "}
            <Link to={`${hrefLang}/blog/${item.alias}/#comments`}>
              {item.commentCount}
            </Link>
          </div>
        </div>
        <div className="blog__item-link">
          <Link to={`${hrefLang}/blog/${item.alias}/`}>
            {t("blogPage.item.readMore")}
          </Link>
        </div>
      </div>
    </li>
  );
};
