import { useTranslation } from "react-i18next";

import "./Translator.scss";

export const Translator = ({ isShowOriginal, showOriginalHandle }) => {
  const { t } = useTranslation();

  return (
    <div className="translator">
      <span className="translator-status">
        {isShowOriginal
          ? t("reviews.translator.status.original")
          : t("reviews.translator.status.translated")}
      </span>
      <button
        className="translator-original button-link"
        type="button"
        onClick={showOriginalHandle}
      >
        {isShowOriginal
          ? t("reviews.translator.translate")
          : t("reviews.translator.showOriginal")}
      </button>
    </div>
  );
};
