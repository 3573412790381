import styled from "@emotion/styled";

export const StyledRLWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    gap: 24px;
  }
`;

export const StyledRLListLabel = styled.div`
  flex: 0 1 120px;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

export const StyledRLList = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  width: 100%;

  color: ${(p) => p.theme.colors["neutral-75"]};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    gap: 36px;
  }
`;

export const StyledRLInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0;
  line-height: 0;
  opacity: 0;
`;

const INDEX_ACTIVE_COLORS = {
  1: "red-500",
  2: "red-500",
  3: "yellow",
  4: "yellow",
  5: "main-600"
};

export const StyledRLLabel = styled.label`
  position: relative;
  font-size: 0;
  cursor: pointer;

  color: ${(p) => {
    if (p.indexActive >= 1) {
      return p.index > p.indexActive
        ? p.theme.colors["neutral-75"]
        : p.theme.colors[`${INDEX_ACTIVE_COLORS[p.indexActive]}`];
    }
    return p.theme.colors["neutral-75"];
  }};

  &:hover,
  &:focus {
    color: ${(p) => {
      if (p.indexActive >= 1) {
        return p.index > p.indexActive
          ? p.theme.colors["neutral-75"]
          : p.theme.colors[`${INDEX_ACTIVE_COLORS[p.indexActive]}`];
      }
      return p.theme.colors["neutral-75"];
    }};
  }

  & svg {
    width: 20px;
    height: 20px;

    @media (min-width: 360px) {
      width: 28px;
      height: 28px;
    }

    @media (min-width: $lg-min) {
      width: 30px;
      height: 30px;
    }
  }
`;
