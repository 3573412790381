import PT from "prop-types";
import { useSelector } from "react-redux";

import { StyledSkeleton } from "../../../ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";

// import { mockSeoData } from "../../../../utils/constants/mockSeoData";

import Container from "../Container/Container";

import { StyledSeoSection, StyledSeoSectionContent } from "./SeoSection.styled";

const SeoSection = ({ content, noPaddingTop }) => {
  // **Redux state
  const { pageContent } = useSelector(getAllContent);

  if (!pageContent?.current?.data?.seo && !content && pageContent?.isDataLoaded) {
    return null;
  }

  return (
    <StyledSeoSection noPaddingTop={noPaddingTop}>
      <Container>
        {pageContent?.isDataLoaded ? (
          <StyledSeoSectionContent
            dangerouslySetInnerHTML={{
              __html: content || pageContent?.current?.data?.seo
            }}
          />
        ) : (
          <>
            <StyledSkeleton
              height={20}
              width={300}
              style={{
                lineHeight: "2"
              }}
            />
            <StyledSkeleton
              height={16}
              count={10}
              style={{
                lineHeight: "2"
              }}
            />
          </>
        )}
      </Container>
    </StyledSeoSection>
  );
};

SeoSection.propTypes = {
  content: PT.string,
  noPaddingTop: PT.bool
};

export default SeoSection;
