import { useEffect, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { animationFaq, animationFaqTools } from "../../../../assets/animations";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import {
  useDispatchedActions,
  useLangUrlDefault,
  useMatchedRoute
} from "../../../../hooks";
import { getAllFAQ } from "../../../../store/reducers/FAQReducer/FAQ.selectors";

import {
  StyledAnimationWrapper,
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledHeading,
  StyledImage,
  StyledList,
  StyledSection
} from "./FAQ.styled";
import FAQCard from "./FAQCard";

const FAQ = ({
  heading,
  description,
  image,
  image2x,
  tools = false,
  className
}) => {
  const { t } = useTranslation();
  const [activeElement, setActiveElement] = useState(0);
  const { data } = useSelector(getAllFAQ);
  const [searchParams] = useSearchParams();
  const fpt = searchParams.get("fpt") || "IPv4";
  const fc = searchParams.get("fc") || null;
  const [queryLang] = useLangUrlDefault();
  const route = useMatchedRoute();
  const { getFaqContent } = useDispatchedActions();

  const handleOpen = (index) => {
    setActiveElement(index);
  };
  const handleClose = () => {
    setActiveElement(null);
  };
  const collapsibleListOfQuestions = (list) =>
    list.map((el, index) => (
      <FAQCard
        handleOpen={
          activeElement === index
            ? () => handleClose()
            : () => handleOpen(index)
        }
        isOpened={activeElement === index}
        key={index}
        title={el.question}
      >
        {typeof el?.answer === "string" ? (
          <div
            itemProp="text"
            dangerouslySetInnerHTML={{ __html: el.answer }}
          />
        ) : (
          el.answer.map((item) => (
            <div itemProp="text" key={item}>
              {item}
            </div>
          ))
        )}
      </FAQCard>
    ));

  const params = {
    languageCode: queryLang,
    pageTag: route?.tag,
    params: {}
  };
  const keys = [route?.tag];
  if (route?.tag === "proxy") {
    if (fpt) {
      params.params.fpt = fpt;
      keys.push(fpt);
    }
    if (fc) {
      params.params.fc = fc;
      keys.push(fc);
    }
  }
  const key = keys.join("-");

  useEffect(() => {
    if (!data?.[queryLang]?.[key]) {
      getFaqContent({ fetchParams: params, key });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, queryLang]);

  if (!data?.[queryLang]?.[key]?.length) {
    return null;
  }

  return (
    <StyledSection className={className}>
      <StyledContainer>
        <StyledContent>
          <StyledHeading>{heading || t("faq.heading")}</StyledHeading>
          <StyledDescription
            dangerouslySetInnerHTML={{
              __html: description || t("faq.description")
            }}
          />
          <StyledAnimationWrapper tools={tools}>
            <Rive
              src={tools ? animationFaqTools : animationFaq}
              autoPlay
              top={tools ? -55 : -67}
              right={tools ? -69 : -97}
              bottom={tools ? -83 : -67}
              left={tools ? -69 : -97}
            />
          </StyledAnimationWrapper>
        </StyledContent>
        <StyledList itemScope itemType="https://schema.org/FAQPage">
          {data?.[queryLang]?.[key]?.length > 0
            ? collapsibleListOfQuestions(data?.[queryLang]?.[key])
            : null}
        </StyledList>
        <StyledImage>
          <source srcSet={`${image} 1x, ${image2x} 2x`} />
          <img
            src={image}
            alt="How we can help you?"
            width="100%"
            loading="lazy"
          />
        </StyledImage>
      </StyledContainer>
    </StyledSection>
  );
};
FAQ.propTypes = {
  description: PT.string,
  heading: PT.string,
  image: PT.node,
  list: PT.arrayOf(
    PT.shape({
      answer: PT.oneOfType([PT.string, PT.arrayOf(PT.string)]),
      question: PT.string
    })
  ),
  tools: PT.bool
};

export default FAQ;
