import { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatchedActions } from "../../../hooks";
import { ApiService } from "../../../services";
import { getAllCabinet } from "../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { Loader } from "../../ui/Loader/Loader";
import { Typography } from "../../ui/Typography/Typography";

import { Form } from "./Form/Form";

import "./Promocodes.scss";

export const Promocodes = (props) => {
  // **Props
  const { title, isEdit } = props;
  const { id } = useParams();
  const { t } = useTranslation();

  // **Redux state
  const { allProxies } = useSelector(getAllCabinet);

  // **Local state
  const [allowToRender, setAllowToRender] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    startDate: new Date(moment.utc().format()),
    endDate: new Date(moment.utc().add(7, "d").format()),
    eternal: false,
    isActive: true
  });

  // Dispatch
  const { getAllCabinetSites } = useDispatchedActions();

  const fetchPromocodeById = useCallback(
    async () => {
      try {
        const response = await ApiService.getPromocodeByIdForPersonalAcc(id);

        if (response && response.status !== 200) {
          throw response;
        }

        setDefaultValues({
          ...response.data,
          startDate: new Date(moment(response.data.startDate)),
          endDate: response.data.endDate
            ? new Date(moment(response.data.endDate))
            : new Date(moment.utc().add(7, "d").format())
        });
      } catch (err) {
        toast.error(t("notifications.apiError"));
      } finally {
        setAllowToRender(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  useEffect(() => {
    if (!allProxies.isDataLoaded) {
      getAllCabinetSites();
      return;
    }

    if (!isEdit) {
      setAllowToRender(true);
    } else {
      fetchPromocodeById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProxies.isDataLoaded]);

  // Handling error
  useEffect(() => {
    if (allProxies.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProxies.isError]);

  return (
    <div className="cabinet__item">
      <Typography Tag="h2" size="middle" weight="semibold">
        {title}
      </Typography>
      {allowToRender ? (
        <Form isEdit={isEdit} defaultValues={defaultValues} />
      ) : (
        <Loader />
      )}
    </div>
  );
};
