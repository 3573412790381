import styled from "@emotion/styled";

import Icon from "../../../../../components/ui/New/Icon/Icon";

export const StyledTable = styled.div`
  position: relative;
  padding: ${(props) =>
    props.paddingSmallScreen ? props.paddingSmallScreen : "24px 16px 0 0"};
  width: 100%;
  overflow-x: auto;
  border-radius: 12px;
  background: white;
  min-height: 150px;

  &::-webkit-scrollbar-button:end:increment {
    width: 2.5%;
    display: block;
    background: transparent;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: ${(props) =>
      props.paddingLargeScreen ? props.paddingLargeScreen : "24px 0 0 0"};
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  display: table;
`;

export const TableBody = styled.div`
  overflow-y: ${(props) => (props.overflow ? props.overflow : "auto")};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "816px")};
  padding-bottom: 16px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.screenSizes.lgMin}) {
    padding-bottom: ${(props) =>
      props.paddingBottom ? props.paddingBottom : "16px"};
  }
`;

export const TableHeadRow = styled.div`
  border-radius: 12px;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "16px")};
  background-color: #f6f7f9;
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 60px;
`;

export const TableHeadCol = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding: ${(props) => (props.padding ? props.padding : "20px 0")};
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  color: ${(props) => props.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;

  @media (min-width: 1530px) {
    width: ${(props) =>
      props.desktopWidth ? props.desktopWidth : props.width};
  }
`;

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : "flex-start"};
  height: ${(props) => (props.height ? props.height : "80px")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "80px")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "16px")};
  cursor: pointer;
  border-bottom: 1px solid rgba(216, 218, 224, 0.5);
  background: none;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(246, 247, 249, 0.6);
    transition: all 0.3s ease;
  }

  &:last-child {
    border-bottom: ${(props) =>
      props.lastRowBorder
        ? props.lastRowBorder
        : "1px solid rgba(216, 218, 224, 0.5)"};
  }
`;

export const TableCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  box-sizing: border-box;
  word-break: break-word;
  width: ${(props) => (props.width ? props.width : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.width)};
  height: 100%;
  padding: ${(props) => (props.padding ? props.padding : "12px 0")};
  align-items: center;

  @media (min-width: 1530px) {
    width: ${(props) =>
      props.desktopWidth ? props.desktopWidth : props.width};
  }
`;

export const TableText = styled.span`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  width: ${(props) => (props.width ? props.width : "")};
  gap: 8px;
  flex-shrink: 0;
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : ""};
`;

export const StyledIcon = styled(Icon)`
  visibility: hidden;
  color: ${(props) => props.theme.colors["neutral-400"]};
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:focus,
  &:active {
    color: ${(props) => props.theme.colors["main-700"]};
    transition: all 0.3s ease;
  }
`;

export const StyledIpAddressCol = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &:hover {
    svg {
      visibility: visible !important;
      transition: all 0.3s ease;
    }
  }
`;

export const StyledCountryCol = styled.div`
  display: flex;
  align-items: center;

  & span {
    max-width: 130px;
    word-break: break-word;
  }
`;

export const StyledFlag = styled.span`
  width: 22px;
  height: 16px;
  margin-right: 8px;
  flex-shrink: 0;
`;

export const StyledAnonymityCol = styled.div`
  flex-direction: column;
  align-items: flex-start !important;
  padding-top: 14px;
`;

export const StyledAnonymityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAnonymity = styled.div`
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

export const StyledSpeed = styled.span`
  color: ${(props) => props.theme.colors["neutral-500"]};
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
  margin-right: 8px;
`;

export const StyledSpeedNumber = styled.span`
  color: ${(props) => props.theme.colors["neutral-800"]};
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
  background: #f1f1f3;
  border-radius: 8px;
  padding: 4px 8px;
`;

export const StyledNoData = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
`;

export const StyledPersonalProxy = styled.div`
  display: flex;
  align-items: flex-start;

  & span {
    max-width: 480px;
    word-break: break-word;
  }
`;

export const StyledCheckIcon = styled.img`
  margin-right: 12px;
`;
