import { useId } from "react";

import PT from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "../../../../../components/common/New/Progress/Progress";
import { StyledLoading } from "../../../../../components/ui/New/Button/Button.styled";
import Icon from "../../../../../components/ui/New/Icon/Icon";
import Tooltip from "../../../../../components/ui/New/Tooltip/Tooltip";

import AnonymityStatus from "./AnonymityStatus/AnonymityStatus";
import {
  Country,
  Flag,
  Info,
  Inner,
  IpAddress,
  IpAddressWrapper,
  IpBox,
  IpTitle,
  IpWrapper,
  RestyledButton,
  RestyledContainer,
  ResultsWrapper,
  StyledCopyIcon,
  StyledSubtitle,
  StyledTitle
} from "./CheckAnonymity.styled";
import { ResultsTable } from "./ResultTable/ResultTable";

export const CheckAnonymity = ({
  userIp,
  isLoading = false,
  result,
  handleClick
}) => {
  const { t } = useTranslation();

  const customToastId = useId();
  const tooltipId = useId();

  const onCopyHandler = () => {
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  return (
    <RestyledContainer>
      <Inner>
        <Info>
          <IpWrapper>
            {userIp?.ipAddress ? (
              <>
                <IpBox>
                  <IpTitle>{t("myAnonymity.myIP")}</IpTitle>
                  <IpAddressWrapper>
                    {userIp?.countryA2 && (
                      <Flag
                        className={`fl fl-${userIp?.countryA2?.toLowerCase()}`}
                      />
                    )}
                    {userIp?.ipAddress?.length > 24 ? (
                      <Tooltip
                        id={tooltipId}
                        body={userIp?.ipAddress}
                        place="top-start"
                      >
                        <IpAddress overflow>{userIp?.ipAddress}</IpAddress>
                      </Tooltip>
                    ) : (
                      <IpAddress>{userIp?.ipAddress}</IpAddress>
                    )}

                    <CopyToClipboard
                      text={userIp?.ipAddress}
                      onCopy={onCopyHandler}
                    >
                      <StyledCopyIcon name="copyAlt" size="lg" />
                    </CopyToClipboard>
                  </IpAddressWrapper>
                </IpBox>
                {userIp?.country && (
                  <Country>
                    <Icon name="marker" size="lg" />
                    <span>
                      {userIp?.city ? `${userIp?.city},` : ""} {userIp?.country}
                    </span>
                  </Country>
                )}
              </>
            ) : (
              <StyledLoading name="loading" />
            )}
          </IpWrapper>
          {result && !isLoading ? (
            <AnonymityStatus value={60} />
          ) : (
            <>
              <StyledTitle>{t("myAnonymity.title")}</StyledTitle>
              <StyledSubtitle>{t("myAnonymity.subtitle")}</StyledSubtitle>
              <RestyledButton type="button" onClick={handleClick} fullWidth>
                {t("myAnonymity.buttonLabel")}
              </RestyledButton>
            </>
          )}
        </Info>
        <ResultsWrapper isLoading={isLoading}>
          {isLoading && <Progress isLoading={isLoading} />}
          {result && !isLoading ? <ResultsTable data={userIp} /> : null}
        </ResultsWrapper>
      </Inner>
    </RestyledContainer>
  );
};

CheckAnonymity.propTypes = {
  userIp: PT.shape({}),
  isLoading: PT.bool,
  result: PT.shape({}),
  handleClick: PT.func.isRequired
};
