import { useEffect } from "react";

import { useSelector } from "react-redux";

import { getAllSeoBlock } from "../store/reducers/SeoBlockReducer/SeoBlock.selectors";

import { useDispatchedActions } from "./useDispatchedActions";
import { useLangUrlDefault } from "./useLangUrlDefault";

export const useSeoBlock = (keys) => {
  const [lang] = useLangUrlDefault();
  const { fetchSeoBlocks } = useDispatchedActions();
  const { loading, errors, data, loadingKeys } = useSelector(getAllSeoBlock);

  useEffect(() => {
    const alreadyInProcess = keys.some((key) => loadingKeys.includes(key));

    if (!alreadyInProcess) {
      const notExistKeys = keys.filter(
        (key) => !data[lang]?.hasOwnProperty(key)
      );

      if (notExistKeys?.length) {
        fetchSeoBlocks({ keys: notExistKeys, languageCode: lang });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys, lang, data, loadingKeys]);

  const searchedKeys = keys.reduce((acc, key) => {
    if (!data[lang] || !data[lang].hasOwnProperty(key)) {
      return acc;
    }
    return {
      ...acc,
      [key]: data[lang][key]
    };
  }, {});

  return {
    loading,
    data: searchedKeys,
    errors
  };
};
