import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getFaqContent = createAsyncThunk(
  "faq/getFaqContent",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getFaqContent(params.fetchParams);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        data: response.data,
        pageTag: params.key,
        languageCode: params.fetchParams.languageCode
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setFaqContent = createAction("faq/setFaqContent");
