import { useTranslation } from "react-i18next";

import { Promocodes } from "../../../../../../components/common";

export const PromocodesEdit = () => {
  // **Redux state
  const { t } = useTranslation();

  return <Promocodes title={t("cabinetPages.promocodes.edit.title")} isEdit />;
};
