import styled from "@emotion/styled";

import Button from "../Button/Button";

export const StyledButton = styled(Button)`
  height: 44px !important;
  padding: 12px 24px !important;
  color: ${(props) =>
    props.color === "dark"
      ? "white !important"
      : `${props.theme.colors["neutral-800"]} !important`};
  outline: ${(props) =>
    props.color === "dark"
      ? "1px solid #ffffff40 !important"
      : "1px solid rgba(42, 42, 45, 0.65) !important"};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    max-width: 15vw;
    min-width: 120px;
    > div {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  @media (min-width: 1250px) {
    max-width: none;
    > div {
      display: flex;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) =>
      props.color === "dark" ? "#ffffff14 !important" : "white !important"};
    color: ${(props) =>
      props.color === "dark"
        ? "white !important"
        : `${props.theme.colors["neutral-800"]} !important`}
    outline: ${(props) =>
      props.color === "dark"
        ? "1px solid #ffffff40 !important"
        : `1px solid ${props.theme.colors["main-600"]} !important`}
  }

  > svg {
    width: 20px;
    height: 20px;
  }
`;
