export const getGoalsById = (goalsIds, allGoals) => {
  const goalsArray = [];

  if (goalsIds?.length === 0 || allGoals?.length === 0) {
    return goalsArray;
  }

  goalsIds.forEach((goalId) => {
    allGoals.forEach((goal) => {
      if (goalId === goal.id) {
        goalsArray.push(goal);
      }
    });
  });

  return goalsArray;
};
