export const browserInfoTableData = (data, info, t) => [
  {
    src: "img/icons/os.svg",
    alt: t("browserInformation.table.os"),
    label: t("browserInformation.table.os"),
    value: data?.platform
  },
  {
    src: "img/icons/marker-pin.svg",
    alt: t("browserInformation.table.ip"),
    label: t("browserInformation.table.ip"),
    value: data?.ipAddress
  },
  {
    src: "img/icons/monitor.svg",
    alt: t("browserInformation.table.cms"),
    label: t("browserInformation.table.cms"),
    value: data?.app
  },
  {
    src: "img/icons/translate.svg",
    alt: t("browserInformation.table.language"),
    label: t("browserInformation.table.language"),
    value: info.browserLanguage
  },
  {
    src: "img/icons/user.svg",
    alt: t("browserInformation.table.userAgent"),
    label: t("browserInformation.table.userAgent"),
    value: data?.userAgent
  },
  {
    src: "img/icons/server.svg",
    alt: t("browserInformation.table.application"),
    label: t("browserInformation.table.application"),
    value: info.appVersion
  }
];
