import { createSlice } from "@reduxjs/toolkit";

import { siteInitialState } from "./Site.initialState";
import {
  setBurgerMenuReducer,
  setMobileMenuReducer,
  setPreviousLangReducer,
  setServiceMenuReducer
} from "./Site.reducer";
import {
  setBurgerMenu,
  setMobileMenu,
  setPreviousLang,
  setServiceMenu
} from "./Site.thunks";

const SiteSlice = createSlice({
  name: "site",
  initialState: siteInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setMobileMenu, setMobileMenuReducer)
      .addCase(setBurgerMenu, setBurgerMenuReducer)
      .addCase(setServiceMenu, setServiceMenuReducer)
      .addCase(setPreviousLang, setPreviousLangReducer)
});

export const SiteReducerActions = {
  setMobileMenu,
  setBurgerMenu,
  setServiceMenu,
  setPreviousLang
};
export default SiteSlice.reducer;
