import PT from "prop-types";

import { StyledBoxCell } from "./BoxCell.styled";

export const BoxCell = ({ children, minWidth, width, maxWidth, className }) => {
  const helperWidth = (width) => (width ? width - 16 : null);
  return (
    <StyledBoxCell
      propMinWidth={helperWidth(minWidth)}
      propWidth={helperWidth(width)}
      propMaxWidth={helperWidth(maxWidth)}
      className={className}
    >
      {children}
    </StyledBoxCell>
  );
};

BoxCell.propTypes = {
  className: PT.string,
  children: PT.node,
  minWidth: PT.number,
  width: PT.number,
  maxWidth: PT.number
};
