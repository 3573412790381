import i18n from "i18next";

const normalizeLanguageCode = () => {
  const languageCode = i18n.language;
  const parts = languageCode.split("-");
  return parts[0];
};

export const useLangUrlDefault = () => {
  const lang = normalizeLanguageCode();

  if (lang !== "en") {
    return [lang, `/${lang}`];
  }

  return [lang, ""];
};
