import PT from "prop-types";

import { StyledTextCell } from "./TextCell.styled";

export const TextCell = ({ className, children }) => (
  <StyledTextCell className={className}>{children}</StyledTextCell>
);

TextCell.propTypes = {
  className: PT.string,
  children: PT.node
};
