import { createSlice } from "@reduxjs/toolkit";

import { comparisonInitialState } from "./Comparison.initialState";
import {
  clearComparisonStateReducer,
  deleteComparisonSiteReducer,
  resetFullSitesReducer,
  setComparisonWidgetInfoReducer,
  setFullSitesReducer,
  setPrevSitesReducer
} from "./Comparison.reducer";
import {
  clearComparisonState,
  deleteComparisonSite,
  getComparisonWidgetInfo,
  resetFullSites,
  setComparisonWidgetInfo,
  setFullSites,
  setPrevSites,
  updateComparsionSites
} from "./Comparison.thunks";

const ComparisonSlice = createSlice({
  name: "comparison",
  initialState: comparisonInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setFullSites, setFullSitesReducer)
      .addCase(resetFullSites, resetFullSitesReducer)
      .addCase(setPrevSites, setPrevSitesReducer)
      .addCase(deleteComparisonSite, deleteComparisonSiteReducer)
      .addCase(clearComparisonState, clearComparisonStateReducer)
      .addCase(setComparisonWidgetInfo, setComparisonWidgetInfoReducer)

      // getComparisonWidgetInfo
      .addCase(getComparisonWidgetInfo.pending, (state) => {
        state.isError = null;
        state.isLoading = true;
      })
      .addCase(getComparisonWidgetInfo.fulfilled, (state, action) => {
        state.isError = null;
        state.uuid = action.payload?.uuid;
        state.sites = action.payload?.siteList;
        state.isDataLoaded = true;
        state.isLoading = false;
      })
      .addCase(getComparisonWidgetInfo.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = false;
      })

      // updateComparsionSites
      .addCase(updateComparsionSites.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(updateComparsionSites.fulfilled, (state, action) => {
        state.uuid = action.payload?.uuid;
        state.sessionId = action.payload?.sessionId;
        state.sites = action.payload?.siteList;
        if (action.payload?.siteList?.length === 0) {
          state.uuid = "";
          state.sessionId = "";
          state.sites = [];
          state.prevSites = [];
          state.sitesFull = [];
        }
        state.isDeleting = false;
      })
      .addCase(updateComparsionSites.rejected, (state) => {
        state.isDeleting = false;
      })
});

export const ComparisonReducerActions = {
  setFullSites,
  resetFullSites,
  setPrevSites,
  deleteComparisonSite,
  clearComparisonState,
  setComparisonWidgetInfo,
  getComparisonWidgetInfo,
  updateComparsionSites
};
export default ComparisonSlice.reducer;
