export const translationInitialState = {
  lang: "en",
  translations: {
    en: "EN",
    ru: "RU",
    ua: "UA"
  },
  languages: [
    {
      value: "en",
      label: "English"
    },
    {
      value: "ru",
      label: "Россия"
    },
    {
      value: "ua",
      label: "Україна"
    }
  ],
  nearest: ["az", "hy", "be", "kk", "ky", "ru", "tg", "uz", "uk"],
  isDataLoaded: false
};
