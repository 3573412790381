import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../hooks";

import { BreadcrumbsItemStyled } from "./Breadcrumbs.styled";

export const BreadcrumbsItem = ({ item }) => {
  const [, hrefLang] = useLangUrlDefault();

  return (
    <BreadcrumbsItemStyled>
      {item.route ? (
        <>
          <Link to={`${hrefLang}/${item.route}`}>{item.title}</Link>
          <span>/</span>
        </>
      ) : (
        item.title
      )}
    </BreadcrumbsItemStyled>
  );
};
