import { useTranslation } from "react-i18next";

import animation from "../../../../assets/animations/http_headers_check.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  httpHeadersCheckerHeroImageMobile
} from "../../../../assets/img";
import SeoSection from "../../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";

import { HttpHeaderCheck } from "./HttpHeaderCheck/HttpHeaderCheck";
import {
  RestyledMainSection,
  StyledFAQSection,
  StyledSection
} from "./HttpHeadersCheckerPage.styled";

const HttpHeadersCheckerPage = () => {
  const { t } = useTranslation();
  return (
    <StyledSection>
      <RestyledMainSection
        title={t("httpHeadersChecker.main.title")}
        subtitle={t("httpHeadersChecker.main.subtitle")}
        button={false}
        images={{
          mobile1x: httpHeadersCheckerHeroImageMobile,
          mobile2x: httpHeadersCheckerHeroImageMobile
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-84}
            right={-87}
            bottom={-112}
            left={-86}
          />
        }
      />
      <HttpHeaderCheck />
      <StyledFAQSection
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </StyledSection>
  );
};

export default HttpHeadersCheckerPage;

HttpHeadersCheckerPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
