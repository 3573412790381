import { memo, useState } from "react";

import { useSelector } from "react-redux";

import { ProxyModal } from "../../../../../../components/common";
// import { useMetaData } from "../../../../../../hooks";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";

export const ProxyContentTop = memo(() => {
  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);

  // // **Local state
  // const metaData = useMetaData();

  // **Local state
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <div className="proxy-content__top">
        <div className="proxy-content__top-title">
          <h1>
            {current?.data?.h1}
            {/*{t.proxyPage.content.top.title.main}{" "}*/}
            {/*{searchParams.get("fc") && (*/}
            {/*  <>*/}
            {/*    {t.proxyPage.content.top.title.divider}{" "}*/}
            {/*    <span>*/}
            {/*      {getCountryNameByUrlParam(*/}
            {/*        searchParams.get("fc"),*/}
            {/*        countries.data,*/}
            {/*        t.countries*/}
            {/*      )}*/}
            {/*    </span>*/}
            {/*  </>*/}
            {/*)}*/}
          </h1>
        </div>
        {/* Функционал "Запрос на прокси" временно скрыт */}
        {/*<Button type="transparent" onClick={() => setIsModalOpened(true)}>
          {t.proxyPage.content.top.proxy}
        </Button>*/}
      </div>
      <ProxyModal visible={isModalOpened} cancelHandler={setIsModalOpened} />
    </>
  );
});
