import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../../../hooks";

import { getAllBlogInner } from "../../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

export const ArticleNavigation = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <ul className="blog-inner__navigation">
      {content.data.previousArticleAlias && (
        <li className="blog-inner__navigation-item blog-inner__navigation-item--prev">
          <Link to={`${hrefLang}/blog/${content.data.previousArticleAlias}/`}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M4.70864 9.0099L13.3083 0.410484C13.8554 -0.136826 14.7423 -0.136826 15.2891 0.410484C15.8359 0.957306 15.8359 1.8442 15.2891 2.39098L7.6797 10.0001L15.2889 17.609C15.8357 18.1561 15.8357 19.0429 15.2889 19.5897C14.7421 20.1368 13.8552 20.1368 13.3081 19.5897L4.70842 10.9902C4.435 10.7166 4.29845 10.3585 4.29845 10.0002C4.29845 9.6417 4.43527 9.28331 4.70864 9.0099Z" />
              </g>
            </svg>
            <span>{content.data.previousArticleTitle}</span>
          </Link>
        </li>
      )}
      {content.data.nextArticleAlias && (
        <li className="blog-inner__navigation-item blog-inner__navigation-item--next">
          <Link to={`${hrefLang}/blog/${content.data.nextArticleAlias}/`}>
            <span>{content.data.nextArticleTitle}</span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M4.70864 9.0099L13.3083 0.410484C13.8554 -0.136826 14.7423 -0.136826 15.2891 0.410484C15.8359 0.957306 15.8359 1.8442 15.2891 2.39098L7.6797 10.0001L15.2889 17.609C15.8357 18.1561 15.8357 19.0429 15.2889 19.5897C14.7421 20.1368 13.8552 20.1368 13.3081 19.5897L4.70842 10.9902C4.435 10.7166 4.29845 10.3585 4.29845 10.0002C4.29845 9.6417 4.43527 9.28331 4.70864 9.0099Z" />
              </g>
            </svg>
          </Link>
        </li>
      )}
    </ul>
  );
};
