import { createSelector } from "@reduxjs/toolkit";

import { getAllContent } from "../ContentReducer/Content.selectors";
import { getAllTranslation } from "../TranslationReducer/Translation.selectors";

export const getAllProxySite = (state) => state.proxysite;

export const selectProxySitesType = createSelector(
  [getAllTranslation, getAllContent, getAllProxySite],
  (allTranslation, allContent, allProxySite) => {
    const { lang } = allTranslation;
    const { proxyTypes } = allContent;
    const { proxySitesType } = allProxySite;

    // **Props
    const proxyTypesLang = proxyTypes.isDataLoaded ? proxyTypes.data?.[lang] : [];

    if (proxyTypes.isDataLoaded && !proxySitesType.isDataLoaded) {
      return proxyTypesLang.map((it) => ({
        [it.type]: {
          totalElements: 0,
          totalPages: 0,
          content: []
        }
      }));
    }

    return proxySitesType.data;
  }
);
