export const setCabinetProxiesPrevParamsReducer = (state, action) => {
  state.proxies.prevParams = action.payload;
};

export const setCabinetPromocodesPrevParamsReducer = (state, action) => {
  state.promocodes.prevParams = action.payload;
};

export const setCabinetSiteStatusesRecucer = (state, action) => {
  state.siteStatuses.data = action.payload;
  state.siteStatuses.isDataLoaded = true;
};

export const resetCabinetReducer = (state) => {
  state.proxies = {
    data: {
      content: [],
      totalElements: 0,
      totalPages: 0
    },
    isLoading: false,
    isError: null,
    prevParams: null
  };

  state.allProxies = {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  };

  state.promocodes = {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  };
};
