import { useTranslation } from "react-i18next";

import animation from "../../../assets/animations/ipv4.riv";
import {
  ipv4FaqImage,
  ipv4FaqImage2x,
  ipv4HeroImageMobile,
  ipv4HeroImageMobile2x
} from "../../../assets/img";
import FAQ from "../../../components/common/New/FAQ/FAQ";
import HowToBuy from "../../../components/common/New/HowToBuy/HowToBuy";
import SeoSection from "../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/New/Rive/Rive";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { getCountryId, getGoalId } from "../../../utils/helpers";
import { scrollToWithOffset } from "../../../utils/helpers/scroll.helper";

import { Proxy } from "./Proxy/Proxy";
import { RestyledMainSection } from "./ProxyPage.styled";
import { SpecialOffer } from "./SpecialOffer/SpecialOffer";

const ProxyPage = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    scrollToWithOffset("proxyContent", -50);
  };

  return (
    <>
      <RestyledMainSection
        subtitle={t("proxyPage.specialOffer.description")}
        buttonLabel={t("navigation.selectService")}
        images={{
          mobile1x: ipv4HeroImageMobile,
          mobile2x: ipv4HeroImageMobile2x
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-21}
            right={-18}
            bottom={-115}
            left={-18}
          />
        }
        handleButtonClick={handleButtonClick}
      />
      <SpecialOffer />
      <Proxy />
      <HowToBuy />
      <FAQ image={ipv4FaqImage} image2x={ipv4FaqImage2x} />
      <SeoSection />
    </>
  );
};

export default ProxyPage;

ProxyPage.getServerSideState = async (store, params) => {
  const fptParams = params.params.fpt || "IPv4";
  const { data: pageContent } = await $api.post("/api/page/front/content", {
    ...params,
    languageCode: params.locale,
    location: params.params.fpt
      ? params.location
      : `${params.location}?fpt=IPv4`,
    params: {
      ...params.params,
      fpt: Array.isArray(fptParams)
        ? fptParams[fptParams?.length - 1]
        : fptParams || null
    }
  });

  const {
    content: { countries, goals }
  } = store.getState();

  const fetchParams = {
    proxyType: Array.isArray(fptParams) ? fptParams : [fptParams],
    country: getCountryId(countries.data, params?.params?.fc)?.id || "",
    sort: params?.params?.s || "",
    goal: getGoalId(goals.data, params?.params?.fg)?.id || "",
    useNew: true,
    top: true,
    page: 0,
    size: 10
  };
  const { data: sites } = await $api.post("/api/front/site/proxy", fetchParams);
  const fetchFaqsParams = {
    pageTag: params.tag,
    languageCode: params.locale,
    params: {}
  };
  const keys = [params.tag];
  if (params.params.fpt) {
    fetchFaqsParams.params.fpt = params.params.fpt;
    keys.push(params.params.fpt);
  }
  if (params.params.fc) {
    fetchFaqsParams.params.fc = params.params.fc;
    keys.push(params.params.fc);
  }
  const { data: faqData } = await $api.post("/api/front/faqs", fetchFaqsParams);

  const { data: specialPromocodes } = await $api.get(
    "/api/front/promocode/special"
  );

  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: [
      "specialOffer",
      "proxyPageProxyTable",
      "howToBuy",
      "infoAboutProxyType"
    ]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );
  store.dispatch(
    AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
  );
  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.locale,
      innerTag: params.location
    })
  );

  store.dispatch(AllActions.setSpecialPromocodes(specialPromocodes));
  store.dispatch(AllActions.setProxySites({ ...sites, fetchParams }));

  if (faqData) {
    store.dispatch(
      AllActions.setFaqContent({
        data: faqData,
        languageCode: params.locale,
        pageTag: keys.join("-")
      })
    );
  }
};
