import "moment/locale/ru";
import "moment/locale/uk";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { ReadMore } from "../../../../../components/ui/New/ReadMore/ReadMore";

import { useLangUrlDefault } from "../../../../../hooks";

import {
  BlogItemWrapper,
  Category,
  Content,
  Date,
  DateWrapper,
  Description,
  ImageWrapper,
  StyledTitle
} from "../Blog.styled";

export const BlogItem = ({ data }) => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  return (
    <BlogItemWrapper>
      <ImageWrapper>
        <Link to={`${hrefLang}/blog/${data.alias}/`}>
          <picture>
            <source
              srcSet={data.coverImageUrl || "/img/ui/blog-placeholder.svg"}
              media="(min-width: 993px)"
            />
            <source
              srcSet={data.coverImageSmallUrl || "/img/ui/blog-placeholder.svg"}
              media="(max-width: 992px)"
            />
            <img src={data.coverImageUrl} loading="lazy" alt={data.title} />
          </picture>
        </Link>
      </ImageWrapper>
      <Content>
        <div>
          <Category>
            {data.category
              ? t("blogPage.item.categories.article")
              : t("blogPage.item.categories.news")}
          </Category>
          <StyledTitle tag="h2" size="sm">
            <Link to={`${hrefLang}/blog/${data.alias}/`}>{data.title}</Link>
          </StyledTitle>
          <Description>{data.description}</Description>
        </div>
        <DateWrapper>
          <Date suppressHydrationWarning>
            {data?.creationDate && (
              <Moment format="MMMM DD, YYYY" locale={queryLang === "ua" ? "uk" : queryLang}>
                {data.creationDate}
              </Moment>
            )}
          </Date>
          <Link to={`${hrefLang}/blog/${data.alias}/`}>
            <ReadMore variant="dark" />
          </Link>
        </DateWrapper>
      </Content>
    </BlogItemWrapper>
  );
};

BlogItem.propTypes = {
  data: PT.shape({})
};
