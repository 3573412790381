import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Container from "../../../../../../components/common/New/Container/Container";
import { useSeoBlock } from "../../../../../../hooks";
import { parseSeoData } from "../../../../../../utils/helpers";

import { StyledSection, StyledTitle } from "./PortScannerSlider.styled";
import { PortScannerSliderItem } from "./PortScannerSliderItem/PortScannerSliderItem";

export const PortScannerSlider = () => {
  const { data } = useSeoBlock(["toolsPortScanerWhatIsIt"]);

  if (!data?.toolsPortScanerWhatIsIt?.showOnFront) return null;

  const list = parseSeoData(data?.toolsPortScanerWhatIsIt?.list) || [];
  return (
    <StyledSection>
      <Container>
        <StyledTitle tag="h2">
          {data?.toolsPortScanerWhatIsIt?.title?.content}
        </StyledTitle>
      </Container>
      <Swiper
        className="port-scanner-slider"
        direction="horizontal"
        modules={[Navigation]}
        navigation={true}
        wrapperTag="ul"
        breakpoints={{
          360: {
            slidesPerView: 1.1,
            spaceBetween: 12
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 12
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24
          },
          993: {
            slidesPerView: 2.5,
            spaceBetween: 24
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 24
          },
          1500: {
            slidesPerView: 3.5,
            spaceBetween: 24
          },
          1700: {
            slidesPerView: 4,
            spaceBetween: 24
          }
        }}
      >
        {list?.length > 0
          ? list.map((item) => (
              <SwiperSlide key={item.title} tag="li">
                <PortScannerSliderItem {...item} />
              </SwiperSlide>
            ))
          : null}
      </Swiper>
    </StyledSection>
  );
};
