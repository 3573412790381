import { useEffect, useRef, useState } from "react";

import { useOnClickOutside } from "../../../../hooks";

export const TabDropdown = ({ tabs, currentTab, onTabClick }) => {
  // **Local state
  const [isOpened, isSetOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(
    tabs.find((tab) => tab.name === currentTab)
  );

  // **Ref
  const dropdownRef = useRef();

  const dropdownHandler = (status) => {
    isSetOpened(status);
  };

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
    onTabClick(tab.name);
    dropdownHandler(false);
  };

  useEffect(() => {
    setActiveTab(tabs.find((tab) => tab.name === currentTab));
  }, [currentTab, tabs]);

  // Hooks
  useOnClickOutside(dropdownRef, () => dropdownHandler(false));

  return (
    <div
      ref={dropdownRef}
      className={
        isOpened ? "tabs__dropdown tabs__dropdown--active" : "tabs__dropdown"
      }
    >
      <button
        className="tabs__dropdown-current"
        onClick={() => dropdownHandler(!isOpened)}
      >
        {activeTab?.title}
      </button>
      <div className="tabs__dropdown-list">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={tab.name === currentTab ? "active" : ""}
            onClick={() => tabClickHandler(tab)}
          >
            {tab.title}
          </button>
        ))}
      </div>
    </div>
  );
};
