export const comparisonInitialState = {
  uuid: "",
  sessionId: "",
  isOwner: false,
  isDeleting: false,
  sites: [],
  prevSites: [],
  sitesFull: [],
  isError: false,
  isLoading: false,
  isDataLoaded: false
};
