import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getComparisonWidgetInfo = createAsyncThunk(
  "comparison/getComparisonWidgetInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getComparisonWidgetInfo();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const updateComparsionSites = createAsyncThunk(
  "comparison/updateComparsionSites",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.updateComparsionSites(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setFullSites = createAction("comparison/setFullSites");
export const resetFullSites = createAction("comparison/resetFullSites");
export const setPrevSites = createAction("comparison/setPrevSites");
export const deleteComparisonSite = createAction(
  "comparison/deleteComparisonSite"
);
export const clearComparisonState = createAction(
  "comparison/clearComparisonState"
);
export const setComparisonWidgetInfo = createAction(
  "comparison/setComparisonWidgetInfo"
);
