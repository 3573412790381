import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import "./Validation.scss";

export const Validation = ({
  domainName,
  validationToken,
  validationFileUrl,
  validationMetaTag,
  validationNextDate
}) => {
  const { t } = useTranslation();

  const accordionClickHandler = (evt) => {
    const accordion = evt.currentTarget;
    const panel = accordion.nextElementSibling;

    accordion.classList.toggle("active");

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = `${panel.scrollHeight}px`;
    }
  };

  const generateFileHandler = (evt) => {
    evt.preventDefault();

    const file = new File([validationToken], `${validationToken}.txt`, {
      type: "text/plain;charset=utf-8"
    });
    saveAs(file);
  };

  return (
    <div className="proxy-validation">
      <button
        className="proxy-validation__accordion"
        onClick={accordionClickHandler}
      >
        {t("validation.invalid.report")}
      </button>
      <div className="proxy-validation__panel">
        <div className="proxy-validation__content">
          <h3 className="proxy-validation__content-title">
            {t("validation.invalid.panel.title")} &laquo;{domainName}&raquo;
          </h3>
          <p className="proxy-validation__content-notice">
            {t("validation.invalid.panel.notice")}
          </p>
          <h4 className="proxy-validation__content-subtitle">
            {t("validation.invalid.panel.subtitle")}
          </h4>
          <p>
            {t("validation.invalid.panel.link.title")}{" "}
            <a onClick={generateFileHandler}>
              <span>{t("validation.invalid.panel.link.linkName")}</span>
            </a>
            !
          </p>
          <p>{t("validation.invalid.panel.link.example")}</p>
          <pre>
            <code>{validationFileUrl}</code>
          </pre>
          <p>{t("validation.invalid.panel.metag.title")}</p>
          <pre>
            <code>
              {`<html>
    <head>
        ${validationMetaTag}
    </head>
</html>`}
            </code>
          </pre>
          <p>
            <b>{t("validation.invalid.panel.nextData.title")}</b>{" "}
            <Moment format="DD.MM.YYYY HH:mm">
              {new Date(validationNextDate)}
            </Moment>
          </p>
        </div>
      </div>
    </div>
  );
};
