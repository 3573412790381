import PT from "prop-types";

import { StyledIsActiveCell } from "./IsActiveCell.styled";

export const IsActiveCell = ({ isActive = false }) => (
  <StyledIsActiveCell isActive={isActive} />
);

IsActiveCell.propTypes = {
  isActive: PT.bool
};
