import styled from "@emotion/styled";

export const StyledNewCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${(p) => p.theme.colors["main-400"]};
  padding: 8px;

  color: ${(p) => p.theme.colors["neutral-800"]};
  font-size: ${(p) => p.theme.fontSizes.xxs};
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
`;
