import { time } from "./time.helper";

export const transformMinRent = (periods, lang, t) => {
  const options = [];

  if (periods?.length === 0) {
    return options;
  }

  periods
    .filter((period) => period.periodValue)
    .forEach((period) => {
      options.push({
        id: period.id,
        label: time(period.periodName, period.periodValue, lang, t),
        value: period.id,
        time: period.periodMilliseconds
      });
    });

  return options;
};
