import { useState } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Eye, EyeInvisible } from "../../icons";

import "./Input.scss";

export const Input = ({
  name,
  type,
  margin,
  rules,
  label,
  layout,
  showError,
  ...rest
}) => {
  // **Redux state
  const { t } = useTranslation();

  // **Local state
  const [isPassVisible, setIsPassVisible] = useState(false);

  const {
    register,
    formState: { errors }
  } = useFormContext();

  const defineClassname = () => {
    let className = "form-input__item";

    switch (layout) {
      case "vertical": {
        className += " form-input__item--layout-vertical";
        break;
      }
      case "horizontal": {
        className += " form-input__item--layout-horizontal";
        break;
      }
      default: {
        break;
      }
    }

    switch (margin) {
      case "mb-large": {
        className += " form-input__item--margin-bottom-large";
        break;
      }
      default: {
        break;
      }
    }

    if (type === "password") {
      className += " form-input__item--password";
    }

    if (errors[name]) {
      className += " form-input__item--error";
    }
    return className;
  };

  const defineInputType = () => {
    let inputType = type;

    if (type === "password" && isPassVisible) {
      inputType = "text";
    }

    return inputType;
  };

  const changePassVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  return (
    <div className={defineClassname()}>
      <label>
        {label && <span className="form-input__label">{label}</span>}
        <div className="form-input__input">
          <input
            type={defineInputType()}
            {...register(name, rules)}
            {...rest}
          />
          {type === "password" && (
            <button
              type="button"
              className="form-input__visibility"
              onClick={changePassVisibility}
              aria-label={
                isPassVisible
                  ? t("forms.password.state.hide")
                  : t("forms.password.state.show")
              }
            >
              {isPassVisible ? <EyeInvisible /> : <Eye />}
            </button>
          )}
        </div>
      </label>
      {showError && errors[name]?.message && (
        <div className="form-input__error">{errors[name]?.message}</div>
      )}
    </div>
  );
};
