export const promocodesInitialState = {
  special: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  },
  promocodes: {
    data: {
      totalPages: 0,
      totalElements: 0,
      content: []
    },
    isLoading: false,
    isError: null,
    prevParams: null
  },
  promocode: {
    data: {
      alternatePromocodes: [],
      description: null,
      endDate: null,
      eternal: false,
      id: "",
      imageUrl: "",
      isActive: true,
      promocode: "",
      siteId: "",
      siteName: "",
      startDate: ""
    },
    isLoading: false,
    isError: null
  },
  alternatePromocodes: {
    data: [],
    isLoading: false,
    isError: null,
    isDataLoaded: false
  }
};
