export const time = (keyword, value, lang, t) => {
  // Time for RU lang
  if (lang === "ru") {
    if (value === 0) {
      return `${value} ${t?.[keyword]?.[2]}`;
    }

    if (value === 1) {
      return `${value} ${t?.[keyword]?.[0]}`;
    }

    if (value >= 2 && value <= 4) {
      return `${value} ${t?.[keyword]?.[1]}`;
    }

    if (value > 4) {
      return `${value} ${t?.[keyword]?.[2]}`;
    }
  }

  // Time for UA lang
  if (lang === "ua") {
    if (value === 0) {
      return `${value} ${t?.[keyword]?.[2]}`;
    }

    if (value === 1) {
      return `${value} ${t?.[keyword]?.[0]}`;
    }

    if (value >= 2 && value <= 4) {
      return `${value} ${t?.[keyword]?.[1]}`;
    }

    if (value > 4) {
      return `${value} ${t?.[keyword]?.[2]}`;
    }
  }

  // Time for EN lang (default)
  if (value === 0) {
    return `${value} ${t?.[keyword]?.[1]}`;
  }

  if (value === 1) {
    return `${value} ${t?.[keyword]?.[0]}`;
  }

  if (value > 1) {
    return `${value} ${t?.[keyword]?.[1]}`;
  }
};
