import { useFormContext } from "react-hook-form";

import "./Textarea.scss";

export const Textarea = ({
  name,
  rules,
  margin,
  label,
  layout,
  showError,
  isDescription = false,
  parentStyles,
  errorPath = [],
  ...rest
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const defineClassname = () => {
    let className = "form-textarea__item";

    switch (layout) {
      case "vertical": {
        className += " form-textarea__item--layout-vertical";
        break;
      }
      case "horizontal": {
        className += " form-textarea__item--layout-horizontal";
        break;
      }
      default: {
        break;
      }
    }

    switch (margin) {
      case "mb-large": {
        className += " form-textarea__item--margin-bottom-large";
        break;
      }
      default: {
        break;
      }
    }

    if (errors[name]) {
      className += " form-textarea__item--error";
    }

    return className;
  };

  return (
    <div className={defineClassname()} style={parentStyles}>
      <label>
        {label && <span className="form-textarea__label">{label}</span>}
        <div className="form-textarea__textarea">
          <textarea {...register(name, rules)} {...rest}></textarea>
        </div>
      </label>
      {isDescription &&
        showError &&
        !errorPath?.length &&
        errors[name.slice(0, -3)]?.message && (
          <div className="form-textarea__error">
            {errors[name.slice(0, -3)]?.message}
          </div>
        )}

      {!isDescription &&
        showError &&
        !errorPath?.length &&
        errors[name]?.message && (
          <div className="form-textarea__error">{errors[name]?.message}</div>
        )}

      {showError && errors[errorPath[0]]?.[errorPath[1]]?.message && (
        <div className="form-textarea__error">
          {errors[errorPath[0]]?.[errorPath[1]]?.message}
        </div>
      )}
    </div>
  );
};
