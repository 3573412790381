import { useTranslation } from "react-i18next";

import animation from "../../../../assets/animations/tracing_ip.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  tracingIpHeroImageMobile
} from "../../../../assets/img";
import SeoSection from "../../../../components/common/New/SeoSection/SeoSection";
import { Rive } from "../../../../components/ui/New/Rive/Rive";
import { $api } from "../../../../services/AxiosInstance";
import { AllActions } from "../../../../store/reducers/AllActions";

import { TraicingIp } from "./TracingIp/TracingIp";
import { RestyledFAQ, RestyledMainSection } from "./TracingIpPage.styled";

const TracingIpPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <RestyledMainSection
        title={t("tracingIp.main.title")}
        subtitle={t("tracingIp.main.subtitle")}
        button={false}
        images={{
          mobile1x: tracingIpHeroImageMobile,
          mobile2x: tracingIpHeroImageMobile
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-93}
            right={-148}
            bottom={-93}
            left={-98}
          />
        }
      />
      <TraicingIp />
      <RestyledFAQ
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
      />
      <SeoSection />
    </>
  );
};

export default TracingIpPage;

TracingIpPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });

  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
