import { forwardRef } from "react";

import { StyledTableCheckbox } from "./TableCheckbox.styled";

// import styles from "./TableCheckbox.module.scss";

export const TableCheckbox = forwardRef(({ name, className, ...rest }, ref) => (
  <StyledTableCheckbox
    className={className}
    type={"checkbox"}
    {...rest}
    ref={ref}
    id={name}
  />
));
