import styled from "@emotion/styled";

import FAQ from "../../../../components/common/New/FAQ/FAQ";
import { Main } from "../../../../components/common/New/Main/Main";

export const RestyledMainSection = styled(Main)`
  padding-bottom: 80px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 190px;
    min-height: 580px;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    min-height: 600px;

    & .main-description {
      width: 100%;
    }
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      top: 30px;
      width: 408px;
      height: 256px;
      right: 30px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      right: 0;
      width: 510px;
      height: 329px;
    }
    // @media (min-width: 1530px) {
    //   right: 10vw;
    // }
  }
`;

export const StyledFAQSection = styled(FAQ)`
  img {
    width: 100%;
    max-width: 409px;
  }
`;

export const StyledSection = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    background-color: ${({ theme }) => theme.colors["background-color"]};
  }
`;
