import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AllProxyCountriesModal } from "../../../Modals/AllProxyCountriesModal/AllProxyCountriesModal";
import TextButton from "../../../../../ui/New/TextButton/TextButton";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";

import { getCountriesById } from "../../../../../../utils/helpers";
import {
  StyledContentList,
  StyledContentListItem,
  StyledContentTitle
} from "../../ExpandableComponent.styled";

export const Locations = ({ data }) => {
  const { t } = useTranslation();
  // **Redux state
  const { countries } = useSelector(getAllContent);
  const allNewCountries =
    data?.countriesNew?.length > 0 ? data.countriesNew : [];
  const countriesList = getCountriesById(allNewCountries, countries.data);

  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen((prev) => !prev);
  return (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.geo.title")}
      </StyledContentTitle>
      <StyledContentList>
        <StyledContentListItem>
          {countriesList?.length} {t("proxyPage.content.table.geo.title")}
        </StyledContentListItem>
        <StyledContentListItem>
          <TextButton color={"secondary"} size={"sm"} onClick={toggleModal}>
            {t("proxyPage.content.table.geo.modal.title")}
          </TextButton>
        </StyledContentListItem>
      </StyledContentList>
      <AllProxyCountriesModal
        data={countriesList}
        open={open}
        onClose={setOpen}
      />
    </div>
  );
};
