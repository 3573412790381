import { useState } from "react";

import PT from "prop-types";

import { StyledNavigation } from "./Navigation.styled";
import NavigationItem from "./NavigationItem";

export const Navigation = ({ data, className, mobile }) => {
  const [openItems, setOpenItems] = useState([]);

  const handleOpen = (el) => {
    setOpenItems([...openItems, el]);
  };
  const handleClose = (el) => {
    setOpenItems(openItems?.filter((item) => item !== el));
  };

  return (
    <StyledNavigation className={className}>
      {data.map((item, index) => (
        <NavigationItem
          title={item.title}
          list={item.list}
          onClick={
            openItems.includes(item.title)
              ? () => handleClose(item.title)
              : () => handleOpen(item.title)
          }
          key={index}
          isOpened={openItems.includes(item.title)}
          mobile={mobile}
        />
      ))}
    </StyledNavigation>
  );
};

Navigation.propTypes = {
  data: PT.arrayOf(PT.shape({})).isRequired,
  className: PT.string,
  mobile: PT.bool
};
