import { Icon, IconWrapper, Item, Value } from "./ReactTableItem.styled";

const ReactTableItem = ({ src, alt, label, value }) => (
  <Item>
    <IconWrapper>
      <Icon src={src} alt={alt} />
      <span>{label}</span>
    </IconWrapper>
    <Value>{value}</Value>
  </Item>
);

export default ReactTableItem;
