import { useSelector } from "react-redux";

import { Container } from "../../../../components/ui";
import { Loader } from "../../../../components/ui/Loader/Loader";
import { getAllBlogInner } from "../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";

import { Content } from "./Content/Content";
import { Sidebar } from "./Sidebar/Sidebar";

import "./BlogInner.scss";

export const BlogInner = ({ captchaRef, setTokenCaptcha }) => {
  // **Redux state
  const { content } = useSelector(getAllBlogInner);
  const { routeContent } = useSelector(getAllContent);

  return (
    <section className="blog-inner">
      <Container isFluid={routeContent.isContainerFluid}>
        {content.isLoading && <Loader />}
        {!content.isLoading && (
          <div className="blog-inner__wrapper">
            <Content
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
            />
            <Sidebar />
          </div>
        )}
      </Container>
    </section>
  );
};
