export const getCabinetSitesForSelect = (sites) => {
  if (sites?.length === 0) {
    return [];
  }

  return sites.map((site) => ({
    value: site?.id,
    label: site?.name
  }));
};
