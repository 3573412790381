import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { ReviewItem } from "./ReviewItem/ReviewItem";
import { StyledShowAllButton } from "./Reviews.styled";

const PREV_SHOW_COUNT = 3;

export const Reviews = ({
  siteTitle,
  captchaRef,
  setTokenCaptcha,
  reviews = [],
  changeHandler,
  nestedResponseValue = 1,
  toggleReview,
  isDashboard
}) => {
  const { t } = useTranslation();
  const reviewsLength = reviews?.length;
  const [reviewsToShow, setReviewsToShow] = useState([]);

  const showAllHandler = () => {
    setReviewsToShow(reviews);
    toggleReview?.(true);
  };

  useEffect(() => {
    setReviewsToShow(
      nestedResponseValue !== 1 ? reviews.slice(0, reviewsLength) : reviews
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews]);
  return (
    <>
      {reviewsToShow?.map((review, index) => (
        <ReviewItem
          siteTitle={siteTitle}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
          key={review.id}
          review={review}
          last={index === reviewsToShow?.length - 1}
          changeHandler={changeHandler}
          isRootReview={nestedResponseValue === 1}
          nestedResponseValue={nestedResponseValue}
          isDashboard={isDashboard}
          parentToggleReview={toggleReview}
        />
      ))}
      {nestedResponseValue !== 1 &&
        reviewsToShow?.length < reviewsLength &&
        reviewsLength > PREV_SHOW_COUNT && (
          <StyledShowAllButton
            color={"secondary"}
            onClick={showAllHandler}
            iconRight={"refresh"}
          >
            {t("reviews.showAll")}
          </StyledShowAllButton>
        )}
    </>
  );
};
