import { Link } from "react-router-dom";

import "./Button.scss";

export const Button = ({
  position,
  type,
  size,
  link,
  btnType,
  children,
  classNames = "",
  href = "#",
  ...rest
}) => {
  const defineClassname = () => {
    let className = `${classNames} button`;

    switch (position) {
      case "center": {
        className += " button__position--center";
        break;
      }
      case "left": {
        className += " button__position--left";
        break;
      }
      case "right": {
        className += " button__position--right";
        break;
      }
      default: {
        break;
      }
    }

    switch (type) {
      case "solid": {
        className += " button__type--solid";
        break;
      }
      case "transparent": {
        className += " button__type--transparent";
        break;
      }
      case "black": {
        className += " button__type--black";
        break;
      }
      case "black-reverse": {
        className += " button__type--black-reverse";
        break;
      }
      default: {
        break;
      }
    }

    switch (size) {
      case "large": {
        className += " button__size--large";
        break;
      }
      case "small": {
        className += " button__size--small";
        break;
      }
      case "auth": {
        className += " button__size--auth";
        break;
      }
      case "full": {
        className += " button__size--full";
        break;
      }
      default: {
        className += " button__size--default";
        break;
      }
    }

    return className;
  };

  return link ? (
    <Link className={defineClassname()} to={href} {...rest}>
      {children}
    </Link>
  ) : (
    <button className={defineClassname()} type={btnType} {...rest}>
      {children}
    </button>
  );
};
