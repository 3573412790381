import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Input } from "../../../../../../../components/forms/Input/Input";
import { Textarea } from "../../../../../../../components/forms/Textarea/Textarea";
import { Button } from "../../../../../../../components/ui/Button/Button";
import { Loader } from "../../../../../../../components/ui/Loader/Loader";
import { useLangUrlDefault } from "../../../../../../../hooks";
import { ApiService } from "../../../../../../../services";
import { getAllBlogInner } from "../../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllUser } from "../../../../../../../store/reducers/UserReducer/User.selectors";
import { CommentSchema } from "../../../../../../../utils/validation";

export const Form = ({ afterSubmitHandler, captchaRef, setTokenCaptcha }) => {
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { user, isUserAuthenticated } = useSelector(getAllUser);
  const { content } = useSelector(getAllBlogInner);
  const { captchaConfig } = useSelector(getAllContent);
  const { t } = useTranslation();

  // **Local state
  const [isLoading, setIsLoading] = useState(false);
  const { enable, headerName } = captchaConfig.data;

  // Form
  const methods = useForm({
    resolver: yupResolver(CommentSchema(t("forms", { returnObjects: true })))
  });

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      setIsLoading(true);

      const { id } = content.data;
      const { name, email, body } = data;

      const response = await ApiService.createComment(
        id,
        id,
        isUserAuthenticated ? user.name : name,
        isUserAuthenticated ? user.email : email,
        body,
        queryLang,
        header
      );

      if (response && response.status !== 200) {
        throw response;
      }

      // Reset form
      methods.reset();

      // Show success message
      toast.success(t("notifications.modals.comment"));

      afterSubmitHandler();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current?.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="blog-inner__comments-form">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {!isUserAuthenticated && (
            <div className="proxy-form__form-item">
              <div className="proxy-form__form-row">
                <Input
                  name="name"
                  type="text"
                  layout="vertical"
                  defaultValue={isUserAuthenticated ? user.name : ""}
                  label={t("modals.comment.form.name.title")}
                  placeholder={t("modals.comment.form.name.placeholder")}
                  margin="mb-large"
                  showError
                />
                <Input
                  name="email"
                  type="text"
                  layout="vertical"
                  defaultValue={isUserAuthenticated ? user.email : ""}
                  label={t("modals.comment.form.email.title")}
                  placeholder={t("modals.comment.form.email.placeholder")}
                  margin="mb-large"
                  showError
                />
              </div>
            </div>
          )}
          <Textarea
            name="body"
            layout="vertical"
            margin="mb-large"
            label={t("modals.comment.form.body.title")}
            placeholder={t("modals.comment.form.body.placeholder")}
            showError
          />
          <Button
            disabled={isLoading}
            type="solid"
            size="large"
            btnType="submit"
          >
            {!isLoading ? (
              t("modals.comment.title")
            ) : (
              <Loader
                type="absolute"
                scale={0.4}
                color="var(--clr-default-900)"
              />
            )}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
