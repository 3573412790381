import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../../../hooks";

export const PromocodeItem = ({ promocode, modalHandler }) => {
  const [, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  return (
    <li className="cabinet-promocode__item">
      <div className="cabinet-promocode__item-content">
        <div className="cabinet-promocode__item-name">{promocode.siteName}</div>
        <div className="cabinet-promocode__item-date">
          <span>
            <Moment format="DD.MM.YYYY HH:mm">
              {new Date(promocode.startDate)}
            </Moment>
          </span>
          <span className={promocode.eternal ? "eternal" : ""}>
            {promocode.eternal ? (
              "∞"
            ) : (
              <Moment format="DD.MM.YYYY HH:mm">
                {new Date(promocode.endDate)}
              </Moment>
            )}
          </span>
        </div>
        <div className="cabinet-promocode__item-status">
          <span>{t("statuses.title")}:</span>
          {promocode.isActive ? t("statuses.active") : t("statuses.inactive")}
        </div>
      </div>
      <div className="cabinet-promocode__item-actions">
        <Link
          className="cabinet-promocode__item-action--edit"
          to={`${hrefLang}/cabinet/promocodes/edit/${promocode?.id}/`}
        >
          {t("cabinetPages.proxy.list.edit")}
        </Link>
        <button
          className="cabinet-promocode__item-action--delete"
          onClick={modalHandler(promocode?.id)}
        >
          {t("cabinetPages.proxy.list.delete")}
        </button>
      </div>
    </li>
  );
};
