import PT from "prop-types";
import Moment from "react-moment";

import { StyledDateCell } from "./DateCell.styled";

export const DateCell = ({ date }) =>
  date ? (
    <StyledDateCell>
      <Moment format="DD.MM.YYYY">{date}</Moment>
      <span>|</span>
      <Moment format="HH:mm">{date}</Moment>
    </StyledDateCell>
  ) : null;

DateCell.propTypes = {
  date: PT.oneOfType([PT.string, PT.instanceOf(Date)])
};
