import { useState } from "react";

import { useTranslation } from "react-i18next";

import { ReviewModal } from "../../../../../../components/common";
import { Button } from "../../../../../../components/ui/Button/Button";

export const ReviewsTop = ({
  captchaRef,
  setTokenCaptcha,
  pageParams,
  setPageParams,
  getSiteReviews
}) => {
  // **Redux state
  const { t } = useTranslation();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const modalHandler = () => {
    setIsModalOpened((prevState) => !prevState);
  };

  const changeSortType = (type) => {
    setPageParams({ ...pageParams, sort: type });
  };

  return (
    <>
      <div className="proxy-site__reviews-top">
        <div className="proxy-site__reviews-sort">
          <div className="proxy-site__reviews-sort-title">
            {t("proxySitePage.content.reviews.top.title")}:
          </div>
          <div className="proxy-site__reviews-sort-list">
            {t("proxySitePage.content.reviews.top.sortList", {
              returnObjects: true
            }).map((sortType) => (
              <button
                key={sortType.param}
                className={
                  pageParams.sort === sortType.param
                    ? "proxy-site__reviews-sort-item proxy-site__reviews-sort-item--active"
                    : "proxy-site__reviews-sort-item"
                }
                onClick={() => changeSortType(sortType.param)}
              >
                {sortType.label}
              </button>
            ))}
          </div>
        </div>
        <Button type="black" position="right" onClick={modalHandler}>
          {t("modals.review.titleAdd")}
        </Button>
      </div>
      <ReviewModal
        visible={isModalOpened}
        cancelHandler={modalHandler}
        afterSubmitHandler={getSiteReviews}
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
      />
    </>
  );
};
