import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "../SkeletonTable/SkeletonTable.scss";

export const SkeletonToolTable = ({ cells }) => (
  <SkeletonTheme baseColor="#d9d9d9" highlightColor="#adadad">
    <div className="skeleton-table">
      {cells &&
        Array(cells)
          .fill(0)
          .map((item, i) => (
            <div key={`item-skeleton-${i}`} className="skeleton-row">
              <div
                className="skeleton-table__col"
                style={{
                  width: "64px",
                  height: "64px"
                }}
              >
                <Skeleton width={20} height={20} />
              </div>
              <div
                className="skeleton-table__col"
                style={{
                  minWidth: "265px"
                }}
              >
                <div style={{ display: "flex" }}>
                <Skeleton
                  height={24}
                  width={100}
                  style={{ marginRight: "8px" }}
                />
                                  <Skeleton
                    height={24}
                    width={24}
                  />
                </div>
                </div>
              <div
                className="skeleton-table__col"
                style={{
                  width: "180px"
                }}
              >
                <Skeleton width={78} height={36} />
              </div>
              <div
                className="skeleton-table__col"
                style={{
                  width: "600px"
                }}
              >
                <Skeleton height={40} width={600} />
              </div>
            </div>
          ))}
    </div>
  </SkeletonTheme>
);
