import styled from "@emotion/styled";

const variables = {
  color: {
    success: "var(--main-700)",
    warning: "var(--orange)",
    error: "var(--red-500)",
    default: "var(--neutral-700)"
  },
  border: {
    success: "var(--main-500)",
    warning: "rgba(242, 153, 74, 0.1)",
    error: "rgba(255, 92, 92, 0.3)",
    default: "rgba(0, 0, 0, 0.1)"
  },
  background: {
    success: "#f0fbde",
    warning: "#fdf7db",
    error: "#ffe9e6",
    default: "var(--neutral-50)"
  }
};
export const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => (p.size === "sm" ? "8px 12px" : "8px")};
  border-radius: 8px;
  color: ${(props) =>
    variables.color?.[props.color] || variables.color.default};
  border: ${(props) =>
    props.bordered === "true"
      ? `1px solid ${
          variables.border?.[props.color] || variables.border.default
        }`
      : 0};
  background: ${(props) =>
    variables.background?.[props.color] || variables.background.default};
`;

export const Label = styled.div`
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: ${(props) => (props.size === "sm" ? "16px" : "20px")};
  font-style: normal;
  font-weight: ${(props) => (props.size === "sm" ? 400 : 700)};
  line-height: ${(props) => (props.size === "sm" ? 125 : 145)}%;
  text-transform: ${(props) => (props.size === "sm" ? "none" : "uppercase")};
`;
