import * as yup from "yup";

const regexpUrlWithoutHttps =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const HttpHeadersCheckSchema = (trns) =>
  yup.object().shape({
    url: yup
      .string()
      .required(trns.form.rules.required)
      .matches(regexpUrlWithoutHttps, trns.form.rules.url)
  });
