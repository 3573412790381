import { useFormContext } from "react-hook-form";

import "./Switch.scss";

export const Switch = ({
  name,
  margin,
  rules,
  label,
  layout,
  showError,
  ...rest
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const defineClassname = () => {
    let className = "form-switch__item";

    switch (layout) {
      case "vertical": {
        className += " form-switch__item--layout-vertical";
        break;
      }
      case "horizontal": {
        className += " form-switch__item--layout-horizontal";
        break;
      }
      default: {
        break;
      }
    }

    switch (margin) {
      case "mb-large": {
        className += " form-switch__item--margin-bottom-large";
        break;
      }
      default: {
        break;
      }
    }

    if (errors[name]) {
      className += " form-switch__item--error";
    }
    return className;
  };

  return (
    <div className={defineClassname()}>
      <label>
        {label && <span className="form-switch__label">{label}</span>}
        <div className="form-switch__input">
          <input type="checkbox" {...register(name, rules)} {...rest} />
          <span className="form-switch__slider"></span>
        </div>
      </label>
      {showError && errors[name]?.message && (
        <div className="form-switch__error">{errors[name]?.message}</div>
      )}
    </div>
  );
};
