import { useState } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Eye, EyeInvisible } from "../../icons";

import "./AuthInput.scss";

export const AuthInput = ({ name, type, rules, label, showError, ...rest }) => {
  // **Redux state
  const { t } = useTranslation();

  // **Local state
  const [isPassVisible, setIsPassVisible] = useState(false);

  // Form
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const defineClassname = () => {
    let className = "form-auth-input__item";

    if (type === "password") {
      className += " form-auth-input__item--password";
    }

    if (errors[name]) {
      className += " form-auth-input__item--error";
    }

    return className;
  };

  const defineInputType = () => {
    let inputType = type;

    if (type === "password" && isPassVisible) {
      inputType = "text";
    }

    return inputType;
  };

  const changePassVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  return (
    <div className={defineClassname()}>
      <label>
        {label && <span className="form-auth-input__label">{label}</span>}
        <div className="form-auth-input__input">
          <input
            type={defineInputType()}
            {...register(name, rules)}
            {...rest}
          />
          {type === "password" && (
            <button
              type="button"
              className="form-auth-input__visibility"
              onClick={changePassVisibility}
              aria-label={
                isPassVisible
                  ? t("forms.password.state.hide")
                  : t("forms.password.state.show")
              }
            >
              {isPassVisible ? <EyeInvisible /> : <Eye />}
            </button>
          )}
        </div>
        {showError && errors[name]?.message && (
          <div className="form-auth-input__error">{errors[name]?.message}</div>
        )}
      </label>
    </div>
  );
};
