import { useEffect, useId, useRef, useState } from "react";

import Annotation from "../../../../../../../components/ui/New/Annotation/Annotation";
import Rating from "../../../../../../../components/ui/New/Raiting/Rating";
import { StyledSkeleton } from "../../../../../../../components/ui/New/Skeleton/components/SkeletonTableComponents.styled";
import { StyledTooltip } from "../../../../../../../components/ui/New/Tooltip/Tooltip.styled";

import { useLangUrlDefault } from "../../../../../../../hooks";

import {
  StyledServiceHead,
  StyledServiceHeadImage,
  StyledServiceHeadInfo,
  StyledServiceHeadTitleLink
} from "./ServiceHead.styled";

export const ServiceHead = ({ data, loading }) => {
  const [, hrefLang] = useLangUrlDefault();
  const ref = useRef();
  const id = useId();

  const [needTooltip, setNeedTooltip] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setNeedTooltip(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);
  return (
    <StyledServiceHead>
      {!loading ? (
        <StyledServiceHeadImage
          src={data?.image ? data.image : "/img/ui/placeholder.svg"}
          alt={data?.name}
          loading="lazy"
          aria-hidden="true"
        />
      ) : (
        <StyledSkeleton width={44} height={44} />
      )}
      <StyledServiceHeadInfo>
        {!loading ? (
          <StyledServiceHeadTitleLink
            to={`${hrefLang}/proxy/${data.alias}/`}
          >
            <span ref={ref} data-tooltip-id={id}>
              {data?.name}
            </span>
          </StyledServiceHeadTitleLink>
        ) : (
          <StyledSkeleton width={150} height={19} />
        )}
        {needTooltip ? (
          <StyledTooltip id={id} target={ref}>
            {data?.name}
          </StyledTooltip>
        ) : null}
        {!loading ? (
          <Annotation
            positive={data?.reviewRatingPositive}
            negative={data?.reviewRatingNegative}
            showIcon
          />
        ) : (
          <StyledSkeleton width={50} height={19} />
        )}
      </StyledServiceHeadInfo>
      {!loading ? (
        <Rating rating={data?.rating} size={"sm"} />
      ) : (
        <StyledSkeleton width={64} height={36} />
      )}
    </StyledServiceHead>
  );
};
