import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Pagination,
  Reviews as ReviewsContent
} from "../../../../../../components/common";
import { Loader } from "../../../../../../components/ui/Loader/Loader";
import { useDispatchedActions } from "../../../../../../hooks";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

import { ReviewsTop } from "./ReviewsTop";

export const Reviews = ({ captchaRef, setTokenCaptcha }) => {
  // **Props
  const { alias } = useParams();

  // **Redux state
  const { t } = useTranslation();
  const { reviews } = useSelector(getAllProxySite);

  // **Local state
  const [pageParams, setPageParams] = useState({
    sort: "creationDate",
    currentPage: reviews.reviewPage + 1 || 1,
    size: 10
  });

  // **Ref
  const scrollRef = useRef(null);

  // **Dispatch
  const { getProxySiteReviews } = useDispatchedActions();

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({
      ...pageParams,
      currentPage: page
    });
  };

  const fetchProxyRevies = useCallback(() => {
    getProxySiteReviews({
      sort: pageParams.sort,
      siteId: alias,
      page: pageParams.currentPage - 1,
      size: pageParams.size
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias, pageParams]);

  // Handling content
  useEffect(() => {
    fetchProxyRevies();
  }, [fetchProxyRevies]);

  useEffect(() => {
    if (reviews.reviewPage?.toString()) {
      setPageParams({
        ...pageParams,
        currentPage: reviews.reviewPage + 1
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews.reviewPage]);

  // Handling error
  useEffect(() => {
    if (reviews.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews.isError]);

  return (
    <div ref={scrollRef} className="proxy-site__reviews">
      <ReviewsTop
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
        pageParams={pageParams}
        setPageParams={setPageParams}
        getSiteReviews={fetchProxyRevies}
      />
      <div className="proxy-site__reviews-content">
        {reviews.data?.content?.length !== 0 && (
          <>
            <ReviewsContent
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              reviews={reviews.data?.content}
              changeHandler={fetchProxyRevies}
            />
            <Pagination
              totalPages={reviews.data?.totalPages}
              onPageChange={pageChangeHandler}
              currentPage={pageParams.currentPage}
              hideOnSinglePage
              scrollRef={scrollRef}
            />
          </>
        )}
        {!reviews.isLoading && reviews.data?.content?.length === 0 && (
          <p>{t("proxySitePage.content.reviews.noData")}</p>
        )}
        {reviews.isLoading && <Loader type="blur" />}
      </div>
    </div>
  );
};
