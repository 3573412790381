import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Rating } from "../../../../components/common";
import { Container } from "../../../../components/ui";
import { Button } from "../../../../components/ui/Button/Button";
import { FeedbackCount } from "../../../../components/ui/FeedbackCount/FeedbackCount";
import { Loader } from "../../../../components/ui/Loader/Loader";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { AllActions } from "../../../../store/reducers/AllActions";
import { getAllComparison } from "../../../../store/reducers/ComparisonReducer/Comparison.selectors";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllSite } from "../../../../store/reducers/SiteReducer/Site.selectors";
import {
  convertCountryCodeAlpha3ToAlpha2,
  getCountriesById,
  getGoalsById,
  getRentPeriod
} from "../../../../utils/helpers";

import "./Comparison.scss";

export const Comparison = () => {
  // **Props
  const { uuid } = useParams();
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  // **Redux state
  const {
    routeContent,
    countries,
    goals,
    pageContent: { current }
  } = useSelector(getAllContent);
  const { sites, sitesFull, prevSites, isOwner, isDeleting } =
    useSelector(getAllComparison);
  const { previousLang } = useSelector(getAllSite);

  // **Local state
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  // const metaData = useMetaData();

  // First confition is needed for sharing,second condition needed for usual routing
  const [isLoading, setIsLoading] = useState(
    (sitesFull?.length === 0 && isFirstLoad) ||
      (isFirstLoad && sites?.length !== sitesFull?.length)
  );

  // **Dispatch
  const {
    setFullSites,
    resetFullSites,
    setPrevSites,
    updateComparsionSites,
    getAllGoals,
    setPreviousLang,
    deleteComparisonSite
  } = useDispatchedActions();

  const getSites = async () => {
    try {
      const response = await ApiService.getComparisonSites(uuid);

      if (response && response.status !== 200) {
        throw response;
      }
      if (response?.data) {
        setFullSites(response?.data);
      } else {
        resetFullSites();
      }
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      setIsLoading(false);
      setIsFirstLoad(false);
    }
  };

  const deleteSite = (id) => () => {
    deleteComparisonSite(id);

    updateComparsionSites({
      uuid,
      siteIds: sites
        .filter((site) => site.siteId !== id)
        .map((site) => site.siteId)
    });
  };

  useEffect(() => {
    if (
      sites?.length !== 0 &&
      JSON.stringify(sites) !== JSON.stringify(prevSites)
    ) {
      getSites();
      setPrevSites(sites);
    }

    if (sites?.length === 0 && uuid) {
      getSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites, uuid]);

  useEffect(() => {
    if (!goals.isDataLoaded || previousLang !== queryLang) {
      getAllGoals(queryLang);
      setPreviousLang(queryLang);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goals.isDataLoaded, queryLang]);

  return (
    <section className="comparison">
      <Container isFluid={routeContent.isContainerFluid}>
        <div className="comparison__wrapper">
          <div className="comparison__top">
            <h1>{current?.data?.h1}</h1>
          </div>
          <div
            className={
              isLoading
                ? "comparison__content comparison__content--loading"
                : "comparison__content"
            }
          >
            {sitesFull?.length !== 0 && (
              <div className="comparison__table">
                <ul className="comparison__table-list">
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.name")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.name}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.link")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        <Button
                          size="small"
                          type="solid"
                          link
                          href={`${hrefLang}/proxy/${site.alias}/`}
                        >
                          {t("proxyPage.content.table.site.btn")}
                        </Button>
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.rating")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        <Rating
                          isCaptionVisible={false}
                          rating={site.rating}
                          position="left"
                        />
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.freeTest")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.freeTest ? t("common.yes") : t("common.no")}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.individual")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.individual ? t("common.yes") : t("common.no")}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.replacement")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.replacementPossibility
                          ? t("common.yes")
                          : t("common.no")}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.countries")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site?.id} className="comparison__item-site">
                        <ul className="comparison__item-countries">
                          {getCountriesById(site.countries, countries.data).map(
                            (country) => {
                              const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                                country?.code
                              ).toLowerCase();
                              const name = queryLang
                                ? country?.localization[queryLang]
                                : "";

                              return (
                                <li key={country?.id}>
                                  {alpha2 !== "default" ? (
                                    <span className={`fi fi-${alpha2}`}></span>
                                  ) : (
                                    <img
                                      src="/img/flags/default.svg"
                                      width={20}
                                      height={20}
                                      loading="lazy"
                                      alt={name}
                                    />
                                  )}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.type")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.proxyType.join(", ")}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.purposes")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {getGoalsById(site.purposeUse, goals?.data)
                          .map((goal) => goal.name)
                          .join(", ")}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.minCost")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.price} {site.currencyName}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.minRent")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {
                          getRentPeriod(
                            site.minRentPeriod,
                            queryLang,
                            t("times", { returnObjects: true })
                          )?.label
                        }
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.auth")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.clientLoginAuth && (
                          <p>{t("proxy.auth.loginAuth")}</p>
                        )}
                        {site.clientIPAuth && <p>{t("proxy.auth.ipAuth")}</p>}
                        {site.clientNoAuth && <p>{t("proxy.auth.noAuth")}</p>}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.refunds")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.refunds ? t("common.yes") : t("common.no")}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.partnership")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        {site.affiliateProgram
                          ? t("common.yes")
                          : t("common.no")}
                      </div>
                    ))}
                  </li>
                  <li className="comparison__item">
                    <div className="comparison__item-head">
                      {t("comparisonPage.table.reviews")}
                    </div>
                    {sitesFull.map((site) => (
                      <div key={site.id} className="comparison__item-site">
                        <FeedbackCount
                          Tag="a"
                          positive={site.reviewRatingPositive}
                          negative={site.reviewRatingNegative}
                          href={`${hrefLang}/proxy/${site.alias}/#reviews`}
                        />
                      </div>
                    ))}
                  </li>
                  {isOwner && (
                    <li
                      className={
                        isDeleting
                          ? "comparison__item comparison__item--deleting"
                          : "comparison__item"
                      }
                    >
                      <div className="comparison__item-head">
                        {t("comparisonPage.table.remove")}
                      </div>
                      {sitesFull.map((site) => (
                        <div key={site.id} className="comparison__item-site">
                          <button
                            className="comparison__item-delete"
                            onClick={deleteSite(site.id)}
                            disabled={isDeleting}
                          >
                            <span></span>
                            {t("comparisonPage.table.remove")}
                          </button>
                        </div>
                      ))}
                    </li>
                  )}
                </ul>
              </div>
            )}
            {!isFirstLoad && !isLoading && sitesFull?.length === 0 && (
              <div className="comparison__no">
                {t("comparisonPage.noData")}{" "}
                <Link to={`${hrefLang}/`}>
                  {t("comparisonPage.returnText")}
                </Link>
              </div>
            )}
            {isLoading && <Loader type="blur" />}
          </div>
        </div>
      </Container>
    </section>
  );
};

Comparison.getServerSideState = async (store, params) => {
  await ApiService.getAllGoals(params.locale).then((response) => {
    if (response?.data) {
      store.dispatch(AllActions.setGoals(response.data));
    }
  });
};
