export const getCountryByIso = (countryIsoCode, allCountries) => {
    let country = "";
      allCountries?.forEach((stateCountry) => {
        if (countryIsoCode === stateCountry.codeAlpha2) {
          country = stateCountry;
        }
    });

    return country;
  };
