import styled from "@emotion/styled";

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 16px 24px;
  }
`;
