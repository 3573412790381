import "./Container.scss";

export const Container = ({ isFluid, isAuth, children }) => {
  const defineClassname = () => {
    let className = "container";

    if (isFluid) {
      className += " container__type--fluid";
    }

    if (isAuth) {
      className += " container__type--auth";
    }

    return className;
  };

  return <div className={defineClassname()}>{children}</div>;
};
