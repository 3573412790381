/* eslint-disable react/no-unknown-property */
import PT from "prop-types";
import { useSelector } from "react-redux";

import { useMatchedRoute } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getHighlightedWord } from "../../../../utils/helpers/text.helper";
import { StyledSkeleton } from "../../../ui/New/Skeleton/components/SkeletonTableComponents.styled";

import {
  ContentWrapper,
  Description,
  ImageWrapper,
  MainContainer,
  MainSection,
  StyledButton,
  StyledSkeletonBoxTitle
} from "./Main.styled";
import HighlightedTitle from "./components";

export const Main = ({
  title,
  subtitle,
  subtitleHtml,
  buttonLabel = "Select service",
  highlightWord,
  images,
  animation,
  button = true,
  className,

  star = false,
  handleButtonClick
}) => {
  const { pageContent } = useSelector(getAllContent);
  // current page
  const page = useMatchedRoute();
  // current page title
  const heading = pageContent.current?.data?.h1 || title;

  const currentHighlighWord = () => {
    if (!pageContent.current?.data?.h1) {
      return highlightWord;
    }
    if (page?.tag !== "proxy") {
      return pageContent?.current?.data?.highlightedWord;
    }
    return getHighlightedWord(pageContent?.current?.data?.highlightedWord);
  };

  // current highlightedWord
  const highlightedWord = currentHighlighWord();

  const loading = !title && !pageContent.isDataLoaded;

  return (
    <MainSection>
      <MainContainer className={className}>
        <ContentWrapper className="main-content-wrapper">
          {loading ? (
            <StyledSkeletonBoxTitle>
              <StyledSkeleton />
            </StyledSkeletonBoxTitle>
          ) : (
            <HighlightedTitle
              heading={heading}
              highlightWord={highlightedWord}
              star={star}
              loading={loading}
            />
          )}

          {subtitle && (
            <Description className="main-description">{subtitle}</Description>
          )}

          {subtitleHtml && (
            <Description
              className="main-description"
              dangerouslySetInnerHTML={{
                __html: subtitleHtml
              }}
            />
          )}

          {button && (
            <StyledButton size="lg" onClick={handleButtonClick}>
              {buttonLabel}
            </StyledButton>
          )}
        </ContentWrapper>
        <ImageWrapper className={"main-image-wrapper"}>
          {animation ? animation : null}
          <picture>
            {images.mobile1x && (
              <source
                srcSet={`${images.mobile1x} 1x ${
                  images.mobile2x ? `, ${images.mobile2x} 2x` : ""
                }`}
              />
            )}

            <img src={images.mobile2x} alt={heading} fetchpriority="high" />
          </picture>
        </ImageWrapper>
      </MainContainer>
    </MainSection>
  );
};

Main.propTypes = {
  highlightWord: PT.string,
  title: PT.string,
  subtitle: PT.string,
  subtitleHtml: PT.string,
  buttonLabel: PT.string,
  images: PT.shape({}),
  button: PT.bool,
  star: PT.bool,
  className: PT.string,
  animation: PT.node,
  handleButtonClick: PT.func
};
