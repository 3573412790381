import { useTranslation } from "react-i18next";

import {
  StyledCheckIcon,
  StyledNoData,
  StyledPersonalProxy,
  StyledTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "../DataTable.styled";

export const ProxyDifferenceTable = ({ isLoading, data }) => {
  const { t } = useTranslation();

  return (
    <StyledTable paddingSmallScreen="16px 16px 0 0" paddingLargeScreen="24px">
      <TableWrapper>
        <TableHeadRow paddingLeft="0">
          <TableHeadCol width="560px" desktopWidth="50%" padding="0 24px">
            {t("freeProxy.differenceBetween.freeProxy")}
          </TableHeadCol>
          <TableHeadCol width="560px" desktopWidth="50%" padding="0 24px">
            {t("freeProxy.differenceBetween.personalProxy")}
          </TableHeadCol>
        </TableHeadRow>
        <TableBody paddingBottom="0" maxHeight="auto" overflow="clip">
          {!isLoading && data?.length === 0 ? (
            <StyledNoData>
              {t("proxyPage.content.table.noDataComponent")}
            </StyledNoData>
          ) : (
            data.map((item, index) => (
              <TableRow
                key={index}
                minHeight="56px"
                height="auto"
                paddingLeft="0"
                alignItems="center"
                lastRowBorder="none"
              >
                <TableCol width="560px" desktopWidth="50%" padding="8px 24px">
                  <TableText width="100%">{item?.freeProxy}</TableText>
                </TableCol>
                <TableCol width="560px" desktopWidth="50%" padding="8px 24px">
                  <StyledPersonalProxy>
                    <StyledCheckIcon
                      src="img/icons/check-circle.svg"
                      alt="check"
                    />
                    <TableText width="100%">{item?.personalProxy}</TableText>
                  </StyledPersonalProxy>
                </TableCol>
              </TableRow>
            ))
          )}
        </TableBody>
      </TableWrapper>
    </StyledTable>
  );
};
