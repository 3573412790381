import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./SkeletonTable.scss";

export const SkeletonTable = ({ cells }) => (
  <SkeletonTheme baseColor="#d9d9d9" highlightColor="#adadad">
    <div className="skeleton-table">
      {cells && Array(cells).fill(0).map((item, i) => <div key={`item-skeleton-${i}`} className="skeleton-row">
        <div style={{
          width: 10,
          height: 10,
          margin: "0 1.9%"
        }}>
          <Skeleton />
        </div>
        <div style={{
          width: "9%",
          minWidth: 120,
          marginRight: "1.9%"
        }}>
          <Skeleton height={35} style={{ marginBottom: 10 }} />
          <Skeleton height={15} />
          <Skeleton height={10} style={{ marginBottom: 5 }} />
          <Skeleton height={15} />
        </div>
        <div style={{
          width: 20,
          marginRight: "1.9%"
        }}>
          <Skeleton
            circle
            height={20}
            count={2}
            style={{ marginBottom: 15 }}
          />
        </div>
        <div style={{
          width: "15%",
          minWidth: 200,
          marginRight: "2.3%"
        }}>
          <Skeleton height={12} count={7} />
        </div>
        <div style={{
          width: "7%",
          minWidth: 100,
          marginRight: "2.3%"
        }}>
          <Skeleton height={15} count={6} />
        </div>
        <div style={{
          width: "4.5%",
          minWidth: 60,
          marginRight: "3%"
        }}>
          <Skeleton height={20} />
        </div>
        <div style={{
          width: "4.5%",
          minWidth: 60,
          marginRight: "2.5%"
        }}>
          <Skeleton height={20} />
        </div>
        <div style={{
          width: "1.5%",
          minWidth: 20,
          marginRight: "2.3%"
        }}>
          <Skeleton height={15} />
        </div>
        <div style={{
          width: "8%",
          minWidth: 110,
          marginRight: "2%"
        }}>
          <Skeleton height={35} />
        </div>
        <div style={{
          width: 20,
          marginRight: "2%"
        }}>
          <Skeleton height={20} />
        </div>
      </div>)}
    </div>
  </SkeletonTheme>
);
