import styled from "@emotion/styled";

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.white};

  padding: 24px;
  min-height: 320px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-height: 270px;
    padding: 36px;
  }
`;

export const StyledImage = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 64px;
    height: 64px;
  }
`;

export const StyledTitle = styled.div`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.title};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.21;
  margin-bottom: 16px;
`;

export const StyledDescription = styled.div`
  color: ${({ theme }) => theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
`;
