import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { useLangUrlDefault } from "../../../../../../../hooks";
import { getAllBlogInner } from "../../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

export const Info = () => {
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { content } = useSelector(getAllBlogInner);
  const { t } = useTranslation();

  return (
    <ul className="blog-inner__info">
      <li className="blog-inner__info-item blog-inner__info-item--time">
        <Moment format="DD.MM.YYYY">
          {new Date(content.data.creationDate)}
        </Moment>
      </li>
      <li className="blog-inner__info-item blog-inner__info-item--chat">
        <a href={`${hrefLang}/blog/${content.data.alias}/#comments`}>
          {t("blogInnerPage.articleBody.commentsTitle")}:{" "}
          {content.data.commentCount}
        </a>
      </li>
    </ul>
  );
};
