export const setMobileMenuReducer = (state, action) => {
  state.isMobileMenuOpened = action.payload;
};

export const setBurgerMenuReducer = (state, action) => {
  state.isBurgerMenuOpened = action.payload;
};

export const setServiceMenuReducer = (state, action) => {
  state.isServiceMenuOpened = action.payload;
};

export const setPreviousLangReducer = (state, action) => {
  state.previousLang = action.payload;
};
