export const blogInitialState = {
  content: {
    data: {
      totalElements: 0,
      totalPages: 0,
      content: [],
      params: {}
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  }
};
