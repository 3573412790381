export const getCountriesForSelect = (fetchedCountries, lang) => {
  if (fetchedCountries?.length === 0 || !lang) {
    return [];
  }

  const options = [];

  fetchedCountries.forEach((fetchedCountry) => {
    if (fetchedCountry.frontShow && fetchedCountry.localization?.[lang]) {
      options.push({
        label: fetchedCountry.localization?.[lang],
        value: fetchedCountry.id
      });
    }
  });

  return options;
};
