import { createSlice } from "@reduxjs/toolkit";

import { cabinetInitialState } from "./Cabinet.initialState";
import {
  resetCabinetReducer,
  setCabinetPromocodesPrevParamsReducer,
  setCabinetProxiesPrevParamsReducer,
  setCabinetSiteStatusesRecucer
} from "./Cabinet.reducer";
import {
  getAllCabinetSiteStatuses,
  getAllCabinetSites,
  getCabinetPromocodes,
  getCabinetSites,
  resetCabinet,
  setCabinetPromocodesPrevParams,
  setCabinetProxiesPrevParams,
  setCabinetSiteStatuses
} from "./Cabinet.thunks";

const CabinetSlice = createSlice({
  name: "cabinet",
  initialState: cabinetInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setCabinetProxiesPrevParams, setCabinetProxiesPrevParamsReducer)
      .addCase(
        setCabinetPromocodesPrevParams,
        setCabinetPromocodesPrevParamsReducer
      )
      .addCase(setCabinetSiteStatuses, setCabinetSiteStatusesRecucer)
      .addCase(resetCabinet, resetCabinetReducer)

      // getBlogContentByAlias
      .addCase(getCabinetSites.pending, (state) => {
        state.proxies.isError = null;
        state.proxies.isLoading = true;
      })
      .addCase(getCabinetSites.fulfilled, (state, action) => {
        state.proxies.isError = null;
        state.proxies.data = action.payload;
        state.proxies.isLoading = false;
      })
      .addCase(getCabinetSites.rejected, (state, action) => {
        state.proxies.isError = action.payload;
        state.proxies.isLoading = false;
      })

      // getAllCabinetSites
      .addCase(getAllCabinetSites.pending, (state) => {
        state.allProxies.isError = null;
        state.allProxies.isLoading = true;
      })
      .addCase(getAllCabinetSites.fulfilled, (state, action) => {
        state.allProxies.isError = null;
        state.allProxies.data = action.payload;
        state.allProxies.isDataLoaded = true;
        state.allProxies.isLoading = false;
      })
      .addCase(getAllCabinetSites.rejected, (state, action) => {
        state.allProxies.isError = action.payload;
        state.allProxies.isLoading = false;
      })

      // getCabinetPromocodes
      .addCase(getCabinetPromocodes.pending, (state) => {
        state.promocodes.isError = null;
        state.promocodes.isLoading = true;
      })
      .addCase(getCabinetPromocodes.fulfilled, (state, action) => {
        state.promocodes.isError = null;
        state.promocodes.data = action.payload;
        state.promocodes.isLoading = false;
      })
      .addCase(getCabinetPromocodes.rejected, (state, action) => {
        state.promocodes.isError = action.payload;
        state.promocodes.isLoading = false;
      })

      // getAllCabinetSiteStatuses
      .addCase(getAllCabinetSiteStatuses.pending, (state) => {
        state.siteStatuses.isError = null;
        state.siteStatuses.isLoading = true;
      })
      .addCase(getAllCabinetSiteStatuses.fulfilled, (state, action) => {
        state.siteStatuses.isError = null;
        state.siteStatuses.data = action.payload;
        state.siteStatuses.isLoading = false;
      })
      .addCase(getAllCabinetSiteStatuses.rejected, (state, action) => {
        state.siteStatuses.isError = action.payload;
        state.siteStatuses.isLoading = false;
      })
});

export const CabinetReducerActions = {
  setCabinetProxiesPrevParams,
  setCabinetPromocodesPrevParams,
  setCabinetSiteStatuses,
  resetCabinet,
  getCabinetSites,
  getAllCabinetSites,
  getCabinetPromocodes,
  getAllCabinetSiteStatuses
};
export default CabinetSlice.reducer;
