import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../../hooks";

import {
  StyledCategory,
  StyledImageWrapper,
  StyledItemContent,
  StyledItemDate,
  StyledItemDescription,
  StyledItemTitle,
  StyledRelatedItem
} from "./RelatedItem.styled";

export const RelatedItem = ({ data }) => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  return (
    <StyledRelatedItem>
      <StyledImageWrapper>
        <Link to={`${hrefLang}/blog/${data.alias}/`}>
          <picture>
            <source
              srcSet={data.coverImage || "/img/ui/blog-placeholder.svg"}
            />
            <img src={data.coverImage} loading="lazy" alt={data.title} />
          </picture>
        </Link>
      </StyledImageWrapper>
      <StyledItemContent>
        <div>
          <StyledCategory>
            {data.category
              ? t("blogPage.item.categories.article")
              : t("blogPage.item.categories.news")}
          </StyledCategory>
          <StyledItemTitle tag="h3">
            <Link to={`${hrefLang}/blog/${data.alias}/`}>{data.title}</Link>
          </StyledItemTitle>
          {data.description && (
            <StyledItemDescription>{data.description}</StyledItemDescription>
          )}
        </div>
        <StyledItemDate>
          <Moment format="MMMM DD, YYYY">
            {data.creationDate && new Date(data.creationDate)}
          </Moment>
        </StyledItemDate>
      </StyledItemContent>
    </StyledRelatedItem>
  );
};

RelatedItem.propTypes = {
  data: PT.shape({})
};
