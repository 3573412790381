/* eslint-disable no-undef */
import { useEffect, useState } from "react";

import { saveAs } from "file-saver";
import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { toast } from "react-toastify";

import { Uploader } from "../../../../../../components/common/New/Uploader/Uploader";
import { FormInput } from "../../../../../../components/forms/New/FormInput/FormInput";
import { FormSelect } from "../../../../../../components/forms/New/FormSelect/FormSelect";
import Button from "../../../../../../components/ui/New/Button/Button";
import { useSeoBlock } from "../../../../../../hooks";
import { ApiService } from "../../../../../../services";
import { serviceSelectOptions } from "../../../../../../utils/constants";
import { CardInfo } from "../../../components/CardInfo/CardInfo";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import {
  BlockLogoStyledBody,
  BlockLogoStyledBodyRow,
  StyledInstructionButtons,
  StyledInstructionText
} from "./BlockLogo.styled";

export const BlockLogo = ({
  setFiles,
  croppedFileObjects,
  setCroppedFileObjects,
  validateData,
  setValidateData,
  setDefaultValues
}) => {
  const { t } = useTranslation();
  const { watch, setError, clearErrors, getValues } = useFormContext();
  const { errors } = useFormState();
  const link = watch("link");
  const [vatidated, setValidated] = useState(false);
  const [revalidate, setRevalidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const { data } = useSeoBlock(["validateSiteInstruction"]);

  const generateFileHandler = () => {
    setLoadingFile(true);
    ApiService.getValidateFile(validateData?.validationKey)
      .then((res) => {
        if (res?.status !== 200) {
          throw new Error("Error while downloading file");
        }
        const file = new File(
          [res?.data],
          `${validateData?.validationToken}.txt`,
          {
            type: "text/plain;charset=utf-8"
          }
        );
        saveAs(file);
      })
      .catch(() => {
        toast.error(
          t("dashboard.services.addEdit.blocks.logo.downloadFileError")
        );
      })
      .finally(() => {
        setLoadingFile(false);
      });
  };

  const checkSite = (signal, isClick) => {
    const currentLink = getValues("link");
    ApiService.checkSite(validateData?.validationKey, currentLink, signal)
      .then((res) => {
        if (res?.data === true) {
          setValidateData({
            ...validateData,
            valid: true
          });
          toast.success(
            t("dashboard.services.addEdit.blocks.logo.checkSuccess")
          );
          setValidated(false);
          setRevalidate(false);
        } else if (isClick) {
          toast.warning(
            t("dashboard.services.addEdit.blocks.logo.checkError"),
            {
              autoClose: 15000,
              hideProgressBar: false,
              pauseOnHover: true,
              closeOnClick: true,
              onClose: () => {
                setValidated(false);
                setRevalidate(true);
              }
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkHandler = () => {
    if (!link) {
      setError("link", {
        type: "required",
        message: t("forms.link.rules.required")
      });
      return;
    }
    if (
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
        link
      )
    ) {
      setValidated(true);
      setLoading(true);
    } else {
      setError("link", {
        type: "url",
        message: t("forms.link.rules.url")
      });
    }
  };

  useEffect(() => {
    let interval;
    const controller = new AbortController();
    if (vatidated) {
      checkSite(controller.signal, true);
    } else if (revalidate) {
      interval = setInterval(() => {
        checkSite(controller.signal);
      }, 10000);
    }
    return () => {
      clearInterval(interval);
      controller?.abort?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revalidate, vatidated]);

  useEffect(() => {
    if (validateData) {
      if (link && !"https://".includes(link) && !"http://".includes(link)) {
        const isHttp = link.includes("http://");
        setValidateData((prevState) => ({
          ...prevState,
          validationFileUrl: `http${isHttp ? "" : "s"}://${link
            .replace("http://", "")
            .replace("https://", "")}/rating-${prevState?.validationKey}.txt`
        }));
      } else {
        setValidateData((prevState) => ({
          ...prevState,
          valid: false,
          validationFileUrl: `https://{{your-site-url}}/rating-${prevState?.validationKey}.txt`
        }));
      }
    }
    clearErrors("link");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.logo.title")}
      subtitle={t("dashboard.services.addEdit.blocks.logo.subtitle")}
    >
      <BlockLogoStyledBody>
        <Uploader
          onUpload={setFiles}
          defaultImage={validateData?.image}
          setDefaultValues={setDefaultValues}
          croppedFileObjects={croppedFileObjects}
          setCroppedFileObjects={setCroppedFileObjects}
        />

        <BlockLogoStyledBodyRow>
          <FormInput
            name={"link"}
            type={"text"}
            label={t("forms.link.label")}
            placeholder={t("forms.link.placeholder")}
            rules={{
              required: t("forms.link.rules.required")
            }}
            showError
            showValidated
            isValidated={
              validateData?.valid &&
              validateData?.link &&
              validateData?.link === link
            }
          />

          <FormSelect
            name={"category"}
            options={serviceSelectOptions}
            label={t("forms.linkCategory.label")}
            placeholder={t("forms.linkCategory.placeholder")}
            defaultValue={serviceSelectOptions[0].value}
          />
        </BlockLogoStyledBodyRow>

        {(!validateData?.valid || validateData?.link !== link || !link) && (
          <CardInfo
            title={
              data?.validateSiteInstruction?.title?.content ||
              t("dashboard.services.addEdit.blocks.logo.instruction.title")
            }
            noMargin
          >
            {data?.validateSiteInstruction?.instruction?.content &&
              validateData?.validationMetaTag && (
                <StyledInstructionText
                  dangerouslySetInnerHTML={{
                    __html: data?.validateSiteInstruction?.instruction?.content
                      .replace(
                        "{{ #meta-tag}}",
                        validateData?.validationMetaTag
                          .replace(/</g, "&lt;")
                          .replace(/>/g, "&gt;")
                      )
                      .replace(
                        "{{ #file-url}}",
                        validateData?.validationFileUrl
                      )
                  }}
                />
              )}
            {validateData?.validationNextDate && (
              <StyledInstructionText>
                <p>
                  <b>{t("validation.invalid.panel.nextData.title")}</b>{" "}
                  <Moment format="DD.MM.YYYY HH:mm">
                    {new Date(validateData?.validationNextDate)}
                  </Moment>
                </p>
              </StyledInstructionText>
            )}

            <StyledInstructionButtons>
              <Button
                size={"md"}
                onClick={checkHandler}
                loading={loading}
                disabled={vatidated || errors?.link}
              >
                {t("dashboard.services.addEdit.blocks.logo.check")}
              </Button>

              <Button
                size={"md"}
                variant={"outlined"}
                className="button_download_file"
                onClick={generateFileHandler}
                loading={loadingFile}
              >
                {t("dashboard.services.addEdit.blocks.logo.download")}
              </Button>
            </StyledInstructionButtons>
          </CardInfo>
        )}
      </BlockLogoStyledBody>
    </FormBlock>
  );
};
