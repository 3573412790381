import "./FormTabs.scss";

export const FormTabs = ({ tabList = [], activeTab, changeTab, position }) => {
  const defineClassname = () => {
    let className = "form-tabs";

    switch (position) {
      case "center": {
        className += " form-tabs__position--center";
        break;
      }
      case "left": {
        className += " form-tabs__position--left";
        break;
      }
      case "right": {
        className += " form-tabs__position--right";
        break;
      }
      default: {
        break;
      }
    }

    return className;
  };

  return (
    <ul className={defineClassname()}>
      {tabList.map(({ value }) => (
        <li key={value}>
          <button
            className={value === activeTab ? "active" : ""}
            onClick={changeTab(value)}
            type="button"
          >
            {value.toUpperCase()}
          </button>
        </li>
      ))}
    </ul>
  );
};
