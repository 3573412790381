import "moment/locale/ru";
import "moment/locale/uk";
import PT from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import Icon from "../../../../../../../components/ui/New/Icon/Icon";
import { useLangUrlDefault } from "../../../../../../../hooks";

import {
  SkeletonDate,
  SkeletonViews,
  StyledInfoItem,
  StyledInfoList
} from "./Info.styled";

export const Info = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  return (
    <StyledInfoList>
      <StyledInfoItem>
        <Icon name="calendar" size="lg" />
        {data?.creationDate ? (
          <Moment
            format="MMMM DD, YYYY"
            locale={queryLang === "ua" ? "uk" : queryLang}
            suppressHydrationWarning
          >
            {new Date(data.creationDate)}
          </Moment>
        ) : (
          <SkeletonDate />
        )}
      </StyledInfoItem>
      <StyledInfoItem>
        <Icon name="eye" size="lg" />
        {data?.viewCount || data?.viewCount === 0 ? (
          `${data?.viewCount} ${t("blogInnerPage.articleBody.viewsTitle")}`
        ) : (
          <SkeletonViews />
        )}
      </StyledInfoItem>
    </StyledInfoList>
  );
};

Info.propTypes = {
  data: PT.shape({
    viewCount: PT.number
  })
};
