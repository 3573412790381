import PT from "prop-types";

import { StyledSeparator } from "./Separator.styled";

export const Separator = ({ margin = 0, marginLg = 0 }) => (
  <StyledSeparator margin={margin} marginLg={marginLg} />
);

Separator.propTypes = {
  margin: PT.number,
  marginLg: PT.number
};
