import PT from "prop-types";

import { convertCountryCodeAlpha3ToAlpha2 } from "../../../../utils/helpers";

import { LabelCountryFlag, LabelCountryWrapper } from "./LabelCounrry.styled";

export const LabelCountry = ({ code, className, children }) => {
  let value = code?.toLowerCase();
  if (value?.length !== 2) {
    value = convertCountryCodeAlpha3ToAlpha2(code)?.toLowerCase();
  }

  return (
    <LabelCountryWrapper className={className}>
      <LabelCountryFlag className={`fl fl-${value}`} />
      {children}
    </LabelCountryWrapper>
  );
};

LabelCountry.propTypes = {
  code: PT.string.isRequired,
  className: PT.string,
  children: PT.node.isRequired
};

export default LabelCountry;
