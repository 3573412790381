import styled from "@emotion/styled";

export const StyledSidebarButton = styled.button`
  position: fixed;
  top: 50%;
  z-index: 150;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px 4px 4px 8px;
  gap: 4px;
  border: none;
  border-radius: 0px 0px 8px 8px;
  background-color: ${({ theme }) => theme.colors["neutral-800"]};
  color: ${({ theme }) => theme.colors.white};
  transform: translateY(-50%) rotate(90deg);
  right: ${({ isOpen }) => (isOpen ? "253px" : "-48px")};
  transition: right ${(p) => p.theme.transitions.default};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  text-transform: uppercase;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }

  & > svg {
    width: 20px;
    height: 20px;
    ${({ isOpen }) => (isOpen ? "transform: rotate(180deg);" : "")}
  }
`;
