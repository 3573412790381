import { createSlice } from "@reduxjs/toolkit";

import { translationInitialState } from "./Translation.initialState";
import {
  setCurrentLangReducer,
  setLanguagesReducer
} from "./Translation.reducer";
import { setCurrentLang, setLanguages } from "./Translation.thunks";

const TranslationSlice = createSlice({
  name: "translation",
  initialState: translationInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setCurrentLang, setCurrentLangReducer)
      .addCase(setLanguages, setLanguagesReducer)
});

export const TranslationReducerActions = {
  setCurrentLang,
  setLanguages
};
export default TranslationSlice.reducer;
