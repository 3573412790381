import { useEffect, useState } from "react";

// eslint-disable-next-line import/namespace
import { Chart, registerables } from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Select from "../../../../../../components/ui/New/Select/Select";
import { StyledSkeleton } from "../../../../../../components/ui/New/Skeleton/components/SkeletonTableComponents.styled";
import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { TabTitle } from "../TabTitle/TabTitle";

import {
  StyledRatingBoxSkeleton,
  StyledRatingContainer,
  StyledRatingFilter,
  StyledRatingFilterLabel,
  StyledRatingFilterSelectBox
} from "./Rating.styled";

Chart.register(...registerables);

export const Rating = ({ active }) => {
  // **Props
  const { alias } = useParams();
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const currentMonth = new Date().getMonth() + 1;
  const monthes = t("proxySitePage.content.rating.labels", {
    returnObjects: true
  });
  const startArray = monthes?.slice(0, currentMonth);
  const endArray = monthes?.slice(currentMonth, 12);

  const options = [
    {
      label: t("proxySitePage.content.rating.filters.month"),
      value: "days"
    },
    {
      label: t("proxySitePage.content.rating.filters.general"),
      value: "months"
    }
  ];

  const getChartData = (data, stepRating) => ({
    labels:
      stepRating === "days"
        ? [...Array(30)]
            .map((it, i) => {
              if (i === 0) {
                return moment().format("DD.MM");
              }

              return moment().add(-i, "day").format("DD.MM");
            })
            .reverse()
        : [...endArray, ...startArray],
    datasets: [
      {
        label: t("proxySitePage.content.rating.label"),
        data,
        fill: false,
        // backgroundColor: "transparent",
        borderColor: "#C8EE85",
        pointStyle: "line"
      }
    ]
  });

  // **Redux state
  const { site, rating } = useSelector(getAllProxySite);
  // **Dispatch
  const { getProxySiteRating } = useDispatchedActions();
  // **Local state
  const [stepRating, setStepRating] = useState("days");
  const [chartData, setChartData] = useState(getChartData([], stepRating));
  const [mounted, setMounted] = useState(false);

  const clickStepRatingHandle = (value) => setStepRating(value);

  useEffect(() => {
    if ((alias !== site.data?.alias || stepRating) && !rating.isDataLoaded) {
      getProxySiteRating({ siteId: alias, step: stepRating });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias, stepRating, rating.isDataLoaded]);

  useEffect(() => {
    setChartData(getChartData(rating.data, stepRating));
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating, queryLang, stepRating]);
  return (
    <StyledRatingContainer active={active}>
      <TabTitle
        title={t("proxySitePage.about.rating.title")}
        imgSrc={"/img/site-page/Rating.png"}
      />
      <StyledRatingFilter>
        <StyledRatingFilterLabel>
          {t("proxySitePage.about.rating.color")}
        </StyledRatingFilterLabel>
        <StyledRatingFilterSelectBox>
          <Select
            options={options}
            value={stepRating}
            onChange={clickStepRatingHandle}
            size={"md"}
          />
        </StyledRatingFilterSelectBox>
      </StyledRatingFilter>
      {rating.isDataLoaded && mounted ? (
        <Line
          data={chartData}
          options={{
            plugins: {
              legend: {
                display: false
              }
            },
            scales: {
              x: {
                border: {
                  display: false
                },
                grid: {
                  display: false
                },
                ticks: {
                  color: "#C5CDD9",
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%"
                }
              },
              y: {
                suggestedMin: 3.0,

                suggestedMax: 5.0,
                ticks: {
                  color: "#C5CDD9",
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "150%"
                },
                grid: {
                  color: "#F2F4F7"
                },
                border: {
                  display: false
                }
              }
            }
          }}
        />
      ) : (
        <StyledRatingBoxSkeleton>
          <StyledSkeleton width={"100%"} height={"100%"} />
        </StyledRatingBoxSkeleton>
      )}
    </StyledRatingContainer>
  );
};
