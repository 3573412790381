import styled from "@emotion/styled";

import FAQ from "../../../../components/common/New/FAQ/FAQ";
import { HowToFix } from "../../../../components/common/New/HowTofix/HowToFix";
import { Main } from "../../../../components/common/New/Main/Main";

export const RestyledMainSection = styled(Main)`
  padding-bottom: 90px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 190px;
    min-height: 560px;
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      top: 37px;
      width: 546px;
      height: 244px;
      right: -70px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      width: 683px;
      height: 305px;
      right: -150px;
    }
    // @media (min-width: 1530px) {
    //   right: 5vw;
    // }

    img {
      margin-top: -20px;
    }
  }
`;

export const RestuledHowToFix = styled(HowToFix)`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    & .animation-wrapper {
      display: block;
      width: 612px;
      min-width: 612px;
      height: 548px;
    }
  }
`;

export const RestyledFAQ = styled(FAQ)`
  img {
    width: 100%;
    max-width: 409px;
  }
`;
