export const mockEverCookieData = [
  {
    name: "userData:",
    value: "undefined"
  },
  {
    name: "cookieData:",
    value: "undefined"
  },
  {
    name: "localData:",
    value: "undefined"
  },
  {
    name: "globalData:",
    value: "undefined"
  },
  {
    name: "sessionData:",
    value: "undefined"
  },
  {
    name: "windowData:",
    value: "undefined"
  },
  {
    name: "pngData:",
    value: "undefined"
  },
  {
    name: "dbData:",
    value: "undefined"
  },
  {
    name: "etagData:",
    value: "undefined"
  },
  {
    name: "cacheData:",
    value: "undefined"
  },
  {
    name: "lsoData:",
    value: "undefined"
  },
  {
    name: "slData:",
    value: "undefined"
  }
];
