export const getGoalsForSelect = (goals) => {
  if (goals?.length === 0) {
    return [];
  }

  return goals.map((goal) => ({
    value: goal?.id,
    label: goal?.name
  }));
};
