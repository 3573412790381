import { useState } from "react";

import PT from "prop-types";

import { StyledIcon, StyledInput } from "./Search.styled";

export const Search = ({
  variant = "default",
  onChange,
  className,
  onClear,
  value,
  placeholder = "Search..."
}) => {
  const [showClear, setShowClear] = useState(false);
  const isValue = !!value && value !== "";

  const handleSearch = (e) => {
    onChange?.(e.currentTarget.value);
    if (e.currentTarget.value?.trim() !== "") {
      if (!showClear) setShowClear(true);
    } else if (showClear) setShowClear(false);
  };

  const handleClear = () => {
    onChange?.("");
    setShowClear(false);
    if (onClear) {
      onClear();
    }
  };

  return (
    <StyledInput
      className={className}
      placeholder={placeholder}
      onChange={handleSearch}
      variant={variant}
      value={value}
      startAdornment={<StyledIcon name="search" position="start" />}
      endAdornment={
        <StyledIcon
          name="x"
          position="end"
          hidden={!showClear && !isValue}
          onClick={handleClear}
          role="button"
        />
      }
    />
  );
};

Search.propTypes = {
  className: PT.string,
  onChange: PT.func.isRequired,
  onClear: PT.func,
  placeholder: PT.string,
  variant: PT.oneOf(["outlined", "default", "outlined-dark"])
};

export default Search;
