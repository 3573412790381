import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { RatingStar } from "../../icons";

import "./Rating.scss";

const returnStars = () => {
  const data = [];

  for (let i = 0; i < 5; i += 1) {
    data.push(
      <span key={i}>
        <RatingStar />
      </span>
    );
  }

  return data;
};

export const Rating = ({
  rating,
  getRating = null,
  position = "center",
  margin,
  isCaptionVisible = true,
  isClickable = false
}) => {
  const { t } = useTranslation();

  // **Local state
  const [ratingWidth, setRatingWidth] = useState(0);
  const [currentRatingWidth, setCurrentRatingWidth] = useState(0);

  const ratingHandler = (e) => {
    if (isClickable) {
      if (e.type === "mousemove") {
        const oneStar = 20;
        const twoStars = 40;
        const threeStars = 60;
        const fourStars = 80;
        const fiveStars = 100;
        const minValue = e.target.getBoundingClientRect().x;
        const { width } = e.target.getBoundingClientRect();

        let result = ((e.clientX - minValue) / (width / 100)).toFixed(0);

        if (result <= oneStar) result = oneStar;
        else if (result > oneStar && result <= twoStars) result = twoStars;
        else if (result > twoStars && result <= threeStars) result = threeStars;
        else if (result > threeStars && result <= fourStars) result = fourStars;
        else result = fiveStars;

        setRatingWidth(result);
      } else if (e.type === "mouseout") {
        setRatingWidth(currentRatingWidth);
      } else if (e.type === "click") {
        setCurrentRatingWidth(ratingWidth);
        setRatingWidth(currentRatingWidth);
        getRating(ratingWidth / 20);
      }
    }
  };

  const defineCaption = (width) => {
    // const {
    //   notRated,
    //   halfStar,
    //   oneStar,
    //   oneHalfStars,
    //   twoStars,
    //   twoHalfStars,
    //   threeStars,
    //   threeHalfStars,
    //   fourStars,
    //   fourHalfStars,
    //   fiveStars,
    //   stars: otherStars
    // } = t("proxyPage.content.table.rating");
    const stars = width / 20;

    if (stars <= 0.1) {
      return t("proxyPage.content.table.rating.notRated");
    }

    if (stars >= 0.4 && stars <= 0.6) {
      return t("proxyPage.content.table.rating.halfStar");
    }

    if (stars >= 0.9 && stars <= 1.1) {
      return t("proxyPage.content.table.rating.oneStar");
    }

    if (stars >= 1.4 && stars <= 1.6) {
      return t("proxyPage.content.table.rating.oneHalfStars");
    }

    if (stars >= 1.9 && stars <= 2.1) {
      return t("proxyPage.content.table.rating.twoStars");
    }

    if (stars >= 2.4 && stars <= 2.6) {
      return t("proxyPage.content.table.rating.twoHalfStars");
    }

    if (stars >= 2.9 && stars <= 3.1) {
      return t("proxyPage.content.table.rating.threeStars");
    }

    if (stars >= 3.4 && stars <= 3.6) {
      return t("proxyPage.content.table.rating.threeHalfStars");
    }

    if (stars >= 3.9 && stars <= 4.1) {
      return t("proxyPage.content.table.rating.fourStars");
    }

    if (stars >= 4.4 && stars <= 4.6) {
      return t("proxyPage.content.table.rating.fourHalfStars");
    }

    if (stars >= 4.85) {
      return t("proxyPage.content.table.rating.fiveStars");
    }

    return `${stars} ${t("proxyPage.content.table.rating.stars")}`;
  };

  const defineClassname = () => {
    let className = "rating";

    switch (position) {
      case "left": {
        className += " rating__position--left";
        break;
      }
      case "center": {
        className += " rating__position--center";
        break;
      }
      default: {
        className += " rating__position--center";
        break;
      }
    }

    switch (margin) {
      case "no": {
        break;
      }
      default: {
        className += " rating__margin--default";
        break;
      }
    }

    if (isClickable) {
      className += " rating--clickable";
    }

    return className;
  };

  useEffect(() => {
    if (rating) {
      setRatingWidth(rating * 20);
      setCurrentRatingWidth(rating * 20);
    }

    if (rating && getRating) {
      getRating(rating);
    }
  }, [rating, getRating]);

  return (
    <div className={defineClassname()}>
      <div className="rating__wrapper">
        <button
          className="rating__stars rating__stars--empty"
          onMouseMove={ratingHandler}
          onMouseOut={ratingHandler}
          onBlur={ratingHandler}
          onClick={ratingHandler}
          type="button"
        >
          {returnStars().map((item) => item)}
        </button>
        <div
          className="rating__stars rating__stars--filled"
          style={{ width: `${ratingWidth}%` }}
        >
          {returnStars().map((item) => item)}
        </div>
      </div>
      {isCaptionVisible && (
        <div className="rating__caption">{defineCaption(ratingWidth)}</div>
      )}
    </div>
  );
};
