// import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import { Button } from "../../../../../../components/ui/Button/Button";
// import { SkeletonTable } from "../../../../../../components/ui/Skeleton/SkeletonTable/SkeletonTable";
import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import {
  convertCountryCodeAlpha3ToAlpha2,
  getCountriesById,
  getParamsUrlForFilter,
  time
} from "../../../../../../utils/helpers";

import { NameCell } from "./NameCell/NameCell";

import "./ProxyTable.scss";

export const ProxyTable = ({ params, isLoading, tableDate }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  // const [tableClassName, setTableClassName] = useState();

  // **Redux state
  const { countries, proxyTypes } = useSelector(getAllContent);

  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();

  const columns = [
    {
      cell: (row) =>
        row.topSite ? (
          <div className="proxy-table__label">
            {t("proxyPage.content.table.top")}
          </div>
        ) : null,
      minWidth: "0",
      maxWidth: "0",
      compact: true
    },
    {
      name: "№",
      selector: (row, index) => index + 1 + params.page * params.size,
      maxWidth: "7rem",
      minWidth: "7rem",
      center: true
    },
    {
      name: t("proxyPage.content.table.name.name"),
      cell: (row) => <NameCell row={row} />,
      minWidth: "21rem",
      maxWidth: "21rem"
    },
    {
      name: t("proxyPage.content.table.benefits.title"),
      cell: (row) => {
        const { benefits } = row;
        const benefitsKeys = Object.keys(benefits);
        const styleForListItem = {
          position: "relative",
          paddingLeft: "2rem"
        };
        const styleForIcon = {
          position: "absolute",
          top: -2,
          left: 0,
          color: "#ffdf08"
        };

        return benefitsKeys?.length > 0 ? (
          <ul className="proxy-table__type">
            {benefitsKeys.map((key, i) =>
              benefits[key] ? (
                <li key={`benefit-${key}-${i}`} style={styleForListItem}>
                  <span style={styleForIcon}>&#10004;</span>{" "}
                  {t(`proxyPage.content.table.benefits.${key}`)}
                </li>
              ) : (
                <li key={`benefit-${key}-${i}`} style={styleForListItem}>
                  <span
                    style={{
                      ...styleForIcon,
                      color: "#c4c4c4"
                    }}
                  >
                    &#10006;
                  </span>{" "}
                  {t(`proxyPage.content.table.benefits.${key}`)}
                </li>
              )
            )}
          </ul>
        ) : (
          <div>{t("proxyPage.content.table.benefits.noBenefits")}</div>
        );
      },
      minWidth: "21rem"
    },
    {
      name: t("proxyPage.content.table.type.title"),
      cell: (row) =>
        row.proxyType?.length > 0 ? (
          <ul className="proxy-table__type">
            {row.proxyType.map((type) => (
              <li key={type}>
                {
                  proxyTypes.data?.[queryLang]?.find((it) => it.type === type)
                    ?.name
                }
              </li>
            ))}
          </ul>
        ) : (
          <div>{t("proxyPage.content.table.type.noContent")}</div>
        ),
      maxWidth: "17rem",
      minWidth: "12rem"
    },
    {
      name: t("proxyPage.content.table.geo.title"),
      cell: (row) => {
        const countriesList = getCountriesById(row.countries, countries.data);
        const hasCountries = countriesList?.length > 0;
        return hasCountries > 0 ? (
          <div className="proxy-table__geo">
            <div className="proxy-table__geo-title">
              {countriesList?.length} {t("proxyPage.content.table.geo.title")}
            </div>
            <ul className="proxy-table__geo-list">
              {countriesList.map((country) => {
                const newCountry = country?.urlParam;
                const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                  country?.code
                ).toLowerCase();
                const name = queryLang ? country?.localization[queryLang] : "";

                return (
                  <li key={country?.id}>
                    <Link
                      to={`${hrefLang}/proxy/${getParamsUrlForFilter(
                        newCountry,
                        searchParams.get("fg"),
                        searchParams.get("fpt")
                          ? [searchParams.get("fpt")]
                          : [],
                        searchParams.get("s")
                      )}`}
                      onClick={() => setActiveFilters({ fc: newCountry })}
                      title={name}
                    >
                      {alpha2 !== "default" ? (
                        <span className={`fi fi-${alpha2}`}></span>
                      ) : (
                        <img
                          src="/img/flags/default.svg"
                          width={20}
                          height={20}
                          loading="lazy"
                          alt={name}
                        />
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div>{t("proxyPage.content.table.geo.noContent")}</div>
        );
      },
      maxWidth: "20rem",
      minWidth: "14rem",
      style: {
        overflow: "auto",
        alignItems: "flex-start",
        marginTop: "1rem",
        marginBottom: "1rem",
        paddingTop: "0",
        paddingBottom: "0",
        maxHeight: "15rem"
      }
    },
    {
      name: t("proxyPage.content.table.minPrice.title"),
      cell: (row) => `${row?.price} ${row?.currencyName}`,
      maxWidth: "10.5rem",
      minWidth: "10.5rem"
    },
    {
      name: t("proxyPage.content.table.minRent.title"),
      cell: (row) =>
        time(
          row?.minRentPeriod?.periodName,
          row?.minRentPeriod?.periodValue,
          queryLang,
          t("times", { returnObjects: true })
        ),
      maxWidth: "13rem",
      minWidth: "13rem"
    },
    {
      name: t("proxyPage.content.table.api.title"),
      cell: (row) =>
        row?.api
          ? t("proxyPage.content.table.api.yes")
          : t("proxyPage.content.table.api.no"),
      maxWidth: "7.5rem",
      minWidth: "7.5rem"
    },
    {
      name: t("proxyPage.content.table.site.title"),
      cell: (row) => (
        <div className="proxy-table__site">
          <Button
            size="small"
            type="solid"
            link
            href={`${hrefLang}/proxy/${row.alias}/`}
          >
            {t("proxyPage.content.table.site.btn")}
          </Button>
          {row.articleLink && (
            <Link className="proxy-table__site-overview" to={row.articleLink}>
              {t("proxyPage.content.table.site.readOverview")}
            </Link>
          )}
        </div>
      ),
      maxWidth: "14.5rem",
      minWidth: "14.5rem"
    }
  ];

  const customStyles = {
    head: {
      style: {
        fontSize: "1.4rem",
        fontWeight: "600",
        borderBottomWidth: "none",
        borderBottomColor: "none",
        borderBottomStyle: "none"
      }
    },
    headRow: {
      style: {
        borderBottomWidth: "none",
        borderBottomColor: "none",
        borderBottomStyle: "none"
      }
    },
    headCells: {
      style: {
        paddingLeft: "0.3rem",
        paddingRight: "0.3rem"
      },
      draggingStyle: {
        cursor: "move"
      }
    },
    cells: {
      style: {
        borderRight: "1px solid var(--clr-ui-border-200)",
        padding: "1.5rem 2rem"
      }
    },
    rows: {
      style: {
        position: "relative",
        fontSize: "1.4rem",
        "&:not(:last-of-type)": {
          borderBottomWidth: "2px",
          borderBottomColor: "transparent",
          borderBottomStyle: "solid"
        },
        "&:last-child": {
          marginBottom: "1rem"
        },
        boxShadow: "0 2px 10px rgba(196, 196, 196, 0.25)",
        border: "2px solid transparent",
        transition: "border-color var(--trasition)",
        "&:not(:last-child)": {
          marginBottom: "2rem"
        },
        "&:hover,&:focus": {
          borderColor: "var(--clr-primary-400)",
          transition: "border-color var(--trasition)"
        }
      }
    }
  };

  // useEffect(() => {
  //   setTableClassName("");
  // }, []);

  return (
    <>
      <DataTable
        // className={tableClassName}
        customStyles={customStyles}
        columns={columns}
        data={tableDate}
        noDataComponent={
          !isLoading && (
            <div style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
              {t("proxyPage.content.table.noDataComponent")}
            </div>
          )
        }
        // progressPending={isLoading}
        // progressComponent={<SkeletonTable cells={10} />}
      />
    </>
  );
};
