import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllUser } from "../../../../../store/reducers/UserReducer/User.selectors";

import { EditReview } from "./EditReview/EditReview";
import { Feedback } from "./Feedback/Feedback";
import { Response } from "./Response/Response";
import { Voting } from "./Voting/Voting";

export const ReplyPart = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler,
  complaint,
  toggleReview,
  isRootReview,
  isReviewsOpened
}) => {
  // **Redux state
  const { user, isUserAuthenticated } = useSelector(getAllUser);
  const { t } = useTranslation();

  // **Local state
  const [isResponseOpened, setIsResponseOpened] = useState(false);

  // **Ref
  const responseRef = useRef(null);

  const toggleAccordion = () => {
    if (!responseRef.current) return;

    const height = responseRef.current.scrollHeight;

    if (isResponseOpened) {
      requestAnimationFrame(() => {
        responseRef.current.style.height = `${height - 15}px`;

        requestAnimationFrame(() => {
          responseRef.current.style.height = "0px";
        });
      });

      setIsResponseOpened(false);
    } else {
      responseRef.current.style.height = `${height}px`;

      setIsResponseOpened(true);
    }
  };

  const transitionEndHandler = () => {
    if (!responseRef.current) return;

    if (isResponseOpened) {
      responseRef.current.style.height = "auto";
    } else {
      responseRef.current.style.height = "0px";
    }
  };

  const defineCorrectFeedbackForm = () => {
    if (complaint) {
      return null;
    }

    if (review.karma || review.karma === 0) {
      return (
        <Voting
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
          review={review}
          changeHandler={changeHandler}
        />
      );
    } else {
      return (
        <div className="reviews__edit-wrap">
          {isUserAuthenticated && review?.userId === user?.uid && (
            <EditReview
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              review={review}
              changeHandler={changeHandler}
            />
          )}
          <Feedback
            captchaRef={captchaRef}
            setTokenCaptcha={setTokenCaptcha}
            review={review}
            changeHandler={changeHandler}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    if (!responseRef.current) return;

    if (!isResponseOpened) {
      responseRef.current.style.height = "0px";
    } else {
      responseRef.current.style.height = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="reviews__bottom">
        <button className="reviews__reply" onClick={toggleAccordion}>
          {isResponseOpened
            ? t("reviews.reply.cancel")
            : t("reviews.reply.apply")}
        </button>
        {isRootReview && (
          <button className="reviews__reply" onClick={toggleReview}>
            {isReviewsOpened
              ? t("reviews.reply.toggle.close")
              : t("reviews.reply.toggle.open")}
          </button>
        )}
        {defineCorrectFeedbackForm()}
      </div>
      <Response
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
        ref={responseRef}
        review={review}
        isOpen={isResponseOpened}
        toggleAccordion={toggleAccordion}
        changeHandler={changeHandler}
        onTransitionEnd={transitionEndHandler}
      />
    </>
  );
};
