export const getCurrency = (countries, value, byId = false) => {
  let country = {};

  if (byId) {
    country = countries.find((entity) => entity.id.toLowerCase() === value);
  } else {
    country = countries.find(
      (entity) => entity.code.toLowerCase() === value.toLowerCase()
    );
  }

  if (!country) {
    return;
  }

  return {
    id: country?.id,
    label: country.code,
    value: country.id
  };
};
