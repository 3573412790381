import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Chip from "../../../../../components/ui/New/Chip/Chip";
import { useDispatchedActions, useLangUrlDefault } from "../../../../../hooks";

import {
  StyledChildrenWrapper,
  StyledIcon,
  StyledList,
  StyledListDescription,
  StyledListItem,
  StyledListLink,
  StyledListTitle,
  StyledNavigationItem,
  StyledTitle,
  StyledTitleWrapper
} from "./Navigation.styled";

const NavigationItem = ({
  title,
  list = [],
  children,
  isOpened,
  onClick,
  className,
  mobile,
  handleServiceClick
}) => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();

  const location = useLocation();

  // **Dispatch
  const { setBurgerMenu, setServiceMenu } = useDispatchedActions();

  const closeMenu = () => {
    setBurgerMenu(false);
    setServiceMenu(false);
  };

  const isLinkActive = (el) => {
    if (el.search) {
      return (
        location?.pathname === `${hrefLang}/${el.route}/` &&
        location?.search === el.search
      );
    } else {
      return location?.pathname === `${hrefLang}/${el.route}/`;
    }
  };

  const renderTitle = (el) => {
    if (title === "resources") {
      return t(`navigation.${el.translation}`);
    } else if (title === "services" || title === "proxies") {
      return el?.title;
    } else return t(`navigation.${el.translation}.title`);
  };

  const renderDescription = (el) => {
    if (title === "services" || title === "proxies") {
      return el?.description;
    } else return t(`navigation.${el.translation}.description`);
  };

  const listMemo = (
    <StyledList isOpened={isOpened} id={title}>
      {list?.map((el, i) => (
        <StyledListItem isOpened={isOpened} key={i}>
          <StyledListLink
            onClick={
              handleServiceClick && el?.useCountries
                ? handleServiceClick
                : closeMenu
            }
            to={
              el.search
                ? `${hrefLang}/${el.route}/${el.search}`
                : `${hrefLang}/${el.route}/`
            }
            active={isLinkActive(el) ? "true" : "false"}
          >
            <StyledListTitle>
              {renderTitle(el)}
              {el.chip && <Chip label={el.chip} />}
            </StyledListTitle>
            {el.description && (
              <StyledListDescription>
                {renderDescription(el)}
              </StyledListDescription>
            )}
          </StyledListLink>
        </StyledListItem>
      ))}
    </StyledList>
  );

  return (
    <StyledNavigationItem isOpened={isOpened} className={className}>
      <StyledTitleWrapper isOpened={isOpened} {...(mobile && { onClick })}>
        <StyledTitle isOpened={isOpened}>
          {t(`navigation.${title}`)}
        </StyledTitle>
        <StyledIcon
          name={isOpened ? "arrowUp" : "arrowDown"}
          isOpened={isOpened}
        />
      </StyledTitleWrapper>
      {children ? (
        <StyledChildrenWrapper isOpened={isOpened}>
          {children}
        </StyledChildrenWrapper>
      ) : (
        listMemo
      )}
    </StyledNavigationItem>
  );
};

NavigationItem.propTypes = {
  title: PT.string.isRequired,
  list: PT.arrayOf(PT.shape({})),
  children: PT.node,
  isOpened: PT.bool,
  onClick: PT.func,
  className: PT.string
};

export default NavigationItem;
