import { createSlice } from "@reduxjs/toolkit";

import { promocodesInitialState } from "./Promocodes.initialState";
import {
  setAllPromocodesReducer,
  setAlternatePromocodesReducer,
  setPromocodeReducer,
  setPromocodesPrevParamsReducer,
  setSpecialPromocodesReducer
} from "./Promocodes.reducer";
// Thunks
import {
  getAllPromocodes,
  getAlternatePromocodes,
  getPromocode,
  getSpecialPromocodes,
  setAllPromocodes,
  setAlternatePromocodes,
  setPromocode,
  setPromocodesPrevParams,
  setSpecialPromocodes
} from "./Promocodes.thunks";

const PromocodesSlice = createSlice({
  name: "promocodes",
  initialState: promocodesInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setPromocodesPrevParams, setPromocodesPrevParamsReducer)
      .addCase(setAllPromocodes, setAllPromocodesReducer)
      .addCase(setAlternatePromocodes, setAlternatePromocodesReducer)
      .addCase(setPromocode, setPromocodeReducer)
      .addCase(setSpecialPromocodes, setSpecialPromocodesReducer)

      // getAllPromocodes
      .addCase(getAllPromocodes.pending, (state) => {
        state.promocodes.isError = null;
        state.promocodes.isLoading = true;
      })
      .addCase(getAllPromocodes.fulfilled, (state, action) => {
        state.promocodes.isError = null;
        state.promocodes.data = action.payload;
        state.promocodes.isLoading = false;
      })
      .addCase(getAllPromocodes.rejected, (state, action) => {
        state.promocodes.isError = action.payload;
        state.promocodes.isLoading = false;
      })

      // getPromocode
      .addCase(getPromocode.pending, (state) => {
        state.promocode.isError = null;
        state.promocode.isLoading = true;
      })
      .addCase(getPromocode.fulfilled, (state, action) => {
        state.promocode.isError = null;
        state.promocode.data = action.payload;
        state.promocode.isLoading = false;
      })
      .addCase(getPromocode.rejected, (state, action) => {
        state.promocode.isError = action.payload;
        state.promocode.isLoading = false;
      })

      // getAlternatePromocodes
      .addCase(getAlternatePromocodes.pending, (state) => {
        state.alternatePromocodes.isError = null;
        state.alternatePromocodes.isLoading = true;
      })
      .addCase(getAlternatePromocodes.fulfilled, (state, action) => {
        state.alternatePromocodes.isError = null;
        state.alternatePromocodes.data = action.payload;
        state.alternatePromocodes.isLoading = false;
      })
      .addCase(getAlternatePromocodes.rejected, (state, action) => {
        state.alternatePromocodes.isError = action.payload;
        state.alternatePromocodes.isLoading = false;
      })

      // getSpecialPromocodes
      .addCase(getSpecialPromocodes.pending, (state) => {
        state.special.isError = null;
        state.special.isLoading = true;
      })
      .addCase(getSpecialPromocodes.fulfilled, (state, action) => {
        state.special.isError = null;
        state.special.data = action.payload;
        state.special.isLoading = false;
        state.special.isDataLoaded = true;
      })
      .addCase(getSpecialPromocodes.rejected, (state, action) => {
        state.special.isError = action.payload;
        state.special.isLoading = false;
      })
});

export const PromocodesReducerActions = {
  setPromocodesPrevParams,
  setAllPromocodes,
  setAlternatePromocodes,
  setPromocode,
  setSpecialPromocodes,
  getAllPromocodes,
  getPromocode,
  getAlternatePromocodes,
  getSpecialPromocodes
};
export default PromocodesSlice.reducer;
