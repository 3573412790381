import styled from "@emotion/styled";

export const StyledTableCheckbox = styled.input`
  flex: none;
  width: 24px;
  height: 24px;
  position: relative;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='2.5' y='2.5' width='19' height='19' rx='3.5' stroke='%23D8DAE0'/%3e%3c/svg%3e ");
  &:hover,
  &:focus {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='2.5' y='2.5' width='19' height='19' rx='3.5' stroke='%238FC846'/%3e%3c/svg%3e ");
  }

  &:indeterminate {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='2' y='2' width='20' height='20' rx='4' fill='%238FC846'/%3e%3crect x='7' y='11' width='10' height='2' rx='1' fill='white'/%3e%3c/svg%3e ");
  }

  &:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='2' y='2' width='20' height='20' rx='4' fill='%238FC846'/%3e%3cpath d='M18.8393 9.25584C19.1647 8.9304 19.1647 8.40277 18.8393 8.07733C18.5138 7.75189 17.9862 7.75189 17.6607 8.07733L10.75 14.9881L7.17259 11.4107C6.84715 11.0852 6.31951 11.0852 5.99408 11.4107C5.66864 11.7361 5.66864 12.2637 5.99408 12.5892L10.1607 16.7558C10.4862 17.0813 11.0138 17.0813 11.3393 16.7558L18.8393 9.25584Z' fill='white'/%3e%3c/svg%3e ");
  }
`;
