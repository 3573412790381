export const freeProxyInitialState = {
  freeProxy: {
    data: {
      totalElements: 0,
      totalPages: 0,
      content: [],
      skip: 0
    },
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  allFreeProxy: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  },
  cities: {
    data: [],
    isError: null,
    isLoading: false,
    isDataLoaded: false
  }
};
