export const setFaqContentReducer = (state, action) => {
  state.data = {
    ...state.data,
    [action.payload.languageCode]: {
      ...state.data[action.payload.languageCode],
      [action.payload.pageTag]: action.payload.data
    }
  };
  state.isDataLoaded = true;
};
