export const userInitialState = {
  user: {
    email: "",
    uid: "",
    picture: "",
    name: "",
    roles: []
  },
  isLogout: false,
  isUserAuthenticated: false,
  isRedirectToCabinet: false,
  isAuthfetched: false
};
