import { useState } from "react";

import { useTranslation } from "react-i18next";

import { ApiService } from "../../../../../../services";
import { ConfirmModal } from "../../../../Modals/ConfirmModal/ConfirmModal";
import { ReviewModal } from "../../../../Modals/ReviewModal/ReviewModal";

export const EditReview = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler
}) => {
  // **Local state
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  const { t } = useTranslation();

  const modalHandler = () => setIsModalOpened((prevState) => !prevState);

  const confirmModalHandler = () =>
    setIsConfirmModalOpened((prevState) => !prevState);

  return (
    <>
      <div className="reviews__edit-review">
        <button className="reviews__edit-review-edit" onClick={modalHandler}>
          <span>{t("modals.review.edit")}</span>
        </button>
        &nbsp; / &nbsp;
        <button
          className="reviews__edit-review-delete"
          onClick={confirmModalHandler}
        >
          <span>{t("modals.review.delete")}</span>
        </button>
      </div>
      <ReviewModal
        isEdit
        visible={isModalOpened}
        cancelHandler={modalHandler}
        captchaRef={captchaRef}
        setTokenCaptcha={setTokenCaptcha}
        reviewItem={review}
        afterSubmitHandler={changeHandler}
      />
      <ConfirmModal
        visible={isConfirmModalOpened}
        cancelHandler={confirmModalHandler}
        title={t("modals.review.titleDelete")}
        confirmBtnText={t("modals.review.delete")}
        cancelBtnText={t("modals.review.cancel")}
        successMessage={t("notifications.modals.reviewDelete")}
        confirmService={() => ApiService.deleteSiteReview(review?.id)}
        afterConfirmService={() => changeHandler()}
      />
    </>
  );
};
