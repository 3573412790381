import "./SidebarButton.scss";

export const SidebarButton = ({
  position,
  isActive,
  arrowPosition = 35,
  ...rest
}) => {
  const defineClassname = () => {
    let className = "sidebar-btn";

    switch (position) {
      case "left": {
        className += " sidebar-btn__left";
        break;
      }
      case "right": {
        className += " sidebar-btn__right";
        break;
      }
      default: {
        break;
      }
    }

    if (isActive) {
      className += " sidebar-btn--active";
    }
    return className;
  };

  const defineStyles = () => {
    const styles = {};

    if (position === "left") {
      styles["--position"] = `${arrowPosition}rem`;
    }

    if (position === "right") {
      styles["--position"] = `${arrowPosition}rem`;
    }

    return styles;
  };

  return (
    <button style={defineStyles()} className={defineClassname()} {...rest}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M9.93685 7.14357L4.34704 12.7332C3.99146 13.0889 3.41495 13.0889 3.05955 12.7332C2.70411 12.3778 2.70411 11.8013 3.05955 11.4459L8.00566 6.49991L3.05969 1.55412C2.70425 1.19854 2.70425 0.622118 3.05969 0.266684C3.41512 -0.0888946 3.99161 -0.0888946 4.34719 0.266684L9.93699 5.85639C10.1147 6.03419 10.2035 6.26698 10.2035 6.49988C10.2035 6.73289 10.1145 6.96585 9.93685 7.14357Z" />
        </g>
      </svg>
    </button>
  );
};
