import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatchedActions } from "../../../../../../hooks";
import { getParamsUrlForFilter } from "../../../../../../utils/helpers";

import "./ProxyFilter.scss";

export const ProxyFilter = memo(() => {
  // **Props
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // **Redux state
  const { t } = useTranslation();

  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();

  const routeHandler = (filter) => {
    setActiveFilters({ filter });

    const fc = searchParams.get("fc");
    const fg = searchParams.get("fg");
    const fpts = searchParams.getAll("fpt");

    navigate(getParamsUrlForFilter(fc, fg, fpts, filter));
  };

  return (
    <div className="proxy-filter">
      <div className="proxy-filter__title">
        {t("proxyPage.content.sort.title")}:
      </div>
      <div className="proxy-filter__list">
        <button
          className={
            searchParams.get("s") === "rating"
              ? "proxy-filter__item proxy-filter__item--active proxy-filter__item--rating"
              : "proxy-filter__item proxy-filter__item--rating"
          }
          onClick={() => routeHandler("rating")}
        >
          <span>{t("proxyPage.content.filter.rating")}</span>
        </button>
        <button
          className={
            searchParams.get("s") === "reviews" || !searchParams.get("s")
              ? "proxy-filter__item proxy-filter__item--active proxy-filter__item--review"
              : "proxy-filter__item proxy-filter__item--review"
          }
          onClick={() => routeHandler("reviews")}
        >
          <span>{t("proxyPage.content.filter.review")}</span>
        </button>
        <button
          className={
            searchParams.get("s") === "costs"
              ? "proxy-filter__item proxy-filter__item--active proxy-filter__item--cost"
              : "proxy-filter__item proxy-filter__item--cost"
          }
          onClick={() => routeHandler("costs")}
        >
          <span>{t("proxyPage.content.filter.cost")}</span>
        </button>
      </div>
    </div>
  );
});
