import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useLangUrlDefault } from "../../../../hooks";

export const AuthTabs = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  return (
    <ul className="auth__tabs">
      <li className="auth__tab">
        <NavLink
          to={`${hrefLang}/login`}
          className={({ isActive }) =>
            isActive
              ? "auth__tab-link auth__tab-link--active"
              : "auth__tab-link"
          }
        >
          {t("authPages.auth.title")}
        </NavLink>
      </li>
      <li className="auth__tab">
        <NavLink
          to={`${hrefLang}/registration`}
          className={({ isActive }) =>
            isActive
              ? "auth__tab-link auth__tab-link--active"
              : "auth__tab-link"
          }
        >
          {t("authPages.register.title")}
        </NavLink>
      </li>
    </ul>
  );
};
