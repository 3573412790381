import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { useDispatchedActions } from "../../../../hooks";
import { getAllProxySite } from "../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { Reviews } from "../Reviews";

import { InfoPart } from "./InfoPart/InfoPart";
import { ReplyPart } from "./ReplyPart/ReplyPart";
import { UserPart } from "./UserPart/UserPart";

export const ReviewItem = ({
  captchaRef,
  setTokenCaptcha,
  review,
  changeHandler,
  complaint,
  isRootReview,
  nestedResponseValue
}) => {
  // **Redux state
  const { reviews } = useSelector(getAllProxySite);

  // **Local state
  // eslint-disable-next-line no-unused-vars
  const [isReviewsOpened, setIsReviewsOpened] = useState(false);

  // **Dispatch
  const { setReviewInfo } = useDispatchedActions();

  // **Ref
  const reviewInnerRef = useRef(null);
  const reviewRef = useRef(null);

  const toggleReview = () => {
    if (!reviewInnerRef.current) return;

    const height = reviewInnerRef.current.scrollHeight;

    if (isReviewsOpened) {
      requestAnimationFrame(() => {
        reviewInnerRef.current.style.height = `${height}px`;

        requestAnimationFrame(() => {
          reviewInnerRef.current.style.height = "0px";
        });
      });

      setIsReviewsOpened(false);
    } else {
      reviewInnerRef.current.style.height = `${height}px`;

      setIsReviewsOpened(true);
    }
  };

  const transitionEndHandler = () => {
    if (!reviewInnerRef.current) return;

    if (isReviewsOpened) {
      reviewInnerRef.current.style.height = "auto";
    } else {
      reviewInnerRef.current.style.height = "0px";
    }
  };

  useEffect(() => {
    if (!reviewInnerRef.current) return;

    if (!isReviewsOpened) {
      reviewInnerRef.current.style.height = "0px";
    } else {
      reviewInnerRef.current.style.height = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!reviewRef.current) return;
    if (reviews.reviewId && review.id === reviews.reviewId) {
      const coords = reviewRef.current.getBoundingClientRect();

      window.scrollTo({
        top: coords.top + window.scrollY,
        behavior: "smooth"
      });

      setReviewInfo({ page: null, id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewRef.current, reviews.reviewId]);

  return (
    <>
      <div
        ref={nestedResponseValue === 1 ? reviewRef : null}
        className="reviews"
      >
        <div className="reviews__item">
          <UserPart review={review} />
          <div className="reviews__content">
            <InfoPart review={review} />
            <ReplyPart
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              review={review}
              changeHandler={changeHandler}
              complaint={complaint}
              toggleReview={toggleReview}
              isRootReview={isRootReview}
              isReviewsOpened={isReviewsOpened}
            />
          </div>
        </div>
        {isRootReview && review?.responses?.length !== 0 && (
          <div
            ref={reviewInnerRef}
            className={
              nestedResponseValue > 9
                ? "reviews__inner reviews__inner--no-margin"
                : "reviews__inner"
            }
            onTransitionEnd={transitionEndHandler}
            style={{ height: 0 }}
          >
            <Reviews
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              reviews={review.responses}
              changeHandler={changeHandler}
              nestedResponseValue={nestedResponseValue + 1}
            />
          </div>
        )}
        {!isRootReview && review?.responses?.length !== 0 && (
          <div
            className={
              nestedResponseValue > 9
                ? "reviews__inner reviews__inner--no-margin"
                : "reviews__inner"
            }
          >
            <Reviews
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
              reviews={review.responses}
              changeHandler={changeHandler}
              nestedResponseValue={nestedResponseValue + 1}
            />
          </div>
        )}
      </div>
    </>
  );
};
