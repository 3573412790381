import { useFormContext } from "react-hook-form";

import "./Checkbox.scss";

export const Checkbox = ({
  name,
  rules,
  type = "checkbox",
  label,
  ...rest
}) => {
  // Form
  const { register } = useFormContext();

  return (
    <div className="form-checkbox__item">
      <label>
        <input type={type} {...register(name, rules)} {...rest} />
        <span className="form-checkbox__input"></span>
        <span className="form-checkbox__label">{label}</span>
      </label>
    </div>
  );
};
