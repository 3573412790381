export const setRouteContentReducerReducer = (state, action) => {
  state.routeContent = action.payload;
};

export const setStatisticReducer = (state, action) => {
  state.statistic.data = action.payload;
  state.statistic.isDataLoaded = true;
};

export const setActiveFiltersReducer = (state, action) => {
  state.activeFilters = { ...state.activeFilters, ...action.payload };
};

export const setPageContentReducer = (state, action) => {
  state.pageContent.data = {
    ...state.pageContent.data,
    [action.payload.innerTag]: action.payload.data
  };
  state.pageContent.current.data = action.payload.data;
  state.pageContent.current.tag = action.payload.innerTag;
  state.pageContent.isDataLoaded = true;
};

export const setCurrentPageContentReducer = (state, action) => {
  state.pageContent.current.data = action.payload.data;
  state.pageContent.current.tag = action.payload.innerTag;
};

export const setClearPageContentReducer = (state) => {
  state.pageContent = {
    data: {},
    current: {
      tag: "",
      data: {}
    },
    isLoading: false,
    isDataLoaded: false,
    isError: null
  };
};

export const setCountriesReducer = (state, action) => {
  state.countries.data = action.payload;
  state.countries.isDataLoaded = true;
};

export const setGoalsByGroupsReducer = (state, action) => {
  state.goalsByGroups.data = action.payload;
  state.goalsByGroups.isDataLoaded = true;
};

export const setSellersReducer = (state, action) => {
  state.sellers.data = action.payload;
  state.sellers.isDataLoaded = true;
};

export const setReviewsReducer = (state, action) => {
  state.reviews.data = action.payload;
  state.reviews.isDataLoaded = true;
};

export const setGoalsReducer = (state, action) => {
  state.goals.data = action.payload;
  state.goals.isDataLoaded = true;
};

export const setTopSitesReducer = (state, action) => {
  state.topSites.data = action.payload;
  state.topSites.isDataLoaded = true;
};

export const setCurrenciesReducer = (state, action) => {
  state.currencies.data = action.payload;
  state.currencies.isDataLoaded = true;
};

export const setRentPeriodsReducer = (state, action) => {
  state.rentPeriods.data = action.payload;
  state.rentPeriods.isDataLoaded = true;
};

export const setProxyTypesReducer = (state, action) => {
  state.proxyTypes.data = action.payload;
  state.proxyTypes.isDataLoaded = true;
};

export const setProxyTypesMenuReducer = (state, action) => {
  state.proxyTypesMenu.data = action.payload;
  state.proxyTypesMenu.isDataLoaded = true;
};

export const setActivePageParamsReducer = (state, action) => {
  state.activePageParams = action.payload;
};

export const setCaptchaConfigReducer = (state, action) => {
  state.captchaConfig.data = action.payload;
  state.captchaConfig.isDataLoaded = true;
};

export const setSocialsReducer = (state, action) => {
  state.socials.data = action.payload;
  state.socials.isDataLoaded = true;
};

export const setMainBlockListReducer = (state, action) => {
  state.mainBlockList.data = action.payload;
  state.mainBlockList.isDataLoaded = true;
};

export const setSeoDataDefaultReducer = (state, action) => {
  state.seoDataDefault.data = action.payload;
  state.seoDataDefault.isDataLoaded = true;
};

export const setUserIpReducer = (state, action) => {
  state.userIp.data = action.payload;
  state.userIp.isDataLoaded = true;
};

export const setUserChoiceReducer = (state, action) => {
  state.userChoice.data = action.payload;
  state.userChoice.isDataLoaded = true;
};
