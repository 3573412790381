import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ProxyTabFilter } from "../../../../../components/common/New/ProxyTabFilter/ProxyTabFilter";
import Sidebar from "../../../../../components/common/New/Sidebar/Sidebar";
import Pagination from "../../../../../components/ui/New/Pagination/Pagination";
import { useDispatchedActions } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getCountryId, getGoalId, isEqual } from "../../../../../utils/helpers";
import {
  ProxyCard,
  ProxyContentContainer
} from "../../../MainPage/components/Proxy/Proxy.styled";

import { ProxyPageContainer, ProxyPaginationBox } from "./ProxyContent.styled";
import { ProxyContentTop } from "./ProxyContentTop/ProxyContentTop";
import { ProxyTable } from "./ProxyTable/ProxyTable";

export const ProxyContent = () => {
  // **Props
  const [searchParams] = useSearchParams();
  const searchParamsSize = [...new Set(searchParams.keys())]?.length;
  // **Redux state
  const { countries, goals } = useSelector(getAllContent);
  const { proxySites } = useSelector(getAllProxySite);
  const { t } = useTranslation();
  const { getProxySites } = useDispatchedActions();

  // **Local state
  const [isLoading, setIsLoading] = useState(!proxySites.isDataLoaded);
  const [filterParams, setFilterParams] = useState({
    country: getCountryId(countries.data, searchParams.get("fc"))?.id || "",
    sort: searchParams.get("filter") || "",
    goal: getGoalId(goals.data, searchParams.get("fg"))?.id || "",
    proxyType: searchParams.get("fpt") ? [searchParams.get("fpt")] : [],
    useNew: true,
    top: true,
    page: 0,
    size: 10
  });

  const pageChangeHandler = (page) => {
    setFilterParams({ ...filterParams, page: page - 1 });
  };

  const handleSetRowsPerPage = (value) => {
    setFilterParams({ ...filterParams, page: 0, size: value });
  };

  useEffect(() => {
    const isEqualFilter = isEqual(proxySites.fetchParams, filterParams);

    if (searchParamsSize !== 0 && !isEqualFilter) {
      getProxySites(filterParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    if (proxySites.isDataLoaded) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxySites.isDataLoaded]);

  useEffect(() => {
    const prevFilterParams = filterParams;
    const currentFilterParams = {
      page: 0,
      country: getCountryId(countries.data, searchParams.get("fc"))?.id || "",
      sort: searchParams.get("filter") || "",
      goal: getGoalId(goals.data, searchParams.get("fg"))?.id || "",
      proxyType: searchParams.getAll("fpt"),
      useNew: true,
      top: true,
      size: 10
    };
    const isEqualFilter = isEqual(prevFilterParams, currentFilterParams);

    if (!isEqualFilter) {
      setFilterParams({
        ...filterParams,
        ...currentFilterParams
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, countries, goals]);

  useEffect(() => {
    if (proxySites.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxySites.isError]);
  return (
    <ProxyPageContainer>
      <ProxyContentTop />

      <ProxyContentContainer id="proxyContent">
        <ProxyCard shadow>
          <ProxyTabFilter />
          <ProxyTable
            isLoading={isLoading}
            tableData={proxySites.content}
            skip={proxySites.skip}
          />
          {proxySites.content?.length === 0 ? null : (
            <ProxyPaginationBox>
              <Pagination
                currentPage={filterParams?.page + 1}
                rowsPerPage={filterParams?.size}
                rowCount={proxySites?.totalElements}
                onChangeRowsPerPage={handleSetRowsPerPage}
                onChangePage={pageChangeHandler}
                useCount
              />
            </ProxyPaginationBox>
          )}
        </ProxyCard>
        <Sidebar />
      </ProxyContentContainer>
    </ProxyPageContainer>
  );
};
