import { useEffect, useState } from "react";

import { fingerPrint } from "@forproxyband/react-tools";

export const useBrowserFingerprint = () => {
  const [browserFingerprint, setBrowserFingerprint] = useState(null);

  useEffect(() => {
    const getFingerPrint = async () => {
      const result = await fingerPrint.getFingerPrint();
      setBrowserFingerprint(result.visitorId);
    };
    getFingerPrint();
  }, []);

  return browserFingerprint;
};
