export const setReviewInfoReducer = (state, action) => {
  state.reviews.reviewPage = action.payload.page;
  state.reviews.reviewId = action.payload.id;
};

export const setProxySiteInfoReducer = (state, action) => {
  state.site.data = action.payload;
  state.site.isDataLoaded = true;
};

export const setProxySiteReviewsAmountReducer = (state, action) => {
  state.reviewsAmount.data = action.payload;
};

export const setProxySiteRatingReducer = (state, action) => {
  state.rating.data = action.payload;
  state.rating.isDataLoaded = true;
};

export const setAllPromocodesBySiteReducer = (state, action) => {
  state.promocodes.data = action.payload;
  state.promocodes.isDataLoaded = true;
};

export const setProxySiteReviewsReducer = (state, action) => {
  state.reviews.data = action.payload.data;
  state.reviews.fetchedParams = action.payload.params;
  state.reviews.isDataLoaded = true;
};

export const setProxySitesReducer = (state, action) => {
  state.proxySites.content = action.payload.content;
  state.proxySites.totalElements = action.payload.totalElements;
  state.proxySites.totalPages = action.payload.totalPages;
  state.proxySites.fetchParams = action.payload.fetchParams;
  state.proxySites.skip = 0;
  state.proxySites.isDataLoaded = true;
};

export const setProxySitesFetchParamsReducer = (state, action) => {
  state.proxySites.fetchParams = action.payload;
};

export const setProxySitesTypeReducer = (state, action) => {
  state.proxySitesType.data = action.payload;
  state.proxySitesType.isDataLoaded = true;
};

export const clearProxySiteReviewsReducer = (state) => {
  state.reviews.data = {
    totalPages: 0,
    totalElements: 0,
    content: []
  };
  state.reviews.isDataLoaded = false;
};
