import "./Typography.scss";

export const Typography = ({
  Tag = "div",
  size,
  weight,
  color,
  padding,
  position,
  classNames = "",
  children,
  ...rest
}) => {
  const defineClassname = () => {
    let className = `${classNames} typography`;

    switch (size) {
      case "very-large": {
        className += " typography__size--very-large";
        break;
      }
      case "large": {
        className += " typography__size--large";
        break;
      }
      case "middle": {
        className += " typography__size--middle";
        break;
      }
      default: {
        break;
      }
    }

    switch (weight) {
      case "semibold": {
        className += " typography__weight--semibold";
        break;
      }
      case "bold": {
        className += " typography__weight--bold";
        break;
      }
      default: {
        break;
      }
    }

    switch (padding) {
      case "bottom-large": {
        className += " typography__padding--bottom-large";
        break;
      }
      case "bottom-very-large": {
        className += " typography__padding--bottom-very-large";
        break;
      }
      default: {
        break;
      }
    }

    switch (position) {
      case "center": {
        className += " typography__position--center";
        break;
      }
      default: {
        break;
      }
    }

    if (color) {
      className += " typography__children";
    }

    return className;
  };

  return (
    <Tag className={defineClassname()} {...rest}>
      {children}
    </Tag>
  );
};
