import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "../../../../../components/common/New/Progress/Progress";
import { FormInput } from "../../../../../components/forms/New/FormInput/FormInput";
import { useUserIp } from "../../../../../hooks/useUserIp";
import { TraicingIpSchema } from "../../../../../utils/validation/traicingIp.validation";

import {
  InfoMessage,
  InputBox,
  RestyledButton,
  RestyledContainer,
  RestyledFormSelect,
  RestyledTitle,
  ResultContent,
  ResultItem,
  ResultWrapper,
  StyledAdditionalText,
  StyledForm,
  TracingIpWrapper
} from "./TracingIp.styled";

const mockData = [
  "IP/ Domain: 185.19.6.116",
  "Not shown: 0 closed ports",
  "109 pop2 - closed",
  "110 pop3 - closed",
  "115 sftp - closed",
  "118 sqlserv - closed",
  "119 nntp - closed",
  "IP/ Domain: 185.19.6.116",
  "Not shown: 0 closed ports",
  "109 pop2 - closed",
  "110 pop3 - closed",
  "115 sftp - closed",
  "118 sqlserv - closed",
  "119 nntp - closed"
];

export const TraicingIp = () => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  const methods = useForm({
    resolver: yupResolver(
      TraicingIpSchema(t("tracingIp", { returnObjects: true }))
    )
  });

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      methods.reset();

      setResult(mockData);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <RestyledContainer>
      <TracingIpWrapper>
        <RestyledTitle tag="h2">{t("tracingIp.main.title")}</RestyledTitle>
        <FormProvider {...methods}>
          <StyledForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <InputBox>
              <FormInput
                name="ipAddress"
                type="text"
                placeholder={t("tracingIp.form.enterIP")}
                label={t("tracingIp.form.inputLabel")}
                showError
              />
              <StyledAdditionalText
                type="button"
                onClick={() => {
                  methods.setValue("ipAddress", userIp?.ipAddress);
                  methods.clearErrors();
                }}
              >
                {t("portScanner.form.pasteIP")}
              </StyledAdditionalText>
            </InputBox>
            <RestyledFormSelect
              name="type"
              id="type"
              placeholder={t("ui.yesNo.yes")}
              defaultValue="yes"
              label={t("tracingIp.form.selectLabel")}
              returnObject
              options={[
                {
                  label: t("ui.yesNo.yes"),
                  value: "yes"
                },
                { label: t("ui.yesNo.no"), value: "no" }
              ]}
            />
            <RestyledButton
              type="submit"
              fullWidth
              loading={isLoading}
              {...(isLoading && { iconLeft: "loading" })}
            >
              {isLoading
                ? t("tracingIp.form.loading")
                : t("tracingIp.form.traceIP")}
            </RestyledButton>
          </StyledForm>
        </FormProvider>
        <InfoMessage>
          <img src="img/icons/information.svg" alt="Info icon" />
          <span>{t("tracingIp.form.infoMessage")}</span>
        </InfoMessage>
        <RestyledTitle tag="h2">{t("portScanner.checkResults")}</RestyledTitle>
        <ResultWrapper isLoading={isLoading}>
          {isLoading ? (
            <Progress isLoading={isLoading} />
          ) : (
            <ResultContent>
              {result && result?.length
                ? result?.map((el, index) => (
                    <ResultItem key={index}>{el}</ResultItem>
                  ))
                : null}
            </ResultContent>
          )}
        </ResultWrapper>
      </TracingIpWrapper>
    </RestyledContainer>
  );
};
