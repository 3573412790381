import { createSlice } from "@reduxjs/toolkit";

import { userInitialState } from "./User.initialState";
import {
  setAuthenticationReducer,
  setDefaultUserReducer,
  setIsRedirectToCabinetReducer,
  setLogoutReducer,
  setUserReducer
} from "./User.reducer";
import {
  setAuthentication,
  setDefaultUser,
  setIsRedirectToCabinet,
  setLogout,
  setUser
} from "./User.thunks";

const UserSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  extraReducers: (builder) =>
    builder
      .addCase(setUser, setUserReducer)
      .addCase(setDefaultUser, setDefaultUserReducer)
      .addCase(setAuthentication, setAuthenticationReducer)
      .addCase(setIsRedirectToCabinet, setIsRedirectToCabinetReducer)
      .addCase(setLogout, setLogoutReducer)
});

export const UserReducerActions = {
  setUser,
  setDefaultUser,
  setAuthentication,
  setIsRedirectToCabinet,
  setLogout
};
export default UserSlice.reducer;
