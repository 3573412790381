import { Link, useNavigate } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../../hooks";

export const Card = ({ icon, title, href, children }) => {
  // **Props

  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();

  const clickHandler = (e) => {
    e.preventDefault();

    const innerUrl = e.target.closest("[data-href]")?.dataset?.href;

    if (innerUrl) {
      navigate(`${hrefLang}/${innerUrl}`);
      return;
    }

    navigate(e.currentTarget.getAttribute("href"));
  };

  if (href) {
    return (
      <li className="hero__cards-item">
        <Link to={`${hrefLang}/${href}`} onClick={clickHandler}>
          <div className="hero__cards-item-icon">{icon}</div>
          <div className="hero__cards-item-title">{title}</div>
          {children}
        </Link>
      </li>
    );
  }

  return (
    <li className="hero__cards-item">
      <span>
        <div className="hero__cards-item-icon">{icon}</div>
        <div className="hero__cards-item-title">{title}</div>
        {children}
      </span>
    </li>
  );
};
