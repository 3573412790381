import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AuthButton from "../../../../components/ui/New/AuthButton/AuthButton";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { getAllSite } from "../../../../store/reducers/SiteReducer/Site.selectors";
import { navigationHeader } from "../../../../utils/constants";

import BlogNavItem from "./BlogNavItem/BlogNavItem";
import {
  StyledAddSiteButton,
  StyledButtonsWrapper,
  StyledLanguages,
  StyledNavDesktop,
  StyledNavMobile
} from "./BurgerMenu.styled";
import { Navigation } from "./Navigation/Navigation";
import { Overlay } from "./Overlay/Overlay";
import { ServicesNavigation } from "./ServicesNavigation/ServicesNavigation";

export const BurgerMenu = () => {
  const [, hrefLang] = useLangUrlDefault();
  const { isBurgerMenuOpened } = useSelector(getAllSite);
  const { setBurgerMenu } = useDispatchedActions();
  const { t } = useTranslation();
  const isProd = process.env.REACT_APP_MODE === "prod";

  const navigationList =
  isProd
      ? navigationHeader.filter((item) => item.title !== "tools")
      : navigationHeader;

  return (
    <Overlay isOpened={isBurgerMenuOpened} setIsOpened={setBurgerMenu}>
      <StyledNavMobile>
        <ServicesNavigation />
        <Navigation data={navigationList} mobile />
      </StyledNavMobile>
      <StyledNavDesktop isProd={isProd}>
        <Navigation data={navigationList} />
        <BlogNavItem />
      </StyledNavDesktop>
      <StyledButtonsWrapper>
        <StyledAddSiteButton
          variant="secondary"
          size="nm"
          iconLeft="plus"
          linkTo={`${hrefLang}/addsite`}
        >
          {t("navigation.addsite")}
        </StyledAddSiteButton>
        <AuthButton />
        <StyledLanguages />
      </StyledButtonsWrapper>
    </Overlay>
  );
};
