import { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Modal } from "../../";
import { Button } from "../../../ui/Button/Button";
import { Loader } from "../../../ui/Loader/Loader";
import { Typography } from "../../../ui/Typography/Typography";

import "./ConfirmModal.scss";

export const ConfirmModal = ({
  afterConfirmService = null,
  confirmService,
  title = "",
  confirmBtnText = "Confirm",
  cancelBtnText = "Cancel",
  successMessage = "Query is successfully confirmed",
  cancelHandler,
  ...rest
}) => {
  const { t } = useTranslation();

  // **Local state
  const [isDeleting, setIsDeleting] = useState(false);

  const confirmHandler = async () => {
    try {
      setIsDeleting(true);

      const response = await confirmService();

      if (response && response.status !== 200) {
        throw response;
      }

      // Closing modal
      cancelHandler(false);

      // Show success message
      toast.success(successMessage);

      if (afterConfirmService) {
        afterConfirmService();
      }
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 300);
    }
  };

  return (
    <Modal cancelHandler={cancelHandler} {...rest}>
      {!isDeleting ? (
        <>
          <Typography size="middle" weight="semibold" position="center">
            {title}
          </Typography>
          <div className="confirm-modal__actions">
            <Button type="solid" onClick={confirmHandler}>
              {confirmBtnText}
            </Button>
            <Button type="black" onClick={() => cancelHandler(false)}>
              {cancelBtnText}
            </Button>
          </div>
        </>
      ) : (
        <Loader noPadding />
      )}
    </Modal>
  );
};
