import { useEffect, useState } from "react";

export const useLockBackdrop = (lock) => {
  // ** local State
  const [checkLock, setCheckLock] = useState(lock);
  // ** Effects
  useEffect(() => {
    if (checkLock !== lock) {
      if (lock) {
        const currentScroll =
          window.scrollY || document.documentElement.scrollTop;
        const scrollbarWidth =
          window.innerWidth - document.documentElement.clientWidth;
        document.body.classList.add("lock-backdrop");
        document.body.style.top = `-${currentScroll}px`;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflow = "hidden";
      } else {
        const scrollY = document.body?.style?.top?.slice(1, -2) || 0;
        document.body.style.paddingRight = "";
        document.body.style.top = "";
        document.body.classList.remove("lock-backdrop");
        document.body.style.overflow = "";
        window.scrollTo({
          top: scrollY || 0,
          behavior: "instant"
        });
      }
      setCheckLock(lock);
    }

    // return () => {
    //   if (!lock) return;
    //   console.log("unmount", lock);
    //   document.body.style.paddingRight = "";
    //   document.body.style.top = "";
    //   document.body.classList.remove("lock-backdrop");
    //   document.body.style.overflow = "";
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock]);
};
