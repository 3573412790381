import * as yup from "yup";

export const PasswordRecoverySchema = (trns) =>
  yup.object().shape({
    password: yup
      .string()
      .required(trns.password.rules.required)
      .test({
        name: "noSpaces",
        message: trns.password.rules.noSpace,
        test: (value) => !/\s/.test(value)
      })
      .min(6, `${trns.password.rules.min} 6`)
  });
