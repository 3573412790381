import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import { useLangUrlDefault } from "../../../../../../../hooks";

export const ProxyItem = ({ proxySite, modalHandler }) => {
  const [, hrefLang] = useLangUrlDefault();
  const { valid: isValid, statistic } = proxySite || {};
  const { views, conversions, reviews } = statistic || {};

  // **Redux state
  const { t } = useTranslation();

  return (
    <li className="cabinet-proxy__item">
      <div className="cabinet-proxy__item-content">
        <div className="cabinet-proxy__item-name">
          <a href={proxySite?.link} target="_blank" rel="noreferrer">
            {proxySite?.name}
          </a>
          <img
            className="cabinet-proxy__item-valid"
            src={
              isValid ? "/img/icons/valid-ok.svg" : "/img/icons/valid-no.svg"
            }
            width="13"
            height="13"
            loading="lazy"
            alt={t(`validation.${isValid ? "valid" : "invalid"}.title`)}
            title={t(`validation.${isValid ? "valid" : "invalid"}.report`)}
          />
        </div>
        <div className="cabinet-proxy__item-stat">
          <div className="cabinet-proxy__item-container">
            <div className="cabinet-proxy__item-date">
              <Moment format="DD.MM.YYYY HH:mm">
                {new Date(proxySite?.createDate)}
              </Moment>
            </div>
            <div className="cabinet-proxy__item-status cabinet-proxy__item-statistics">
              <span>{t("statistic.views.title")}:</span>
              {views ? views : 0}
            </div>
          </div>
          <div className="cabinet-proxy__item-container">
            <div className="cabinet-proxy__item-status">
              <span>{t("statuses.title")}:</span>
              {t(`statuses.${proxySite?.statusType}`)}
            </div>
            <div className="cabinet-proxy__item-status cabinet-proxy__item-statistics">
              <span>{t("statistic.conversions.title")}:</span>
              {conversions ? conversions : 0}
            </div>
          </div>
          <div className="cabinet-proxy__item-container">
            <div className="cabinet-proxy__item-status cabinet-proxy__item-statistics">
              <span>{t("statistic.reviews.title")}:</span>
              {reviews ? reviews : 0}
            </div>
          </div>
        </div>

        <div className="cabinet-proxy__item-statmob">
          <div className="cabinet-proxy__item-date">
            <Moment format="DD.MM.YYYY HH:mm">
              {new Date(proxySite?.createDate)}
            </Moment>
          </div>
          <div className="cabinet-proxy__item-status">
            <span>{t("statuses.title")}:</span>
            {t(`statuses.${proxySite?.statusType}`)}
          </div>
          <div className="cabinet-proxy__item-statwrapper">
            <div className="cabinet-proxy__item-status cabinet-proxy__item-statistics">
              <span>{t("statistic.views.title")}:</span>
              {views ? views : 0}
            </div>
            <div className="cabinet-proxy__item-status cabinet-proxy__item-statistics">
              <span>{t("statistic.conversions.title")}:</span>
              {conversions ? conversions : 0}
            </div>
            <div className="cabinet-proxy__item-status cabinet-proxy__item-statistics">
              <span>{t("statistic.reviews.title")}:</span>
              {reviews ? reviews : 0}
            </div>
          </div>
        </div>
      </div>
      <div className="cabinet-proxy__item-actions">
        <Link
          className="cabinet-proxy__item-action--edit"
          to={`${hrefLang}/cabinet/proxy/edit/${proxySite.id}/`}
        >
          {t("cabinetPages.proxy.list.edit")}
        </Link>
        <button
          className="cabinet-proxy__item-action--delete"
          onClick={modalHandler(proxySite?.id)}
        >
          {t("cabinetPages.proxy.list.delete")}
        </button>
      </div>
    </li>
  );
};
