import styled from "@emotion/styled";

import { Main } from "../../../components/common/New/Main/Main";

export const RestyledMainSection = styled(Main)`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 30px;
    min-height: 530px;
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      width: 560px;
      height: 419px;
    }

    @media (min-width: 1250px) {
      width: 701px;
      height: 524px;
      right: -70px;
    }

    // @media (min-width: 1530px) {
    //   right: 4.5vw;
    // }

    &::before {
      width: 236px;
      height: 236px;
      bottom: 50px;
    }

    img {
      margin-top: -35px;
    }
  }
`;
