export const setFreeProxyContentReducer = (state, action) => {
  state.freeProxy.data = action.payload;
  state.freeProxy.isDataLoaded = true;
};

export const setAllFreeProxyContentReducer = (state, action) => {
  state.allFreeProxy.data = action.payload;
  state.allFreeProxy.isDataLoaded = true;
};

export const setCitiesReducer = (state, action) => {
  state.cities.data = action.payload;
  state.cities.isDataLoaded = true;
};
