import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getAllTranslation } from "../store/reducers/TranslationReducer/Translation.selectors";
import { useLangUrlDefault } from "./";

export const useRedirect = () => {
  // **Props
  const { pathname } = useLocation();
  const [queryLang] = useLangUrlDefault();
  const { lang } = useParams();
  const navigate = useNavigate();

  // **Redux state
  const { translations } = useSelector(getAllTranslation);

  useEffect(() => {
    // Adding slash to the route if route does not contain slash at the end
    if (pathname.slice(-1) !== "/") {
      navigate(`${pathname}/`, { replace: true });
      return;
    }

    if (lang === "en") {
      navigate(`${pathname.split("/").slice(2).join("/")}`, { replace: true });
      return;
    }

    if (queryLang && translations?.hasOwnProperty(queryLang)) return;

    const splittedPath = pathname.split("/").slice(2);

    const path = splittedPath.join("/");

    navigate(path.slice(-1) === "/" ? path : `${path}/`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, pathname]);
};
