import { useSelector } from "react-redux";

import { Typography } from "../../../../../../components/ui/Typography/Typography";
import { getAllBlogInner } from "../../../../../../store/reducers/BlogInnerReducer/BlogInner.selectors";

import { ArticleNavigation } from "./ArticleNavigation/ArticleNavigation";
import { Body } from "./Body/Body";
import { Info } from "./Info/Info";

export const ArticleBody = () => {
  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <>
      <Typography weight="bold" size="very-large" padding="bottom-large">
        <h1>{content.data?.title}</h1>
      </Typography>
      <Info />
      <Body />
      <ArticleNavigation />
    </>
  );
};
