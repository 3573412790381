import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Menu as BaseMenu,
  MenuButton as BaseMenuButton,
  MenuItem as BaseMenuItem
} from "@szhsin/react-menu";
import {
  menuDividerSelector,
  menuItemSelector,
  menuSelector
} from "@szhsin/react-menu/style-utils";

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const MenuButton = styled(BaseMenuButton)`
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.variant === "table"
      ? props.theme.colors["neutral-promo-date"]
      : props.theme.colors["neutral-400"]};
  ${(p) => (p.mlauto === "true" ? "margin-left: auto;" : "")};
  transition: all 0.3s ease;
  ${(p) => (p.variant === "table" ? "padding: 4px;" : "")};
  & svg {
    width: ${(p) => (p.variant === "table" ? "20px" : "24px")};
    height: ${(p) => (p.variant === "table" ? "20px" : "24px")};
  }

  &:hover,
  &:focus {
    transition: all 0.3s ease;
    color: ${(p) =>
      p.variant === "table"
        ? p.theme.colors["main-700"]
        : p.theme.colors["neutral-800"]};

    outline: none;
  }
`;

export const Menu = styled(BaseMenu)`
  ${menuSelector.name} {
    z-index: 1000;
    padding: 8px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors["neutral-800"]};
    box-shadow: 0px 7px 21px 0px #878cbd3b;
    ${(props) => (props.propwidth ? `width: ${props.propwidth}px;` : "auto")};
  }

  ${menuSelector.name}:focus,${menuItemSelector.name}:focus {
    outline: none;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuDividerSelector.name} {
    height: 1px;
    margin: 5px;
    background-color: ${(props) => props.theme.colors["outline-color"]};
  }
`;

export const MenuItem = styled(BaseMenuItem)`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 175px;
  width: 100%;
  color: ${(props) =>
    props.variant === "danger"
      ? props.theme.colors["red-500"]
      : props.theme.colors["neutral-800"]};
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
  padding: 10px;
  border-radius: 8px;

  background: ${(props) =>
    props?.active === "true" ? "#f6f7f9cc" : "transparent"};

  & svg {
    width: 24px;
    height: 24px;
  }

  &:hover,
  &:focus {
    background: #f6f7f9cc;
    outline: none;
    cursor: pointer;
  }

  ${(props) =>
    props.disabled &&
    `cursor: default; color: ${(props) => props.theme.colors["neutral-300"]};`}

  & a {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
