import PT from "prop-types";

import Container from "../Container/Container";

import { BreadcrumbsList } from "./Breadcrumbs.styled";
import { BreadcrumbsItem } from "./BreadcrumbsItem";

const Breadcrumbs = ({ items = [], className }) => (
  <Container className={className}>
    <BreadcrumbsList>
      {items.map((item) => (
        <BreadcrumbsItem key={item.title} item={item} />
      ))}
    </BreadcrumbsList>
  </Container>
);

Breadcrumbs.propTypes = {
  className: PT.string,
  items: PT.arrayOf(PT.oneOfType([PT.string, PT.shape({})]))
};

export default Breadcrumbs;
