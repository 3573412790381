import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLangUrlDefault } from "../../../../../../hooks";
import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import {
  StyledCirkle,
  StyledContentList,
  StyledContentListItem,
  StyledContentTitle
} from "../../ExpandableComponent.styled";

export const ProxyTypes = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  // **Redux state
  const { proxyTypes } = useSelector(getAllContent);

  const proxyTypesData =
    data?.proxyTypeProps?.length > 0
      ? data.proxyTypeProps?.map((type) => type?.proxyType)?.filter(Boolean)
      : [];

  return (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.type.title")}
      </StyledContentTitle>
      {proxyTypesData?.length > 0 ? (
        <StyledContentList>
          {proxyTypesData
            .map((type) => {
              const proxyType = proxyTypes.data?.[queryLang]?.find(
                (it) => it.type === type
              );
              if (!proxyType?.name) return null;

              return (
                <StyledContentListItem key={type}>
                  <StyledCirkle />
                  {proxyType?.name}
                </StyledContentListItem>
              );
            })
            .filter(Boolean)}
        </StyledContentList>
      ) : (
        <span>{t("proxyPage.content.table.type.noContent")}</span>
      )}
    </div>
  );
};
