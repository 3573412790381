import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getStatistic = createAsyncThunk(
  "content/getStatistic",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getStatistic();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getPageContent = createAsyncThunk(
  "content/getPageContent",
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await ApiService.getPageContent(
        {
          tag: params.tag,
          languageCode: params.languageCode,
          params: params.params,
          location: params.location,
          tab: params.tab,
          pathname: params.pathname
        },
        signal
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        data: response.data,
        innerTag: params.innerTag,
        lang: params.languageCode
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllCountries = createAsyncThunk(
  "content/getAllCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllCountries();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllProxies = createAsyncThunk(
  "content/getAllProxies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllProxies();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllMenuProxies = createAsyncThunk(
  "content/getAllMenuProxies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllProxies("menuSort");

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllGoalGroups = createAsyncThunk(
  "content/getAllGoalGroups",
  async (lang, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllGoalGroups(lang);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllGoals = createAsyncThunk(
  "content/getAllGoals",
  async (lang, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllGoals(lang);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllTopSellers = createAsyncThunk(
  "content/getAllTopSellers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllTopSellers();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getLastSiteReviews = createAsyncThunk(
  "content/getLastSiteReviews",
  async (count, { rejectWithValue }) => {
    try {
      const response = await ApiService.getLastSiteReviews(count);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getTopSites = createAsyncThunk(
  "content/getTopSites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getTopSites();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllCurrencies = createAsyncThunk(
  "content/getAllCurrencies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllCurrencies();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllRentPeriods = createAsyncThunk(
  "content/getAllRentPeriods",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getRentPeriods();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getCaptchaConfig = createAsyncThunk(
  "content/getCaptchaConfig",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getCaptchaConfig();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getSeoDataDefault = createAsyncThunk(
  "content/getSeoDataDefault",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getSeoDataDefault();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllSocials = createAsyncThunk(
  "content/getAllSocials",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllSocials();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getMainBlockList = createAsyncThunk(
  "content/getMainBlockList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getMainBlockList();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getUserIp = createAsyncThunk(
  "content/getUserIp",
  async (lang, { rejectWithValue }) => {
    try {
      const response = await ApiService.getUserIp(lang);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getUserChoice = createAsyncThunk(
  "content/getUserChoice",
  async (size, { rejectWithValue }) => {
    try {
      const response = await ApiService.getUserChoice(size);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setRouteContent = createAction("content/setRouteContent");
export const setStatistic = createAction("content/setStatistic");
export const setActiveFilters = createAction("content/setActiveFilters");
export const setPageContent = createAction("content/setPageContent");
export const setCurrentPageContent = createAction(
  "content/setCurrentPageContent"
);
export const setClearPageContent = createAction("content/setClearPageContent");
export const setCountries = createAction("content/setCountries");
export const setGoalsByGroups = createAction("content/setGoalsByGroups");
export const setSellers = createAction("content/setSellers");
export const setReviews = createAction("content/setReviews");
export const setGoals = createAction("content/setGoals");
export const setTopSites = createAction("content/setTopSites");
export const setCurrencies = createAction("content/setCurrencies");
export const setRentPeriods = createAction("content/setRentPeriods");
export const setProxyTypes = createAction("content/setProxyTypes");
export const setProxyTypesMenu = createAction("content/setProxyTypesMenu");
export const setActivePageParams = createAction("content/setActivePageParams");
export const setCaptchaConfig = createAction("content/setCaptchaConfig");
export const setSocials = createAction("content/setSocials");
export const setMainBlockList = createAction("content/setMainBlockList");
export const setSeoDataDefault = createAction("content/setSeoDataDefault");
export const setUserIp = createAction("content/setUserIp");
export const setUserChoice = createAction("content/setUserChoice");
