import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  list-style: none;

  @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    height: 2rem;
  }
`;

export const NavItem = styled.li`
  min-width: 130px;

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.xs};
  line-height: 1;
  text-align: left;
  color: #ffffffcc;
  cursor: pointer;
  transition: font-weight 0.5s ease, color 0.5s ease;

  :not(:last-child) {
    margin-bottom: 24px;

    @media screen and (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin-right: 64px;
      margin-bottom: 0;
      padding-right: 64px;

      border-right: 1px solid #ffffff33;
    }
  }

  :hover {
    font-weight: 600;
    color: ${(p) => p.theme.colors.white};
    transition: font-weight 0.5s ease, color 0.5s ease;
  }

  ::before {
    content: attr(title);
    display: block;
    padding-left: 10px;
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const StyledLink = styled(Link)`
  color: #ffffffcc;
  text-decoration: none;
`;
