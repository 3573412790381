export const transformNetworks = (
  networks,
  socials = [],
  isArray = false,
  isEdit = false
) => {
  if (!networks) return [];

  if (isArray) {
    const socialNetworks = [];

    Object.entries(networks).forEach((entry) => {
      entry[1].forEach((value) => {
        if (value.trim()) {
          socialNetworks.push({
            socialId: socials.find((it) => it.code === entry[0]).id,
            link: value,
            showOnFront: true
          });
        }
      });
    });

    return socialNetworks;
  } else {
    const allValues = socials.map((it) => it.code);
    const socialNetworks = {};

    if (networks?.length > 0) {
      networks.forEach((network) => {
        if (network.showOnFront) {
          const { socialId, name } = network || {};
          const property =
            socialId && socials.some((it) => it.id === socialId)
              ? socials.find((it) => it.id === socialId).code
              : name;

          if (socialNetworks.hasOwnProperty(property)) {
            socialNetworks[property] = [
              ...socialNetworks[property],
              network.link
            ];
          } else {
            socialNetworks[property] = [network.link];
          }
        }
      });

      if (isEdit) {
        allValues.forEach((value) => {
          if (!socialNetworks.hasOwnProperty(value)) {
            socialNetworks[value] = [" "];
          }
        });
      }

      if (!isEdit) {
        return Object.entries(socialNetworks);
      }
    } else {
      if (!isEdit) {
        return Object.entries(socialNetworks);
      }

      allValues.forEach((value) => {
        socialNetworks[value] = [" "];
      });
    }

    return socialNetworks;
  }
};
