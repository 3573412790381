import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllContent } from "../../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { transformNetworks } from "../../../../../../utils/helpers";

export const Contacts = () => {
  const { t } = useTranslation();

  // **Redux state
  const { site } = useSelector(getAllProxySite);
  const { socials } = useSelector(getAllContent);

  const socialNetworks = useMemo(
    () => transformNetworks(site.data?.socialNetworks, socials.data),
    [site.data?.socialNetworks, socials.data]
  );

  return (
    <div className="proxy-site__contacts">
      {socialNetworks?.length !== 0 ? (
        <ul className="proxy-site__contacts-list">
          {socialNetworks
            .map((it) => [
              ...it,
              socials.data.find((social) => social.code === it[0])?.sort
            ])
            .sort((a, b) => a[2] - b[2])
            .map((entry) => {
              const socialName =
                typeof entry[2] !== "undefined"
                  ? socials.data.find((it) => it.code === entry[0])?.name
                  : entry[0];
              const socialNameCapital = `${socialName
                .charAt(0)
                .toUpperCase()}${socialName.slice(1)}`;

              return (
                <li key={entry[0]} className="proxy-site__contacts-item">
                  <div className="proxy-site__contacts-item-key">
                    {socialNameCapital}:
                  </div>
                  <ul className="proxy-site__contacts-item-value">
                    {entry[1].map((link, index) => (
                      <li key={link + index}>
                        <a
                          href={
                            socialNameCapital === "Email" ||
                            socialNameCapital === "E-mail"
                              ? `mailto:${link}`
                              : link
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {link}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
        </ul>
      ) : (
        <p>{t("proxySitePage.content.contacts.noData")}</p>
      )}
    </div>
  );
};
