import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { StyledContainerSearch, StyledSearch } from "./BlogSearch.styled";

export const BlogSearch = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const s = searchParams.get("s");
  const [search, setSearch] = useState(s || "");

  const handleSearch = (value) => {
    if (
      (value && value.toString().trim() !== "") ||
      (value?.length === 0 && value === "")
    ) {
      setSearch(value);
      if (value?.length === 0) {
        searchParams.delete("s");
      } else {
        searchParams.set("s", value?.trim());
      }
      setSearchParams(searchParams);
    }
  };

  const handleClear = () => {
    setSearch("");
    setSearchParams({ tab });
  };

  return (
    <StyledContainerSearch>
      <StyledSearch
        value={search}
        onChange={handleSearch}
        variant={"outlined"}
        onClear={handleClear}
        placeholder={t("blogPage.search")}
      />
    </StyledContainerSearch>
  );
};
