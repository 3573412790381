import styled from "@emotion/styled";

export const StyledChip = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily.lato};
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  border-radius: 24px;
  text-transform: uppercase;
  color: ${(props) =>
    props.color === "success"
      ? props.theme.colors["main-500"]
      : props.theme.colors["neutral-800"]};
  border: ${(props) =>
    props.color === "success"
      ? `0.5px solid ${props.theme.colors["main-500"]}`
      : `0.5px solid ${props.theme.colors["neutral-800"]}`};
`;
