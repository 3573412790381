import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "../constants";

export const ProfileChangeSchemaNew = (trns, email) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        trns.email.rules.email
      ),
    password: yup.string().when("email", {
      is: (val) => val !== email,
      then: () =>
        yup
          .string()
          .test({
            name: "noSpaces",
            message: trns.password.rules.noSpace,
            test: (value) => !/\s/.test(value)
          })
          .required(trns.field.rules.required),
      otherwise: () => yup.string()
    }),
    oldPassword: yup.string().when("email", {
      is: (val) => val === email,
      then: () => yup.string().required(trns.field.rules.required),
      otherwise: () => yup.string()
    }),
    newPassword: yup.string().when("email", {
      is: (val) => val === email,
      then: () =>
        yup
          .string()
          .test({
            name: "noSpaces",
            message: trns.password.rules.noSpace,
            test: (value) => !/\s/.test(value)
          })
          .required(trns.field.rules.required)
          .min(6, `${trns.password.rules.min} 6`),
      otherwise: () => yup.string()
    })
  });

export const ProfileChangeEmailSchema = (trns) =>
  yup.object().shape({
    newEmail: yup
      .string()
      .trim()
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        trns.email.rules.email
      ),
    password: yup.string().required(trns.field.rules.required)
  });

export const ProfileChangePasswordSchema = (trns) =>
  yup.object().shape({
    oldPassword: yup.string().required(trns.field.rules.required),
    newPassword: yup
      .string()
      .test({
        name: "noSpaces",
        message: trns.password.rules.noSpace,
        test: (value) => !/\s/.test(value)
      })
      .test({
        name: "cnanotBeSame",
        message: trns.password.rules.cannotBeSame,
        test: (value, context) => value !== context.parent.oldPassword
      })
      .required(trns.field.rules.required)
      .min(6, `${trns.password.rules.min} 6`)
  });
