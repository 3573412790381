import { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Uploader } from "../../";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllCabinet } from "../../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllTranslation } from "../../../../store/reducers/TranslationReducer/Translation.selectors";
import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";
import {
  getCurrencies,
  getDefaultDescLangHelper,
  transformMinRent,
  transformNetworks
} from "../../../../utils/helpers";
import { ProxySchema } from "../../../../utils/validation";
import { Checkbox } from "../../../forms/Checkbox/Checkbox";
import { DichotRadio } from "../../../forms/DichotRadio/DichotRadio";
import { Input } from "../../../forms/Input/Input";
import { InputField } from "../../../forms/InputField/InputField";
import { Select } from "../../../forms/Select/Select";
import { Textarea } from "../../../forms/Textarea/Textarea";
import { Button } from "../../../ui/Button/Button";
import { FormTabs } from "../../../ui/FormTabs/FormTabs";
import { Loader } from "../../../ui/Loader/Loader";
import { Typography } from "../../../ui/Typography/Typography";

import { LogoPreviews } from "./LogoPreviews/LogoPreviews";

export const Form = ({
  defaultValues,
  setDefaultValues,
  isEdit,
  domainName,
  getDomainName = null
}) => {
  const { id } = useParams();
  const [queryLang, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // **Redux state
  const { languages } = useSelector(getAllTranslation);
  const { user } = useSelector(getAllUser);
  const { proxies } = useSelector(getAllCabinet);

  const { countries, currencies, rentPeriods, proxyTypes, socials } =
    useSelector(getAllContent);

  // **Local state
  const [files, setFiles] = useState([]);
  const [croppedFileObjects, setCroppedFileObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAffiliateProgram, setIsAffiliateProgram] = useState(
    defaultValues?.affiliateProgram?.value
  );
  const [activeDescriptionTab, setActiveDescriptionTab] = useState(
    getDefaultDescLangHelper(defaultValues?.description, languages)
  );

  // **Dispatch
  const { setCabinetProxiesPrevParams, getAllCabinetSites } =
    useDispatchedActions();

  // Form
  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(ProxySchema("forms", languages, t)),
    defaultValues: useMemo(
      () => ({
        ...defaultValues,
        moneyBack: defaultValues?.benefits?.moneyBack,
        support: defaultValues?.benefits?.support,
        anonymity: defaultValues?.benefits?.anonymity,
        allPlatforms: defaultValues?.benefits?.allPlatforms
      }),
      [defaultValues]
    )
  });

  const proxyTypesForLang = proxyTypes.data?.[queryLang];
  const isProxyTypesForLangNotEmpty =
    proxyTypesForLang && proxyTypesForLang?.length > 0;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const formSummary = {
        name: domainName,
        link: data.link,
        referralLink: data.referralLink,
        proxyType: data.proxyType,
        countries: data.countries.filter((item) => item.trim()),
        purposeUse: [],
        minRentPeriod: data.minRentPeriod.value || data.minRentPeriod,
        priceMin: +data.priceMin,
        currencyId: data.currencyId.value || data.currencyId,
        clientNoAuth: data.clientNoAuth,
        clientLoginAuth: data.clientLoginAuth,
        clientIPAuth: data.clientIPAuth,
        benefits: {
          moneyBack: data.moneyBack,
          support: data.support,
          anonymity: data.anonymity,
          allPlatforms: data.allPlatforms
        },
        freeTest:
          typeof data.freeTest.value === "boolean"
            ? data.freeTest.value
            : data.freeTest,
        refunds:
          typeof data.refunds.value === "boolean"
            ? data.refunds.value
            : data.refunds,
        replacementPossibility:
          typeof data.replacementPossibility.value === "boolean"
            ? data.replacementPossibility.value
            : data.replacementPossibility,
        individual:
          typeof data.individual.value === "boolean"
            ? data.individual.value
            : data.individual,
        affiliateProgram:
          typeof data.affiliateProgram.value === "boolean"
            ? data.affiliateProgram.value
            : data.affiliateProgram,
        api: typeof data.api.value === "boolean" ? data.api.value : data.api,
        affiliatePercent: +data.affiliatePercent,
        description: data.description,
        socialNetworks: transformNetworks(
          data.socialNetworks,
          socials.data,
          true
        )
      };

      // If site edit, add some new values
      if (isEdit) {
        formSummary.id = id;
        formSummary.userId = user.uid;
      }

      const siteResponse = !isEdit
        ? await ApiService.createProxySite(formSummary)
        : await ApiService.updateProxySite(formSummary);

      // Handling error for "createSiteResponse" query
      if (siteResponse && siteResponse.status !== 200) {
        throw siteResponse;
      }

      // Show success message that site has been created / updated
      toast.success(
        isEdit
          ? t("notifications.site.edited")
          : t("notifications.site.created")
      );

      // Set prev params to null in order to load new site
      setCabinetProxiesPrevParams(null);

      // Getting new sites for promocodes
      getAllCabinetSites(proxies.prevParams);

      // Check if file is added, if no go the main cabinet page
      if (!files?.length) {
        if (!isEdit) {
          navigate(`${hrefLang}/cabinet/proxy/`);
        }

        return;
      }

      // If file exists, uploading it to the server
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("fileTop", files[1]);

      const imageUploadResponse = await ApiService.uploadImage(
        formData,
        siteResponse?.data?.id || id,
        toast,
        t("notifications.image.uploading")
      );

      // Handling error for "imageUploadResponse" query
      if (imageUploadResponse && imageUploadResponse.status !== 200) {
        throw imageUploadResponse;
      }

      setDefaultValues((prevState) => ({
        ...prevState,
        image: imageUploadResponse?.data?.file,
        imageTop: imageUploadResponse?.data?.fileTop
      }));

      // Show success message that image has been uploaded
      toast.success(t("notifications.image.uploaded"));

      setTimeout(() => {
        if (!isEdit) {
          navigate(`${hrefLang}/cabinet/proxy/`);
        }
      }, 300);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleActiveTitleCheckbox = (curTarget) => {
    const inputsChecked = curTarget
      .closest(".proxy-form__form-item")
      .querySelector(".proxy-form__form-list")
      .querySelectorAll(".form-checkbox__item input:checked");
    const inputTitle = curTarget
      .closest(".proxy-form__form-item")
      .querySelector(".form-checkbox__input");

    if (inputsChecked?.length > 0) {
      inputTitle.classList.add("active");
    } else {
      inputTitle.classList.remove("active");
    }
  };

  // Check all checkboxes
  const checkAllHandler = (e) => {
    const parent = e.target
      .closest(".proxy-form__form-item")
      .querySelector(".proxy-form__form-list");

    if (parent) {
      const checkboxes = parent.querySelectorAll("input[type='checkbox']");

      if (checkboxes?.length > 0) {
        checkboxes.forEach((checkbox) => {
          if (e.target.checked) {
            checkbox.checked = true;
          } else {
            checkbox.checked = false;
          }
        });
      }
    }

    toggleActiveTitleCheckbox(e.target);
  };

  const checkHandler = (e) => toggleActiveTitleCheckbox(e.target);

  // Allow input only digits
  const onlyDigitHandler = (e) => {
    e.target.value = e.target.value.replace(/\D/g, "");
  };

  // Allow only fractional digits
  const onlyFractionalDigitHandler = (e) => {
    e.target.value = e.target.value
      .replace(/[^\d.]*/g, "")
      .replace(/([.])[.]+/g, "$1")
      .replace(/^[^\d]*(\d+([.]\d{0,5})?).*$/g, "$1");
  };

  const onlyInputRange = (min, max) => (e) => {
    const nameAttr = e.target.getAttribute("name");

    if (nameAttr === "priceMin") {
      onlyFractionalDigitHandler(e);
    } else {
      onlyDigitHandler(e);
    }

    if (+e.target.value < min) {
      e.target.value = min;
    }

    if (+e.target.value > max) {
      e.target.value = e.target.value.slice(0, String(max)?.length);

      if (+e.target.value > max) {
        e.target.value = max;
      }
    }

    if (
      e.target.value?.length > 1 &&
      +e.target.value[0] === 0 &&
      e.target.value.indexOf(".") === -1
    ) {
      e.target.value = e.target.value.slice(1, e.target.value?.length);
    }
  };

  const emailHandler = (e) => {
    e.target.value = e.target.value.replace(/[А-Яа-я]/g, "");
  };

  const changeDescriptionTab = (code) => () => {
    setActiveDescriptionTab(code);
  };

  const defineButtonContent = () => {
    if (isLoading) {
      return (
        <Loader type="absolute" scale={0.4} color="var(--clr-default-900)" />
      );
    }

    if (isEdit) {
      return t("proxy.btn.edit");
    }

    return t("proxy.btn.add");
  };

  // Reset field after changing language
  useEffect(() => {
    methods.resetField("purposeUse");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang]);

  useEffect(() => {
    if (!isAffiliateProgram) {
      methods.resetField("affiliatePercent", { defaultValue: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAffiliateProgram]);

  // Listen for changes for defaultValues
  useEffect(() => {
    methods.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form
        className="proxy-form__form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="proxy-form__form-item">
          <div className="proxy-form__form-row">
            <Input
              name="link"
              type="text"
              label={t("proxy.link.label")}
              placeholder="https://site.com/"
              layout="horizontal"
              showError
              onBlur={(evt) => getDomainName && getDomainName(evt.target.value)}
            />
          </div>
        </div>

        <div className="proxy-form__form-item">
          <div className="proxy-form__form-title">
            {t("proxy.proxyType.title")}
          </div>
          <div className="proxy-form__form-list proxy-form__form-list--small">
            {isProxyTypesForLangNotEmpty &&
              proxyTypesForLang?.map(({ type, name }) => (
                <Checkbox
                  key={type}
                  name="proxyType"
                  label={name}
                  value={type}
                />
              ))}
          </div>
          {methods.formState.errors.proxyType && (
            <div className="form-input__error">
              {t("forms.proxyType.rules.required")}
            </div>
          )}
        </div>
        {countries.isDataLoaded && (
          <div className="proxy-form__form-item">
            <div className="proxy-form__form-title">
              {t("proxy.countries.title")}
              <Checkbox
                name="countries"
                label={t("proxy.checkAll.label")}
                value=" "
                onChange={checkAllHandler}
              />
            </div>
            <div className="proxy-form__form-list proxy-form__form-list--small">
              {countries.data?.map((country) => (
                <Checkbox
                  key={country.id}
                  name="countries"
                  label={country.localization?.[queryLang]}
                  value={country.id}
                  onChange={checkHandler}
                />
              ))}
            </div>
            {methods.formState.errors.countries && (
              <div className="form-input__error">
                {t("forms.countries.rules.required")}
              </div>
            )}
          </div>
        )}

        <div className="proxy-form__form-item">
          <div className="proxy-form__form-row">
            {rentPeriods.isDataLoaded && (
              <Select
                label={t("proxy.minRent.label")}
                name="minRentPeriod"
                options={transformMinRent(
                  rentPeriods.data,
                  queryLang,
                  t("times", { returnObjects: true })
                )}
                showError
                layout="horizontal"
                placeholder={null}
                defaultValue={defaultValues?.minRentPeriod}
                isSearchable={false}
              />
            )}
            <Input
              name="priceMin"
              type="text"
              label={t("proxy.minPrice.label")}
              layout="horizontal"
              showError
              inputMode="numeric"
              onInput={onlyInputRange(0, 999999999999)}
            />
            {currencies.isDataLoaded && (
              <Select
                name="currencyId"
                options={getCurrencies(currencies.data)}
                showError
                layout="horizontal"
                placeholder={null}
                defaultValue={defaultValues.currencyId}
                style={{
                  maxWidth: "12rem"
                }}
              />
            )}
          </div>
        </div>
        <div className="proxy-form__form-item">
          <div className="proxy-form__form-title">{t("proxy.auth.title")}</div>
          <div className="proxy-form__form-list proxy-form__form-list--small">
            <Checkbox name="clientNoAuth" label={t("proxy.auth.noAuth")} />
            <Checkbox
              name="clientLoginAuth"
              label={t("proxy.auth.loginAuth")}
            />
            <Checkbox name="clientIPAuth" label={t("proxy.auth.ipAuth")} />
          </div>
        </div>
        <div className="proxy-form__form-item">
          <div className="proxy-form__form-title">
            {t("proxy.benefits.title")}
          </div>
          <div className="proxy-form__form-list proxy-form__form-list--small">
            <Checkbox name="moneyBack" label={t("proxy.benefits.moneyBack")} />
            <Checkbox name="support" label={t("proxy.benefits.support")} />
            <Checkbox name="anonymity" label={t("proxy.benefits.anonymity")} />
            <Checkbox
              name="allPlatforms"
              label={t("proxy.benefits.allPlatforms")}
            />
          </div>
        </div>
        <div className="proxy-form__form-item">
          <div className="proxy-form__form-list">
            <DichotRadio
              name="freeTest"
              label={t("proxy.freeTest.label")}
              options={t("yesno", { returnObjects: true })}
              defaultValue={defaultValues.freeTest}
              showError
            />
            <DichotRadio
              name="refunds"
              label={t("proxy.refunds.label")}
              options={t("yesno", { returnObjects: true })}
              defaultValue={defaultValues.refunds}
              showError
            />
            <DichotRadio
              name="replacementPossibility"
              label={t("proxy.replacementPossibility.label")}
              options={t("yesno", { returnObjects: true })}
              defaultValue={defaultValues.replacementPossibility}
              showError
            />
            <DichotRadio
              name="individual"
              label={t("proxy.individual.label")}
              options={t("yesno", { returnObjects: true })}
              defaultValue={defaultValues.individual}
              showError
            />
            <div className="proxy-form__form-row">
              <DichotRadio
                name="affiliateProgram"
                label={t("proxy.affiliateProgram.label")}
                options={t("yesno", { returnObjects: true })}
                defaultValue={defaultValues.affiliateProgram}
                onClick={(evt) =>
                  setIsAffiliateProgram(JSON.parse(evt.target.value))
                }
                showError
              />
              <Input
                name="affiliatePercent"
                type="text"
                label={t("proxy.affiliatePercent.label")}
                layout="horizontal"
                showError
                inputMode="numeric"
                onInput={onlyInputRange(0, 100)}
                disabled={!isAffiliateProgram}
              />
            </div>
            <DichotRadio
              name="api"
              label={t("proxy.api.label")}
              options={t("yesno", { returnObjects: true })}
              defaultValue={defaultValues.api}
              showError
            />
          </div>
        </div>
        <div className="proxy-form__form-item">
          <FormTabs
            tabList={languages}
            activeTab={activeDescriptionTab}
            changeTab={changeDescriptionTab}
          />
          {languages.map(({ value }) => (
            <Textarea
              key={`description.${value}`}
              name={`description.${value}`}
              label={t("proxy.description.label")}
              layout="vertical"
              showError
              isDescription
              parentStyles={{
                display: value === activeDescriptionTab ? "" : "none"
              }}
            />
          ))}
        </div>
        <div className="proxy-form__contacts">
          <Typography Tag="h3" size="middle" weight="semibold">
            {t("proxy.contacts.title")}
          </Typography>
          <div className="proxy-form__contacts-description">
            {t("proxy.contacts.description")}
          </div>
          <div className="proxy-form__contacts-form">
            {socials.data.map((it, i) => {
              const { id, name, code } = it;

              return (
                <InputField
                  key={`${id}-${i}`}
                  name={`socialNetworks.${code}`}
                  type="text"
                  label={name}
                  placeholder={`${t(
                    "proxy.socialNetworks.placeholder"
                  )}${name}`}
                  layout="horizontal"
                  showError
                  margin="mb-large"
                  errorPath={["socialNetworks", code]}
                  onInput={code === "email" ? emailHandler : null}
                />
              );
            })}
          </div>
        </div>
        <Typography
          Tag="h3"
          size="middle"
          weight="semibold"
          padding="bottom-large"
        >
          {t("proxy.logotype.title")}
        </Typography>
        <Uploader
          onUpload={setFiles}
          defaultImage={defaultValues?.image}
          croppedFileObjects={croppedFileObjects}
          setCroppedFileObjects={setCroppedFileObjects}
        />
        <LogoPreviews
          id={id}
          logo={defaultValues?.image}
          logoTop={defaultValues?.imageTop}
          logosCrop={files}
          setFiles={setFiles}
          setCroppedFileObjects={setCroppedFileObjects}
          setDefaultValues={setDefaultValues}
        />
        <Button disabled={isLoading} type="solid" size="large">
          {defineButtonContent()}
        </Button>
      </form>
    </FormProvider>
  );
};
