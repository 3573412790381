import camelCase from "lodash.camelcase";
import PT from "prop-types";

import {
  RestyledLoading,
  StyledHeader,
  StyledHeaderBox,
  StyledIcon,
  StyledItem,
  StyledKey,
  StyledKeyItem,
  StyledKeyTitle,
  StyledKeyValue,
  StyledList,
  StyledListItem,
  StyledValue,
  StyledValueItem
} from "./IpDetails.styled";

const IpDetailsItem = ({
  id,
  title,
  image,
  isLoading = false,
  isOpened = false,
  handleOpen,
  listData = []
}) => {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <StyledValueItem>
          {value.map((item, i) => (
            <StyledValue key={i} className={"value"}>
              {item}
            </StyledValue>
          ))}
        </StyledValueItem>
      );
    } else return <StyledValue className={"value"}>{value}</StyledValue>;
  };

  return (
    <StyledItem tabIndex={0} id={camelCase(id)} onClick={() => handleOpen(id)}>
      <StyledHeader>
        <StyledHeaderBox>
          <img src={image} alt={title} />
          <span>{title}</span>
        </StyledHeaderBox>
        {isLoading ? (
          <RestyledLoading name="loading" />
        ) : (
          <StyledIcon name="arrowDown" isOpened={isOpened} />
        )}
      </StyledHeader>
      <StyledList isOpened={isOpened} className="list">
        {listData.map((el, index) => (
          <StyledListItem key={index} className={"list-item"}>
            {el.keyTitle && (
              <StyledKeyItem>
                <StyledKeyTitle>{el.keyTitle}</StyledKeyTitle>
                <StyledKeyValue>
                  {el.keyValue?.map((value) => value)}
                </StyledKeyValue>
              </StyledKeyItem>
            )}
            {el.key && <StyledKey>{el.key}</StyledKey>}
            {el.value && renderValue(el.value)}
          </StyledListItem>
        ))}
      </StyledList>
    </StyledItem>
  );
};

IpDetailsItem.propTypes = {
  id: PT.string.isRequired,
  title: PT.string,
  image: PT.node,
  isLoading: PT.bool,
  isOpened: PT.bool,
  handleOpen: PT.func,
  listData: PT.arrayOf(PT.shape({}))
};

export default IpDetailsItem;
