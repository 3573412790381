import styled from "@emotion/styled";

import Container from "../../../../../components/common/New/Container/Container";

export const TabsWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 36px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;
  padding-right: 15px;
  padding-top: 2px;
  padding-left: 4px;
  padding-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    overflow-x: scroll;
    padding-right: 2px;
  }

  & > *:not(:last-child) {
    margin-right: 12px;
  }
`;

export const TabsContainer = styled.div`
  padding-left: 15px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-left: 0;
    width: 100%;
    margin-right: 48px;
    overflow-x: scroll;
  }
`;

export const Tab = styled.div`
  font-family: var(--font-family-text);
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  line-height: 1.5;
  padding: 12px 20px;
  height: 48px;
  color: ${(props) =>
    props.active ? props.theme.colors["neutral-800"] : "#808A9D"};
  background-color: ${(props) =>
    props.active
      ? "rgba(216, 218, 224, 0.50)"
      : props.theme.colors["background-color"]};
  white-space: nowrap;
  border-radius: 8px;
  outline: ${(props) =>
    props.active ? "1px solid transparent" : "1px solid #D4DAE5"};
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &:focus,
  &:active {
    background: rgba(216, 218, 224, 0.5);
    color: ${(props) => props.theme.colors["neutral-800"]};
    outline: 1px solid transparent;
    transition: all 0.3s ease;
  }

  &::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding-left: 2px;
  }
`;
