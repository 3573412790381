import { ProxyFilter } from "../ProxyFilter/ProxyFilter";
import { TableTabs } from "../TableTabs/TableTabs";

import { StyledProxyTabFilterBox } from "./ProxyTabFilter.styled";

export const ProxyTabFilter = () => (
  <StyledProxyTabFilterBox>
    <TableTabs />
    <ProxyFilter />
  </StyledProxyTabFilterBox>
);
