import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ConfirmModal, Pagination } from "../../../../../../components/common";
import { Button } from "../../../../../../components/ui/Button/Button";
import { Loader } from "../../../../../../components/ui/Loader/Loader";
import {
  useDispatchedActions,
  useLangUrlDefault
} from "../../../../../../hooks";
import { ApiService } from "../../../../../../services";
import { getAllCabinet } from "../../../../../../store/reducers/CabinetReducer/Cabinet.selectors";

import { ProxyItem } from "./ProxyItem/ProxyItem";

import "./ProxyList.scss";

export const ProxyList = () => {
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();

  const { proxies } = useSelector(getAllCabinet);

  // **Local state
  const [deletedSiteId, setDeletedSiteId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [params, setParams] = useState(
    proxies.prevParams || {
      proxyType: [],
      country: "",
      sort: "",
      page: 0,
      size: 10
    }
  );

  // **Dispatch
  const { getCabinetSites, setCabinetProxiesPrevParams } =
    useDispatchedActions();

  // *Ref
  const scrollRef = useRef();

  const fetchCabinetSites = useCallback(() => {
    getCabinetSites(params);
    setCabinetProxiesPrevParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setParams({ ...params, page: page - 1 });
  };

  const openModalHandler = (id) => () => {
    setIsModalOpened(true);
    setDeletedSiteId(id);
  };

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(proxies.prevParams)) {
      fetchCabinetSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (proxies.data.totalPages !== params.page) {
      return;
    }

    if (params.page === 0) {
      return;
    }

    setParams({ ...params, page: params.page - 1 });
  }, [params, proxies]);

  return (
    <>
      <div ref={scrollRef} className="cabinet-proxy">
        <Button
          type="black-reverse"
          size="large"
          link
          href={`${hrefLang}/cabinet/proxy/create/`}
        >
          {t("cabinetPages.proxy.list.new")}
        </Button>
        <div className="cabinet-proxy__wrapper">
          {proxies.data?.content?.length !== 0 && (
            <>
              <ul className="cabinet-proxy__list">
                {proxies.data?.content.map((proxySite) => {
                  if (!proxySite) {
                    return;
                  }

                  return (
                    <ProxyItem
                      key={proxySite?.id}
                      proxySite={proxySite}
                      modalHandler={openModalHandler}
                    />
                  );
                })}
              </ul>
              <Pagination
                totalPages={proxies.data.totalPages}
                onPageChange={pageChangeHandler}
                currentPage={params.page + 1}
                hideOnSinglePage
                scrollRef={scrollRef}
              />
            </>
          )}
          {!proxies.isLoading && proxies.data?.content?.length === 0 && (
            <div className="cabinet-proxy__empty">
              {t("cabinetPages.proxy.list.noData")}
            </div>
          )}
          {proxies.isLoading && <Loader type="blur" />}
        </div>
      </div>
      <ConfirmModal
        visible={isModalOpened}
        cancelHandler={setIsModalOpened}
        title={t("cabinetPages.proxy.list.modalTitle")}
        confirmBtnText={t("cabinetPages.proxy.list.delete")}
        cancelBtnText={t("cabinetPages.proxy.list.cancel")}
        successMessage={t("notifications.site.removed")}
        confirmService={() => ApiService.deleteProxySite(deletedSiteId)}
        afterConfirmService={() => {
          setDeletedSiteId(null);
          fetchCabinetSites();
        }}
      />
    </>
  );
};
