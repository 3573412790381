export const setFullSitesReducer = (state, action) => {
  state.sitesFull = action.payload.proxyList;
  state.isOwner = action.payload.isOwner;
};

export const resetFullSitesReducer = (state) => {
  state.sitesFull = [];
};

export const setPrevSitesReducer = (state, action) => {
  state.prevSites = action.payload;
};

export const deleteComparisonSiteReducer = (state, action) => {
  const comparisonId = action.payload;

  state.sites = state.sites.filter(({ siteId }) => siteId !== comparisonId);
  state.prevSites = state.prevSites.filter(
    ({ siteId }) => siteId !== comparisonId
  );
  state.sitesFull = state.sitesFull.filter(({ id }) => id !== comparisonId);
};

export const clearComparisonStateReducer = (state) => {
  state.uuid = "";
  state.sessionId = "";
  state.sites = [];
  state.prevSites = [];
  state.sitesFull = [];
};

export const setComparisonWidgetInfoReducer = (state, action) => {
  state.uuid = action.payload?.uuid;
  state.sites = action.payload?.siteList;
  state.isDataLoaded = true;
};
